/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 0h1v7H0V6h6V0zm4 6V0H9v7h7V6h-6zM9 9v7h1v-6h6V9H9zM0 9v1h6v6h1V9H0z"/>'
  }
})
