<template>
  <div id="app" class="app">
    <preloader v-if="preloader" />
    <router-view />
    <div :class="[$style.cookies, isShow && $style.cookies_show]">
      <p :class="$style.cookies__text">
        {{ $t('cookiesHero.text') }}
      </p>
      <div :class="$style.cookies__buttons">
        <a
          target="_blank"
          :href="`${rulseforuse}?locale=${locale}`"
          :class="$style.cookies__link"
          >{{ $t('cookiesHero.more') }}</a
        >
        <button v-on:click="handleClick" :class="$style.cookies__button">
          {{ $t('cookiesHero.agree') }}
        </button>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <v-pop-up @close="setIsShowAiClonePopUp(false)" v-if="isShowAiClonePopUp">
        <the-start-form />
      </v-pop-up>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'

import { mapMutations, mapState } from 'vuex'
import Preloader from '@/components/ThePreloader.vue'
import i18n from '@/plugins/i18n'
import VPopUp from './components/VPopUp.vue'
import TheStartForm from './components/TheStartForm.vue'

export default {
  name: 'App',
  components: {
    Preloader,
    VPopUp,
    TheStartForm,
  },
  data() {
    return {
      preloader: true,
      isShowValue: true,
    }
  },
  watch: {
    theme() {
      document.documentElement.setAttribute('data-theme', this.theme)
    },
  },
  mounted() {
    window.addEventListener('resize', debounce(this.onResize, 50))
    const self = this
    self.onResize()
    window.addEventListener('load', () => {
      self.onResize()
      self.preloader = false
    })
  },
  destroyed() {
    window.rempveEventListener('resize', this.onResize)
  },
  computed: {
    ...mapState(['theme', 'isShowAiClonePopUp']),
    isShow() {
      if (JSON.parse(localStorage.getItem('cookies-agree')) === 'yes') {
        return false
      }
      return this.isShowValue
    },
    rulseforuse() {
      return process.env.VUE_APP_RULESFORUSE
    },
    locale() {
      return i18n.locale
    },
  },
  methods: {
    ...mapMutations(['setIsMobile', 'setIsShowAiClonePopUp']),

    onResize() {
      this.setIsMobile(window.innerWidth <= 940)
    },
    handleClick() {
      localStorage.setItem('cookies-agree', JSON.stringify('yes'))
      this.isShowValue = false
    },
  },
  created() {
    console.log(this.$route.path)
    if (this.$route.path === '/') {
      // this.$router.push('/ai')
    }
  },
}
</script>
<style lang="scss" module>
.cookies {
  position: fixed;
  left: 50%;
  bottom: 16px;
  width: calc(100% - 64px);
  max-width: 1280px;
  background-color: white;
  border-radius: 24px;
  padding: 24px 24px 24px 32px;
  box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.1);
  z-index: 100000;
  transform: translate(-50%, 200px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  @media (max-width: 940px) {
    flex-direction: column;
    width: calc(100% - 12px);
    bottom: 6px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__text {
    max-width: 864px;
    line-height: 18px;
    margin: 0 24px 0 0;
    font-size: 12px;
    color: $primary;

    @media (max-width: 940px) {
      margin: 0 0 16px 0;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 34px;
    border-radius: 14px;
    background-color: $light;
    font-size: 16px;
    line-height: 1;
    color: white;
    box-sizing: border-box;
    font-weight: 700;
    cursor: pointer;
    margin: 0 16px 0 0;
    min-width: 160px;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 940px) {
      min-width: 140px;
      padding: 0 24px;
    }

    @media (max-width: 360px) {
      min-width: 50%;
      padding: 0 6px;
    }
  }

  &__button {
    cursor: pointer;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    border: none;
    border-radius: 14px;
    font-size: 16px;
    padding: 0 34px;
    min-width: 160px;
    line-height: 1;
    color: white;
    font-weight: 700;

    &:hover {
      opacity: 0.9;
    }

    @media (max-width: 940px) {
      min-width: 140px;
      padding: 0 24px;
    }

    @media (max-width: 360px) {
      min-width: 50%;
      padding: 0 6px;
    }
  }
}

.cookies_show {
  transform: translate(-50%, 0);
}
</style>
