/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'scroll': {
    width: 50,
    height: 34,
    viewBox: '0 0 50 34',
    data: '<path pid="0" d="M25 0c-5.523 0-10 4.477-10 10v12c0 5.523 4.477 10 10 10s10-4.477 10-10V10c0-5.523-4.477-10-10-10zm9 22c0 4.963-4.038 9-9 9-4.963 0-9-4.037-9-9V10c0-4.963 4.037-9 9-9 4.962 0 9 4.037 9 9v12zM26 9v4a1 1 0 11-2 0V9a1 1 0 012 0zM49 16h-3V4h-1v24l4-12zM1 16h3v12h1V4L1 16z"/>'
  }
})
