/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mouse': {
    width: 20,
    height: 32,
    viewBox: '0 0 20 32',
    data: '<path pid="0" d="M10 0C4.477 0 0 4.477 0 10v12c0 5.523 4.477 10 10 10s10-4.477 10-10V10c0-5.523-4.477-10-10-10zm9 22c0 4.963-4.037 9-9 9s-9-4.037-9-9V10c0-4.963 4.037-9 9-9s9 4.037 9 9v12zM11 9v4a1 1 0 11-2 0V9a1 1 0 012 0z"/>'
  }
})
