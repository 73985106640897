<template>
  <div :class="$style.sales">
    <div :class="[$style.wrapperDesktop]">
      <the-social :class="$style.social" v-if="isMobile" />
      <ul :class="$style.column">
        <li :class="$style.columnItem">© Supster 2022</li>
        <li :class="$style.columnItem">
          <a target="_blank" :href="about" :class="$style.columnLink">{{
            $t('about')
          }}</a>
        </li>
        <li :class="$style.columnItem">
          <a target="_blank" :href="`${rulseforuse}`" :class="$style.columnLink">{{
            $t('rulesForUse')
          }}</a>
        </li>
        <li :class="$style.columnItem">
          <a target="_blank" :href="requisites" :class="$style.columnLink">{{
            $t('requisites')
          }}</a>
        </li>
      </ul>
      <a :href="`mailto:${email}`" :class="$style.phone">
        {{ email }}
      </a>
      <ul :class="$style.column">
        <li v-if="code !== 'RU'" :class="$style.columnItem">
          <a target="_blank" :href="`${this.legalization}`" :class="$style.columnLink">{{
            $t('policyOfLegalization')
          }}</a>
        </li>
        <li :class="$style.columnItem">
          <a target="_blank" :href="`${this.privacyPolicy}`" :class="$style.columnLink">{{
            $t('privacyPolicy')
          }}</a>
        </li>
      </ul>
    </div>
    <div :class="[$style.wrapperMobile]">
      <the-social :class="$style.social" v-if="isMobile" />
      <div :class="$style.container">
        <a
          :href="`${this.legalization}`"
          :class="[$style.item, $style[`item-link`]]"
          target="_blank"
          v-if="code !== 'RU'"
        >
          {{ $t('policyOfLegalization') }}
        </a>
        <a
          :href="`${this.rulseforuse}`"
          :class="[$style.item, $style[`item-link`]]"
          target="_blank"
        >
          {{ $t('rulesForUse') }}
        </a>
        <a
          :href="`${this.privacyPolicy}`"
          :class="[$style.item, $style[`item-link`]]"
          target="_blank"
        >
          {{ $t('privacyPolicy') }}
        </a>
        <a :href="`${this.requisites}`" :class="[$style.item, $style[`item-link`]]" target="_blank">
          {{ $t('requisites') }}
        </a>
        <a :href="`${this.about}`" :class="[$style.item, $style[`item-link`]]" target="_blank">
          {{ $t('about') }}
        </a>
        <component
          v-for="({ type, element, value, href, target }, index) in elements"
          :key="`footer-elements-${index}`"
          :is="element"
          :class="[$style.item, $style[`item-${type}`]]"
          :href="
            type === 'email' ? email : value === 'requisites' ? `${href}?locale=${code}` : href
          "
          :target="target"
        >
        </component>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'

import TheSocial from '@/components/TheSocial.vue'
import i18n from '@/plugins/i18n'

export default {
  components: { TheSocial },
  name: 'SalesFooter',
  data() {
    return {
      code: 'RU',
      elements: [
        {
          type: 'text',
          element: 'p',
          href: null,
          target: null,
          value: `© ${new Date().getFullYear()}. Supster`,
        },
        {
          type: 'email',
          element: 'a',
          target: null,
        },
      ],
    }
  },
  computed: {
    ...mapState(['isMobile']),
    getLangFromCode() {
      switch (this.code) {
        case 'RU':
          return 'ru'
        case 'IN':
          return 'in'
        default:
          return 'en'
      }
    },
    number() {
      return process.env.VUE_APP_PHONE
    },
    requisites() {
      return `${process.env.VUE_APP_REQUISITES}?locale=${this.code}`
    },
    about() {
      return `${process.env.VUE_APP_ABOUT}?locale=${this.code}`
    },
    rulseforuse() {
      return `${process.env.VUE_APP_STATIC}${process.env.VUE_APP_USER_AGREEMENT}-${this.getLangFromCode}.pdf`
    },
    locale() {
      return i18n.locale
    },
    phoneNumber() {
      return i18n.locale === 'ru' ? process.env.VUE_APP_PHONE : process.env.VUE_APP_COMPANY_PHONE
    },
    email() {
      return process.env.VUE_APP_EMAIL
    },
    countriesUrl() {
      return `${process.env.VUE_APP_URL}${process.env.VUE_APP_COUNTRIES}`
    },
    legalization() {
      return `${process.env.VUE_APP_STATIC}${process.env.VUE_APP_COMBATING_LEGALIZATION}-${this.getLangFromCode}.pdf`
    },
    privacyPolicy() {
      return `${process.env.VUE_APP_STATIC}${process.env.VUE_APP_PRIVACY}-${this.getLangFromCode}.pdf`
    },
  },
  methods: {
    async getLocale() {
      try {
        const url = this.countriesUrl
        const response = await axios.get(url)
        this.code = response?.data?.data?.code
      } catch (error) {
        console.log(error?.message)
      }
    },
  },
  mounted() {
    this.getLocale()
  },
}
</script>
<style lang="scss" module>
.sales {
  width: 100%;
  margin: 0 72px 0 0;
  padding: 8px 82px 0 82px;

  @media (max-width: 1600px) {
    padding: 8px 41px 0 41px;
  }

  @media (max-width: 1000px) {
    padding: 8px 16px 0 16px;
  }

  @include media('mobile') {
    max-width: 100%;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

.wrapper {
  &Mobile {
    display: none;
    width: 100%;
    flex-direction: column-reverse;

    @include media('mobile') {
      display: flex;
    }
  }

  &Desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @include media('mobile') {
      display: none;
    }

    @media (max-width: 1530px) {
      justify-content: center;
    }
  }
}

.column {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  justify-content: space-between;
  flex-grow: 1;
  width: 430px;
  padding: 0 168px 0 0;

  &:last-child {
    padding: 0 32px 0 150px;
  }

  @media (max-width: 1600px) {
    padding: 0 80px 0 0;

    &:last-child {
      padding: 0 32px 0 36px;
    }
  }

  @media (max-width: 1300px) {
    padding: 0 48px 0 0;
    width: auto;

    &:last-child {
      padding: 0 16px 0 16px;
    }
  }

  &Item {
    font-size: 12px;
    font-weight: 18px;

    @media (min-width: 1920px) and (min-height: 1080px) {
      font-size: 14px;
    }

    @media (max-width: 1240px) {
      font-size: 1vw;
    }
  }

  &Link {
    text-decoration: none;
  }
}

.phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-decoration: none;
  margin: 0 0 0 -24px;

  @media (max-width: 1300px) and (min-width: 941px) {
    font-size: 0.92vw;
    min-width: 115px;
  }

  &Icon {
    width: 10px;
    height: 16px;
    margin: 0 8px 0 0;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  margin: 0 0 16px 0;

  @include media('mobile') {
    margin: 16px 0;
  }
}

.item {
  align-self: center;
  color: var(--text);
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  width: max-content;
  margin: 0 16px 0 0;

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 14px;
  }

  &:last-child {
    margin: 0;
  }

  @include media('mobile') {
    margin: 0 0 16px 0;
    color: $primary;
    font-size: 12px;
    line-height: 12px;
    justify-self: start;

    &:nth-child(2n) {
      justify-self: end;
    }

    &:last-child {
      margin: 0 0 16px 0;
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &-text {
    display: block;

    &:hover {
      opacity: 1;
    }
  }

  &-phone {
    font-weight: 700;

    svg {
      margin: -1px 4px 0 0;
    }
  }
}

.icon {
  width: 10px;
  height: 16px;
  color: var(--text);

  @include media('desktop') {
    width: 0.769vw;
    height: 1.231vw;
  }

  @include media('mobile') {
    width: 10px;
    height: 16px;
    color: $primary;
  }
}
</style>
