<template>
  <section :class="[$style.section, theme === 'dark' && $style.sectionZero]">
    <div :class="$style.inner">
      <div>
        <strong
          :class="[$style.symbol, $style[`symbol-theme-${theme}`]]"
          class="animation-fade-symbol"
          >////</strong
        >
      </div>
      <div :class="$style.content">
        <div
          :class="[
            $style.header,
            $route.name !== 'blog' && $route.name !== 'ai' && $style.headerBusi,
            (($route.name !== 'blog' && $route.name !== 'ai' && pageNumber === '01') ||
              pageNumber === '02') &&
              $style.headerRoute,
            $route.name === 'blog' || ($route.name === 'ai' && $style.headerRoute),
            (pageNumber === '03') | (pageNumber === '04') && $style.headerRoute,
            image === 'laptop' && $style.headerLaptop,
            ($route.name !== 'blog') & (pageNumber === '02') && $style.headerSecond,
          ]"
          class="animation-content"
        >
          <h2 :class="$style.route">
            {{ $route.name === 'blog' || $route.name === 'ai' ? '/blog' : '/busi' }}
          </h2>
          <p
            :class="[
              $style.number,
              pageNumber === '02' && $style.numberZero,
              pageNumber === '03' && $style.numberZero,
              pageNumber === '04' && $style.numberZero,
              image === 'laptop' && $style.numberLaptop,
            ]"
          >
            .{{ pageNumber }}
          </p>
          <p
            :class="[
              $style.countSlide,
              pageNumber === '01' && $style.countSlideShow,
              pageNumber === '02' && $style.countSlideShow,
              ($route.name === 'blog' || $route.name === 'ai') && $style.countSlideHide,
              image === 'laptop' && $style.countSlideLaptop,
            ]"
          >
            {{ symbol.value }}
          </p>
        </div>
        <div
          :class="[
            $style.mainContent,
            isLargeContent && $style.mainContentLarge,
            $style[`mainContent-${image}`],
            image === 'laptop' && $style.mainContentLaptop,
          ]"
        >
          <div
            :class="[$style.image, $style.imageLight, theme === 'dark' && $style.imageLightHide]"
            v-if="!isMobile"
          >
            <div
              :class="[
                $style.image,
                $style.aiCloneImgBlock,
                $style.imageLight,
                theme === 'dark' && $style.imageLightHide,
              ]"
              v-if="$route.name === 'ai'"
            >
              <video
                ref="video"
                class="animation-transform-bottom"
                :class="[
                  $style['image-default'],
                  $style[`image-${image}`],
                  ($route.name !== 'blog') & (symbol.value === 'B') & (pageNumber === '01') &&
                    $style.imageBusiMargin,
                ]"
                muted
                :src="require(`@/assets/images/w1.webm`)"
              ></video>
            </div>
            <picture v-else>
              <source :srcset="require(`@/assets/images/${imageLink}.webp`)" type="image/webp" />
              <img
                class="animation-transform-bottom"
                :class="[
                  $style['image-default'],
                  $style[`image-${image}`],
                  ($route.name !== 'blog') & (symbol.value === 'B') & (pageNumber === '01') &&
                    $style.imageBusiMargin,
                ]"
                draggable="false"
                :src="require(`@/assets/images/${imageLink}.png`)"
                :alt="title"
              />
            </picture>
          </div>
          <div
            v-if="isMobile && !isAiClone"
            :class="[
              $style.mobileImageContainer,
              pageNumber === '03' && $style.mobileImageContainerHide,
              image === 'laptop' && $style.mobileImageContainerLaptop,
            ]"
          >
            <div :class="$style.mobileImageCircle">
              <expandable-image
                v-if="isMobile"
                class="animation-transform-bottom"
                :class="[
                  $style.mobileImageImage,
                  image === 'laptop' && $style.mobileImageImageLaptop,
                ]"
                draggable="false"
                :src="require(`@/assets/images/${imageLink}.png`)"
                :alt="title"
              />
            </div>
          </div>
          <div v-show="isMobile && isAiClone && pageNumber === '01'" :class="$style.videoWrapper">
            <v-player
              color="#271460"
              v-show="isAiClone"
              :class="$style.playerMobile"
              :disabled-name="disabledName"
              @click="onPlayer"
            />
            <img
              v-show="disabledName === 'pause'"
              src="../assets/images/ai-video-background.jpg"
              alt=""
            />

            <video
              ref="video2"
              :class="$style.video"
              class="animation-fade"
              loop
              muted
              data-autoplay
              playsinline
              id="#myVideo"
              @playing="onVideo(true)"
              @pause="onVideo(false)"
            >
              <source src="../assets/images/ai-video.mp4" type="video/mp4" />
            </video>
          </div>
          <div :class="[$style.phones, theme === 'gray' && $style.phonesShow]">
            <div :class="$style.phonesWrapper" />
            <picture>
              <source :srcset="require(`@/assets/images/phones/1.webp`)" type="image/webp" />
              <img
                class="animation-transform-bottom"
                :class="$style.phonesImage1"
                draggable="false"
                :src="require(`@/assets/images/phones/1.png`)"
                :alt="title"
              />
            </picture>
            <picture>
              <source :srcset="require(`@/assets/images/phones/1.webp`)" type="image/webp" />
              <img
                class="animation-transform-bottom"
                :class="$style.phonesImage2"
                draggable="false"
                :src="require(`@/assets/images/phones/1.png`)"
                :alt="title"
              />
            </picture>
            <picture>
              <source :srcset="require(`@/assets/images/phones/1.webp`)" type="image/webp" />
              <img
                class="animation-transform-bottom"
                :class="$style.phonesImage3"
                draggable="false"
                :src="require(`@/assets/images/phones/1.png`)"
                :alt="title"
              />
            </picture>
            <div :class="$style.phonesWrapper" />
          </div>
          <div
            :class="[
              $style.container,
              pageNumber === '03' && $style.containerMedia,
              pageNumber === '04' && $style.containerPayOrCrm,
              image === 'laptop' && $style.containerLaptop,
              pageNumber === '01' && $style.containerHowItWork,
              isMobile &
                (symbol.value === 'B' ||
                  symbol.value === 'C' ||
                  symbol.value === 'D' ||
                  symbol.value === 'E') && $style.containerMarginUp,
            ]"
            class="animation-content animation-content-after"
          >
            <h2
              :class="[
                $style.containerTitle,
                $style[`containerTitle-theme-${theme}`],
                isMobile &
                  (symbol.value === 'B' ||
                    symbol.value === 'C' ||
                    symbol.value === 'D' ||
                    symbol.value === 'E') && $style.containerTitleHide,
              ]"
            >
              {{ title }}
            </h2>
            <slot />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" :class="$style.next">
      <p :class="$style.nextStep">{{ $t(nextStep) }}</p>
      <p :class="[$style.nextText, $style[`nextText-theme-${theme}`]]">{{ nextText }}</p>
    </div>
    <div :class="$style.curcle" />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import i18n from '@/plugins/i18n'
import VPlayer from '@/components/VPlayer.vue'

export default {
  // title
  name: 'VPage',
  components: {
    VPlayer,
  },
  data() {
    return {
      disabledName: 'pause',
      /*
      media: [
        {
          thumb: '',
          src: '',
        },
      ],
      */
    }
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    title: String,
    description: String,
    bandDeg: {
      type: [String, Number],
      default: 20,
    },
    symbol: {
      type: Object,
      default: () => ({ value: null, isSmall: false }),
    },
    image: {
      type: String,
      default: 'phone',
    },
    theme: {
      type: String,
    },
    className: {
      type: String,
      default: '',
    },
    isLargeContent: {
      type: Boolean,
      default: false,
    },
    isHeader: {
      type: Boolean,
      default: true,
    },
    isBoxShadow: {
      type: Boolean,
      default: false,
    },
    nextStep: String,
    nextText: String,
  },
  computed: {
    ...mapState(['isMobile']),
    isAiClone() {
      return this.$route.name === 'ai'
    },
    isIos() {
      return (
        navigator.userAgent.indexOf('Mac OS') !== -1 || navigator.userAgent.indexOf('iOS') !== -1
      )
    },
    pageNumber() {
      return this.index > 9 ? this.index : `0${this.index}`
    },
    imageLink() {
      const path = this.$route.name
      let img = ''
      const locale = i18n.locale === 'ru' ? 'ru' : 'en'

      if (this.$route.name === 'blog' || this.$route.name === 'ai') {
        img = this.index
      } else if (this.index === 1 || this.index === 2) {
        img = `${this.index}_${this.symbol.value}`
      } else {
        img = this.index
      }
      return `${locale}/${path}/${img}`
    },
  },
  methods: {
    onVideo(data) {
      const name = data ? 'play' : 'pause'
      this.$refs.video2[name]()
      this.disabledName = name
    },
    onPlayer(name) {
      if (['play', 'pause'].includes(name)) {
        this.disabledName = name
        this.$refs.video2[name]()
        return
      }
      this.disabledName = 'play'
      this.$refs.video2.currentTime = 0
      this.$refs.video2.play()
    },
    playVideo2() {
      const video = this.$refs.video2
      const self = this
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }

      // eslint-disable-next-line no-shadow
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            self.onPlayer('play')
            observer.unobserve(entry.target)
          } else {
            self.onPlayer('pause')
          }
        })
      }, options)
      observer.observe(video)
    },

    setVideoStart() {
      const { video } = this.$refs
      if (video) {
        switch (this.$route.hash) {
          case '#how-it-work':
            video.currentTime = 30
            break
          case '#income':
            video.currentTime = 0
            break
          case '#media':
            video.currentTime = 20
            break
          default:
            video.currentTime = 24
            break
        }
        video.play()
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.name === 'ai' && !this.isMobile) {
        this.setVideoStart()
      }
    },
    isMobile() {
      if (this.$route.name === 'ai' && !this.isMobile) {
        this.setVideoStart()
        this.$refs.video.addEventListener('ended', this.setVideoStart)
      }
      if (this.$route.name === 'ai' && this.isMobile) {
        if (!this.isIos) {
          this.playVideo2()
        } else {
          this.disabledName = 'pause'
          this.$refs.video2.play()
        }
      }
    },
  },
}
</script>
<style lang="scss" module>
.section {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.next {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 30px;
  right: 96px;
  z-index: 300;
  font-size: 12px;
  margin: 0;

  &Step {
    color: $light;
    font-weight: 400;
    margin: 0 8px 0 0;

    &::after {
      content: ':';
    }
  }

  &Text {
    font-weight: 700;

    &-theme {
      &-dark {
        color: white;
      }

      &-light {
        color: $primary;
      }
    }
  }
}

.curcle {
  margin: -240px 0 0 0;
  width: 100%;
  height: 240px;
  border-radius: 0 0 50% 50%;
  overflow: hidden;
}

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: $max-width + 260px;
  height: 100vh;
  padding: $padding * 3.5 $padding 0;
  margin: 0 auto;

  @media (min-width: 1920px) {
    max-width: calc(100vw - 260px);
  }

  @include media('mobile') {
    max-width: 100%;
    height: 100%;
    padding: 0;
    row-gap: $padding * 1.5;
  }
}

.header {
  margin: -242px 0 0 0;
  position: absolute;
  left: -37px;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 2;

  @media (min-width: 1920px) and (min-height: 1080px) {
    transform: scale(120%, 120%) !important;
    left: 37px;
  }

  @media (max-width: 1600px) and (min-width: 941px) {
    margin: -202px 0 0 0;
    left: 82px;
  }

  &Busi {
    margin: -242px 0 0 -21px;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: -202px 0 0 -21px;
    }
  }

  @include media('mobile') {
    flex-direction: row;
    transform: rotate(270deg);
    z-index: 20;
    width: 100px;
    right: -34px;
    top: 64px;
    margin: 0;
    left: auto;

    &Second {
      margin: 16px 0 0 0;
    }

    &Route {
      top: 24px;

      @media (max-width: 580px) {
        top: 16px;
      }
    }
  }

  @media (max-width: 580px) {
    &Second {
      margin: 0;
    }
  }

  &Laptop {
    @media (max-width: 1600px) and (min-width: 941px) {
      left: -48px;
    }
  }
}
.aiCloneImgBlock {
  position: relative;
}
.playerMobile {
  z-index: 100000;
  top: 25px;
}
.number {
  position: absolute;
  top: 149px;
  align-self: center;
  color: $secondary;
  font-weight: 800;
  font-size: 240px;
  line-height: 1;
  letter-spacing: -0.05em;
  margin: 10px 0 0 -72px;

  @media (max-width: 1600px) and (min-width: 941px) {
    font-size: 180px;
    top: 110px;
    margin: 10px 0 0 -44px;
  }

  @media (max-width: 1100px) and (min-width: 941px) {
    font-size: 15vw;
    margin: 10px 0 0 -48px;
  }

  &Zero {
    margin: 10px 0 0 -40px;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: 10px 0 0 -12px;
    }

    @media (max-width: 1100px) and (min-width: 941px) {
      margin: 10px 0 0 -64px;
    }
  }

  &Laptop {
    margin: 10px 0 0 -204px;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: 10px 0 0 -156px;
    }
  }

  @include media('mobile') {
    position: relative;
    top: auto;
    font-size: 48px;
    line-height: 1;
    margin: 0;
  }

  @media (max-width: 580px) {
    font-size: 32px;
    line-height: 1;
    margin: 12px 0 0 12px;
  }
}

.route {
  font-weight: 800;
  font-size: 240px;
  line-height: 1;
  letter-spacing: -0.05em;

  @media (max-width: 1600px) {
    font-size: 180px;
  }

  @media (max-width: 1100px) and (min-width: 941px) {
    font-size: 15vw;
  }

  @include media('mobile') {
    display: none;
  }
}

.countSlide {
  position: absolute;
  font-size: 14vw;
  line-height: 1;
  letter-spacing: -0.09em;
  font-weight: 800;
  text-transform: lowercase;
  text-align: center;
  display: none;
  top: 240px;
  margin: 0 0 0 188px;

  @media (max-width: 1600px) and (min-width: 941px) {
    top: 180px;
    font-size: 180px;
    margin: 0 0 0 140px;
  }

  @media (max-width: 1100px) and (min-width: 941px) {
    font-size: 15vw;
    margin: 0 0 0 56px;
  }

  &::before {
    content: '.';
    margin: 0 -16px 0 0;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: 0 -12px 0 0;
    }
  }

  &Show {
    display: block;
  }

  &Hide {
    display: none;
  }

  &Laptop {
    margin: 0 0 0 0px;
  }

  @include media('mobile') {
    position: relative;
    top: auto;
    font-size: 48px;
    line-height: 1;
    margin: 0;
    color: $primary;

    &::before {
      content: '';
      margin: 0;
    }
  }

  @media (max-width: 580px) {
    font-size: 32px;
    line-height: 1;
    margin: 8px 0 0 0;
  }
}

.wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 12px;
  align-self: center;

  @include media('desktop') {
    row-gap: 0.923vw;
  }

  @include media('mobile') {
    row-gap: 8px;
  }
}

.title {
  font-weight: 800;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -0.05em;

  @include media('mobile') {
    font-size: 28px;
    line-height: 28px;
  }

  &-theme-light {
    color: $primary;
  }

  &-theme-gray {
    color: $primary;
  }

  &-theme-dark {
    color: white;
  }
}

.description {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;

  @include media('desktop') {
    font-size: 1.846vw;
    line-height: 1.846vw;
  }

  @include media('mobile') {
    font-size: 14px;
    line-height: 20px;
  }

  &-theme-light {
    color: $primary;
  }

  &-theme-gray {
    color: $primary;
  }

  &-theme-dark {
    color: white;
  }
}
.videoWrapper {
  position: relative;
  width: 100%;

  video,
  img {
    width: 100%;
  }
  img {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }
}

.mainContent {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 4;
  height: 100%;
  padding-bottom: 64px;

  &Laptop {
    padding: 0 0 186px 0;
  }

  @include media('mobile') {
    flex-direction: column-reverse;
    width: 100%;
    margin: 0 0 0 0;
    padding: 0;

    &Laptop {
      padding: 0;
    }
  }
}

.mobileImage {
  &Container {
    margin: 8px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 66.67vw;

    &Laptop {
      margin: -186px 0 0 0;

      @media (max-width: 700px) {
        margin: -146px 0 0 0;
      }

      @media (max-width: 528px) {
        margin: -116px 0 0 0;
      }

      @media (max-width: 440px) {
        margin: -24px 0 0 0;
      }
    }

    &Hide {
      display: none;
    }
  }

  &Circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
  }

  &Image {
    margin: 40px 0 0 0;
    width: calc(100vw - 168px);
    max-width: 460px;
    filter: drop-shadow(0px 6px 4px rgba(16, 6, 44, 0.2))
      drop-shadow(0px 30px 20px rgba(21, 7, 33, 0.35));

    @media (max-width: 580px) {
      max-width: 320px;
    }

    @media (max-width: 440px) {
      margin: 20px 0 0 0;
    }

    &Laptop {
      margin: 220px 0 0 0;
      width: calc(100vw - 100px);
      max-width: 580px;

      @media (max-width: 700px) {
        margin: 180px 0 0 0;
        width: calc(100vw - 164px);
      }

      @media (max-width: 580px) {
        max-width: 320px;
      }

      @media (max-width: 528px) {
        margin: 140px 0 0 0;
      }

      @media (max-width: 440px) {
        margin: 52px 0 0 0;
        width: calc(100vw - 100px);
      }
    }
  }
}

.image {
  position: relative;
  filter: drop-shadow(0px 6px 4px rgba(16, 6, 44, 0.2))
    drop-shadow(0px 6px 12px rgba(21, 7, 63, 0.2));

  @media (max-width: 1600px) {
    margin: 64px 16px 0 0;
  }

  &Light {
    &Hide {
      @include media('mobile') {
        display: none;
      }
    }
  }

  &-default {
    z-index: 2;
    display: block;
    filter: drop-shadow(15px 15px 60px rgba(39, 20, 96, 0.25));

    @include media('desktop') {
      top: 1.538vw;
      filter: drop-shadow(1.154vw 1.154vw 4.615vw rgba(39, 20, 96, 0.25));
    }
  }

  &-phone {
    max-height: calc(100vh - 100px);
    margin: -20px 0 0 380px;

    @media (min-height: 1100px) and (max-width: 1600px) {
      max-width: 21vw;
    }

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: -82px 0 0 325px;
      max-height: calc(100vh - 174px);
    }

    @media (max-width: 1070px) and (min-width: 941px) {
      margin: -82px 0 0 220px;
    }
  }

  &-laptop {
    max-height: 55.6vh;
    margin: 100px 0 0 124px;

    @media (max-width: 1600px) and (min-width: 941px) {
      max-height: 62.5vh;
      margin: 36px 0 0 24px;
    }

    @media (max-width: 1056px) and (min-width: 941px) {
      max-height: 54vh;
      margin: 48px 0 0 -16px;
    }
  }

  &-phones {
    margin: -470px 0 0 0;
    width: 100%;
  }

  &BusiMargin {
    @media (max-width: 1600px) and (min-width: 941px) {
      margin: -82px 0 0 290px;
      max-height: calc(100vh - 174px);
    }
  }
}

.phones {
  display: none;

  &Show {
    @include media('mobile') {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      bottom: 0;
      width: calc(100% + 64px);
      height: 220px;
      overflow: hidden;
      margin: 32px 0 -32px 0;
      max-width: 600px;
    }
  }

  &Wrapper {
    height: 100%;
    width: 12px;
  }

  &Image {
    &1 {
      height: 240px;
      margin: 32px -36px 0 -4px;
      filter: blur(8px) drop-shadow(0px 6px 4px rgba(16, 6, 44, 0.2))
        drop-shadow(0px 30px 50px rgba(21, 7, 63, 0.35));
    }

    &2 {
      height: 406px;
      margin: 0 0 96px 0;
      filter: drop-shadow(0px 6px 4px rgba(16, 6, 44, 0.2))
        drop-shadow(0px 30px 50px rgba(21, 7, 63, 0.35));
    }

    &3 {
      height: 406px;
      margin: 64px -12px 0 8px;
      filter: blur(8px) drop-shadow(0px 6px 4px rgba(16, 6, 44, 0.2))
        drop-shadow(0px 30px 50px rgba(21, 7, 63, 0.35));
    }
  }
}

.container {
  margin: -48px 0 0 64px;
  position: relative;
  z-index: 2;
  max-width: 484px;
  box-sizing: border-box;

  @media (max-width: 1600px) and (min-width: 941px) {
    margin: -48px 0 0 32px;
  }

  @media (max-width: 1060px) and (min-width: 941px) {
    margin: -48px 0 0 8px;
  }

  @include media('mobile') {
    max-width: 600px;
    width: 100%;
    grid-area: content;
    padding-top: 0;
    margin: 0;
  }

  &Media {
    margin: -12px 0 0 64px;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: -12px 0 0 32px;
    }

    @media (max-width: 1060px) and (min-width: 941px) {
      margin: -12px 0 0 8px;
    }

    @include media('mobile') {
      margin: 0;
    }
  }

  &PayOrCrm {
    margin: -18px 0 0 64px;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: 12px 0 0 32px;
    }

    @media (max-width: 1060px) and (min-width: 941px) {
      margin: 12px 0 0 8px;
    }

    @include media('mobile') {
      margin: 0;
    }
  }

  &Laptop {
    margin: 112px 0 0 64px;
    @media (max-width: 1600px) and (min-width: 941px) {
      margin: 112px 0 0 32px;
    }

    @media (max-width: 1000px) and (min-width: 941px) {
      margin: 112px 0 0 8px;
    }
  }

  &HowItWork {
    margin: -24px 0 0 64px;

    @media (max-width: 1600px) and (min-width: 941px) {
      margin: -20px 0 0 32px;
    }

    @media (max-width: 1060px) and (min-width: 941px) {
      margin: -20px 0 0 8px;
    }

    @include media('mobile') {
      margin: 0;
    }
  }

  &MarginUp {
    margin: -32px 0 0 0;
  }

  &Title {
    font-size: 48px;
    line-height: 54px;
    font-weight: 800;
    letter-spacing: -0.05em;

    &Hide {
      display: none;
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
      font-size: 54px;
      line-height: 1.125;
    }

    @media (max-width: 1600px) and (min-width: 941px) {
      font-size: 32px;
      line-height: 36px;
    }

    @media (max-width: 580px) {
      font-size: 32px;
    }

    @media (max-width: 400px) {
      word-wrap: break;
      line-height: 1.1;
    }

    &-theme-light {
      color: $primary;
    }

    &-theme-gray {
      color: white;
    }

    &-theme-dark {
      color: white;
    }
  }
  @media (max-width: 580px) {
    margin: 32px 0;
    padding: 0 32px;
  }
}

.band {
  position: absolute;
  top: 0;
  right: -20%;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 200px,
    var(--text) 0,
    var(--text) 201px
  );
  opacity: 0.08;
}

.symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  color: var(--band);
  font-weight: 800;
  font-size: 1600px;
  line-height: 1000px;
  white-space: nowrap;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  opacity: 0.04;
  transition: color 0s, opacity 0s;
  letter-spacing: -0.06em;

  @media (min-height: 1070px) {
    font-size: 180vh;
  }

  @include media('mobile') {
    margin: -128px 0 0 0;
    top: 42%;
    left: 30%;
    font-size: 140vw;
    line-height: 1;
    opacity: 0.3;
    letter-spacing: -0.06em;
  }

  @media (max-width: 580px) {
    top: 36%;
    margin-top: 8px;
    font-size: 1200px;
  }

  &-small {
    font-size: 800px;
    line-height: 800px;

    @include media('desktop') {
      font-size: 61.538vw;
      line-height: 61.538vw;
    }

    @include media('mobile') {
      font-size: 400px;
      line-height: 400px;
    }
  }

  &-theme-light {
    color: $band-light;
  }

  &-theme-gray {
    color: rgba(255, 255, 255, 0.1);
  }

  &-theme-dark {
    color: rgba(255, 255, 255, 0.1);
  }
}

.zoom {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: calc(50% - 20px);
  display: none;

  &Laptop {
    top: -360px;
  }
}

.circle {
  position: relative;
  z-index: 1;
  display: grid;
  align-self: center;
  justify-self: center;
  width: 40px;
  height: 40px;
  background-color: $secondary;
  border-radius: 14px;

  &Icon {
    position: relative;
    z-index: 1;
    display: block;
    align-self: center;
    justify-self: center;
    width: 16px;
    height: 16px;
    color: white;
  }
}

.ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  border-radius: 100%;

  @keyframes pulse {
    //
    to {
      transform: scale(1);
      opacity: 0;
    }
  }

  &::before,
  &::after {
    position: absolute;
    top: -25%;
    left: -25%;
    z-index: 1;
    box-sizing: border-box;
    width: 150%;
    height: 150%;
    border: 1px solid $secondary-light;
    border-radius: 100%;
    transform: scale(0.714);
    opacity: 0.6;
    animation: pulse 1.5s linear infinite;
    content: ' ';
  }

  .avatar::after {
    animation-delay: 1s;
  }
}
</style>
