import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/assets/translations/en'
import ru from '@/assets/translations/ru'
import pt from '@/assets/translations/pt'

Vue.use(VueI18n)

const localLang = JSON.parse(localStorage.getItem('lang'))

const locale = (lang) => {
  switch (lang) {
    case 'pt':
      localStorage.setItem('lang', JSON.stringify('pt'))
      return 'pt'
    case 'ru':
      localStorage.setItem('lang', JSON.stringify('ru'))
      return 'ru'
    default:
      localStorage.setItem('lang', JSON.stringify('en'))
      return 'en'
  }
}

export default new VueI18n({
  locale: localLang || locale(window.navigator.language.split('-', 2)[0]),
  messages: { en, ru, pt },
  // silentTranslationWarn: true,
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return choicesLength < 4 ? 2 : 3
    },
  },
})
