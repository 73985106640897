<template>
  <div @click="close" :class="$style.wrapper">
    <div ref="content" :class="$style.content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'VPopUp',
  data() {
    return {}
  },
  methods: {
    close(e) {
      const withinBoundaries = e.composedPath().includes(this.$refs.content)
      if (!withinBoundaries) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background: rgba(39, 20, 96, 0.9);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
</style>
