<template>
  <component v-if="!circle" :is="component" :class="$style.item">
    <div :class="[$style.line, $style[`line-theme-${theme}`], hideLine && $style.lineHide]" />
    <strong :class="[$style.content, $style[`content-theme-${theme}`]]"><slot /></strong>
  </component>
  <component v-else-if="circle" :is="component" :class="$style.item">
    <div :class="$style.circleBox">
      <div :class="$style.circle">!</div>
    </div>
    <strong :class="[$style.content, $style[`content-theme-${theme}`]]"><slot /></strong>
  </component>
</template>
<script>
export default {
  name: 'VListItem',
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      default: 'li',
    },
    number: {
      type: [Number, String],
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
      default: 'default',
    },
    theme: {
      type: String,
    },
  },
}
</script>
<style lang="scss" module>
.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 50%;
  background-color: $secondary;
  padding: 0;
  line-height: 1;
  color: $primary;
  font-size: 10px;
  font-weight: 700;
  margin: 4px 0 0 0;

  &Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 24px;

    @media (max-width: 1280px) {
      min-width: 16px;
    }
  }
}

.item {
  @include li;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.line {
  min-width: 24px;
  height: 1px;
  margin: 12px 0 0 0;

  @media (max-width: 1280px) {
    min-width: 16px;
  }

  @media (max-width: 1600px) {
    margin: 10px 0 0 0;
  }

  @include media('mobile') {
    margin-top: 9px;
  }

  &-theme-light {
    background-color: $primary;
  }

  &-theme-gray {
    background-color: white;
  }

  &-theme-dark {
    background-color: white;
  }

  &Hide {
    display: none;
  }
}

.content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 0 5px;

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 18px;
    line-height: 1.5;
  }

  @media (max-width: 1600px) and (min-width: 941px) {
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 -4px 5px;
    max-width: 400px;
  }

  @media (max-width: 1200px) and (min-width: 941px) {
    max-width: 380px;
  }

  @media (max-width: 1060px) and (min-width: 941px) {
    font-size: 1.1vw;
  }

  @media (max-width: 580px) {
    font-size: 13px;
    line-height: 18px;
  }

  &-theme-light {
    color: $primary;
  }

  &-theme-gray {
    color: white;
  }

  &-theme-dark {
    color: white;
  }

  a {
    font-weight: 700;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
