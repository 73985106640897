/* eslint-disable */
var blogBack = require('vue-svgicon')
blogBack.register({
  blogBack: {
    width: 300,
    height: 240,
    viewBox: '0 0 300 240',
    data: '<path d="M240 -40H60C26.85 -40 0 -13.15 0 20V140C0 173.15 26.85 200 60 200H240C273.15 200 300 173.15 300 140V20C300 -13.15 273.15 -40 240 -40ZM60 35C51.75 35 45 28.25 45 20C45 11.75 51.75 5 60 5C68.25 5 75 11.75 75 20C75 28.25 68.25 35 60 35ZM120 95C111.75 95 105 88.25 105 80C105 71.75 111.75 65 120 65C128.25 65 135 71.75 135 80C135 88.25 128.25 95 120 95ZM120 35C111.75 35 105 28.25 105 20C105 11.75 111.75 5 120 5C128.25 5 135 11.75 135 20C135 28.25 128.25 35 120 35ZM180 155C171.75 155 165 148.25 165 140C165 131.75 171.75 125 180 125C188.25 125 195 131.75 195 140C195 148.25 188.25 155 180 155ZM180 95C171.75 95 165 88.25 165 80C165 71.75 171.75 65 180 65C188.25 65 195 71.75 195 80C195 88.25 188.25 95 180 95ZM180 35C171.75 35 165 28.25 165 20C165 11.75 171.75 5 180 5C188.25 5 195 11.75 195 20C195 28.25 188.25 35 180 35ZM240 95C231.75 95 225 88.25 225 80C225 71.75 231.75 65 240 65C248.25 65 255 71.75 255 80C255 88.25 248.25 95 240 95Z"/>',
  },
})
