/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'airplane': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" d="M16.785.106L.84 6.623C-.248 7.086-.24 7.73.642 8.016l3.977 1.316 1.521 4.944c.185.54.094.755.63.755.414 0 .597-.2.828-.438.146-.152 1.016-1.049 1.988-2.05l4.136 3.24c.761.444 1.311.214 1.5-.75l2.716-13.56C18.216.29 17.513-.245 16.785.106zM14.727 3.22l-7.675 7.34-.299 3.377-1.51-4.908 8.963-5.993c.447-.287.858-.133.52.184z"/>'
  }
})
