import { mapState } from 'vuex'

export default {
  props: {
    name: {
      require: true,
      type: String,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      element: null,
    }
  },
  watch: {
    name() {
      this.onChangeScrolling()
    },
    activeSlideIndex() {
      this.onChangeScrolling()
    },
    isBLog() {
      window.isSlide = false
    },
    isAiClone() {
      window.isSlide = false
    },
    isThisSlide(){
      if(!this.isBLog && !this.isAiClone){
        window.isSlide = true
      }else{
         window.isSlide = false
      }
    },
  },
  computed: {
    ...mapState(['activeSlideIndex']),
    isBLog() {
      return this.name === 'blog'
    },
    isAiClone() {
      return this.name === 'ai'
    },
    isThisSlide() {
      return this.activeSlideIndex === this.index
    },
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$refs.section){
        this.element = this.$refs.section.querySelector('.fp-slidesNav')
      }

    })
  },

  methods: {
    onChangeScrolling() {
      /* eslint-disable no-unused-expressions */
      this.element?.classList.add('_hide')
      if (this.isThisSlide) {
        this.$bus.$emit('change-scrolling', this.isAiClone ? !this.isAiClone : !this.isBLog)
        if (this.isBLog || this.isAiClone) {
          this.element?.classList.add('_hide')
          this.element?.classList.remove('animation-content')
          return
        }
        this.element?.classList.remove('_hide')
        this.element?.classList.add('animation-content')
      }
    },
  },
}
