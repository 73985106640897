import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

import RulesForUse from '../views/RulesForUse.vue'
import Requisites from '../views/Requisites.vue'
import About from '../views/About.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Index,
      redirect: 'ai',
    },
    {
      path: `/business`,
      name: 'business',
      component: Index,
    },
    {
      path: `${process.env.VUE_APP_BLOG}`,
      name: 'blog',
      component: Index,
    },
    {
      path: `${process.env.VUE_APP_AI_CLONE}`,
      name: 'ai',
      component: Index,
    },
    {
      path: `${process.env.VUE_APP_RULESFORUSE}`,
      name: 'rulesforuse',
      component: RulesForUse,
    },
    {
      path: `${process.env.VUE_APP_REQUISITES}`,
      name: 'requisites',
      component: Requisites,
    },
    {
      path: `${process.env.VUE_APP_ABOUT}`,
      name: 'about',
      component: About,
    },
    {
      path: '**',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
    },
  ],
})
