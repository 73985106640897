<template>
  <div ref="section">
    <v-page
      :class="$style[`section-${color}`]"
      :index="index"
      :symbol="{ value: isBlog ? '#' : '$' }"
      :title="sectionTitle"
      :nextStep="nextStep"
      :nextText="nextText"
      :description="sectionDescriprion"
      :theme="color"
      :band-deg="55"
      :class-name="isBlog ? $style.phones : ''"
      image="phone"
    >
      <div :class="[$style.content, $style[`content-${name}`]]">
        <div>
          <v-list :list="data[name]" :theme="color" />
          <div :class="$style.row">
            <div></div>
            <div :class="[$style.wrapper, $style[`wrapper-${name}`]]">
              <v-button
                v-for="{ name } in icons[name]"
                :key="name"
                :class="[!isBlog && !isAiClone && $style.button]"
                is-no-tag
                is-social
              >
                <icon :name="name" :class="$style.icon" />
              </v-button>
            </div>
          </div>
        </div>
        <v-list-item
          v-if="!isBlog && !isAiClone"
          :theme="color"
          :class="$style.notif"
          number="i"
          type="light"
          component="div"
          :circle="true"
        >
          <p v-html="$t('media.business.list.subText')"></p>
        </v-list-item>
      </div>
    </v-page>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import VPage from '@/components/VPage.vue'
import VList from '@/components/list/VList.vue'
import VListItem from '@/components/list/VListItem.vue'
import VButton from '@/components/VButton.vue'

export default {
  name: 'Media',
  components: {
    VPage,
    VList,
    VButton,
    VListItem,
  },
  props: {
    name: {
      require: true,
      type: String,
    },
    index: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      data: {
        business: ['media.business.list.text1', 'media.business.list.text2'],
        blog: ['media.blog.list.text1', 'media.blog.list.text2'],
        ai: ['media.aiClone.list.text1', 'media.aiClone.list.text2'],
      },
      icons: {
        business: [{ name: 'apple-pay' }, { name: 'google-pay' }],
        blog: [{ name: 'airplane' }, { name: 'viber' }, { name: 'whatsapp' }],
        ai: [{ name: 'airplane' }, { name: 'viber' }, { name: 'whatsapp' }],
      },
    }
  },
  computed: {
    ...mapState(['isMobile']),
    isBlog() {
      return this.name === 'blog'
    },
    isAiClone() {
      return this.name === 'ai'
    },
    sectionTitle() {
      if (this.isAiClone) {
        return this.$t(`media.aiClone.page.title`)
      }
      return this.$t(`media.${this.isBlog ? 'blog' : 'business'}.page.title`)
    },
    sectionDescriprion() {
      return this.$t(`media.${this.isBlog ? 'blog' : 'business'}.page.description`)
    },
    nextStep() {
      return this.$t(`media.${this.isBlog ? 'blog' : 'business'}.page.nextStep`)
    },
    nextText() {
      return this.$t(`media.${this.isBlog ? 'blog' : 'business'}.page.nextText`)
    },
  },
}
</script>
<style lang="scss" module>
.section {
  @include mobile-bg();
}

.content {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  row-gap: 10px;

  &-business {
    @include media('mobile') {
      row-gap: 8px;
    }
  }

  &-blog {
    @include media('mobile') {
      row-gap: 8px;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: $primary;

  @include media('desktop') {
    width: 1.538vw;
    height: 1.538vw;
  }

  @include media('mobile') {
    width: 20px;
    height: 20px;
  }
}

.row {
  @include li;
}

.wrapper {
  display: grid;
  column-gap: 16px;

  &-business {
    margin: 0 0 32px -32px;
    grid-template-columns: repeat(2, 150px);

    @include media('desktop') {
      grid-template-columns: repeat(2, 9.231vw);
    }

    @include media('mobile') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-blog {
    margin: 16px 0 0 -32px;
    grid-template-columns: repeat(3, 48px);

    @include media('desktop') {
      grid-template-columns: repeat(3, 3.692vw);
    }

    @include media('mobile') {
      grid-template-columns: repeat(3, 40px);
    }

    @media (max-width: 580px) {
      margin: 16px 0 0 -26px;
    }
  }
  &-ai {
    margin: 16px 0 0 -32px;
    grid-template-columns: repeat(3, 48px);

    @include media('desktop') {
      grid-template-columns: repeat(3, 3.692vw);
    }

    @include media('mobile') {
      grid-template-columns: repeat(3, 40px);
    }

    @media (max-width: 580px) {
      margin: 16px 0 0 -26px;
    }
  }
}

.phones {
  @include media('mobile') {
    transform: translateY(300px);
  }
}

.button {
  margin: 0 16px 0 0;

  @include media('mobile') {
    padding: $padding / 2.5 $padding / 2.5;
  }

  svg {
    width: 70px;
    height: 25px;

    @include media('desktop') {
      width: 5.385vw;
      height: 1.923vw;
    }

    @include media('mobile') {
      width: 70px;
      height: 25px;
    }
  }
}

.notif {
  strong {
    font-size: 12px;
    line-height: 18px;

    @media (min-width: 1920px) and (min-height: 1080px) {
      font-size: 14px;
      line-height: 1.5;
    }

    @media (max-width: 1060px) and (min-width: 941px) {
      font-size: 1.1vw;
      max-width: 320px;
    }

    @media (max-width: 580px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
