<template>
  <div :class="$style[`section-${color}`]" ref="section">
    <v-page
      v-if="isBLog"
      :index="index"
      :title="sectionTitle"
      :description="sectionDescription"
      :theme="color"
      :nextStep="nextStep"
      :nextText="nextText"
      :band-deg="20"
      :symbol="{ value: '?' }"
      :is-data-last="isBLog && isMobile"
    >
      <v-list :list="blog" :theme="color" />
      <v-income :title="blogIncomeTitle" :text="blogIncomeText" step="25" />
    </v-page>
    <v-page
      v-if="isAiClone"
      :index="index"
      :title="sectionTitle"
      :description="sectionDescription"
      :theme="color"
      :nextStep="nextStep"
      :nextText="nextText"
      :band-deg="20"
      :symbol="{ value: '?' }"
      :is-data-last="isAiClone && isMobile"
    >
      <v-list :list="aiClone" :theme="color" />
    </v-page>
    <template v-if="isMobile ? !isBLog && !isAiClone : true">
      <div v-if="!isBLog && !isAiClone" :class="$style.dropdownInner" />
      <v-page
        v-for="(step, i) in business"
        :key="`how-it-work-${i}`"
        :data-anchor="`step-${i + 1}`"
        :index="index"
        :theme="color"
        ref="section"
        :title="sectionTitle"
        :description="sectionDescription"
        :symbol="{ value: step.letter }"
        :is-header="isMobile ? i === 0 : true"
        :is-box-shadow="isMobile"
        :nextStep="$t(`howItWorks.business.${step.slide}.nextStep`)"
        :nextText="$t(`howItWorks.business.${step.slide}.nextText`)"
        class="slide"
      >
        <v-list
          :list="step.data"
          :theme="color"
          :step="`${$t('step', [i + 1])}`"
          :title="$t(`howItWorks.business.${step.slide}.title`)"
        />
        <p
          v-html="$t('howItWorks.business.b.subText')"
          :class="[$style.text, $style[`text-${color}`]]"
          v-if="i === 1"
        ></p>
      </v-page>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import SlideWithSlider from '@/mixins/SlideWithSlider'

import VList from '@/components/list/VList.vue'
import VPage from '@/components/VPage.vue'
import VIncome from '@/components/VIncome.vue'

export default {
  name: 'HowItWork',
  components: { VPage, VList, VIncome },
  mixins: [SlideWithSlider],
  props: {
    color: {
      type: String,
    },
  },
  data() {
    return {
      business: [
        {
          title: this.$t('howItWorks.business.a.title'),
          slide: 'a',
          letter: 'A',
          data: [
            'howItWorks.business.a.text1',
            'howItWorks.business.a.text2',
            'howItWorks.business.a.text3',
          ],
        },
        {
          title: this.$t('howItWorks.business.b.title'),
          slide: 'b',
          letter: 'B',
          data: [
            'howItWorks.business.b.text1',
            'howItWorks.business.b.text2',
            'howItWorks.business.b.text3',
            'howItWorks.business.b.text4',
          ],
        },
        {
          title: this.$t('howItWorks.business.c.title'),
          slide: 'c',
          letter: 'C',
          data: [
            'howItWorks.business.c.text1',
            'howItWorks.business.c.text2',
            'howItWorks.business.c.text3',
          ],
        },
        {
          title: this.$t('howItWorks.business.d.title'),
          slide: 'd',
          letter: 'D',
          data: [
            'howItWorks.business.d.text1',
            'howItWorks.business.d.text2',
            'howItWorks.business.d.text3',
          ],
        },
      ],
      blog: [
        'howItWorks.blog.text1',
        'howItWorks.blog.text2',
        'howItWorks.blog.text3',
        'howItWorks.blog.text4',
      ],
      aiClone: ['howItWorks.aiClone.text1', 'howItWorks.aiClone.text2', 'howItWorks.aiClone.text3'],
    }
  },
  computed: {
    ...mapState(['isMobile']),
    sectionTitle() {
      return this.$t(this.isAiClone ? 'howItWorks.aiClone.title' : 'howItWorks.page.title')
    },
    nextStep() {
      return this.$t('howItWorks.page.nextStep')
    },
    nextText() {
      return this.$t('howItWorks.page.nextText')
    },
    sectionDescription() {
      return this.$t('howItWorks.page.description')
    },
    blogIncomeTitle() {
      return this.$t('calculateIncome')
    },
    blogIncomeText() {
      return `${this.$t('amountSubscribers')}:`
    },
  },
}
</script>
<style lang="scss" module>
.section {
  @include mobile-bg();

  @include media('mobile') {
    padding: 8px 0 0 0;
  }
}

.dropdownInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: none;

  @include media('mobile') {
    display: none;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 32px 0 0 0;

  @media (max-width: 1600px) and (min-width: 940px) {
    margin: 28px 0 0 0;
    font-size: 13px;
    line-height: 20px;
    max-width: 400px;
  }

  @media (max-width: 1060px) and (min-width: 940px) {
    font-size: 1.1vw;
    max-width: 320px;
  }

  @media (max-width: 580px) {
    margin: 24px 0 0 0;
    font-size: 12px;
    line-height: 18px;
  }

  &-light {
    color: $primary;
  }

  &-gray {
    color: $primary;
  }

  &-dark {
    color: white;
  }
}

.income {
  @include mobile-content;
}
</style>
