<template>
  <header id="header" :class="[$style.section, isMobileWhiteHeader && $style.sectionWhite]">
    <a v-html="$t('barText')" :href="barLink" :class="$style.bar"> </a>
    <div :class="$style.headerContentWrap">
      <div :class="$style.logo">
        <icon
          v-if="!isMobileWhiteHeader"
          :class="[$style.logoIcon, $route.hash === '#media' && $style.logoIconSecondary]"
          name="logo"
        />
        <icon v-else :class="$style.logoIconWhite" name="logo-mobile" />
      </div>
      <div :class="$style.content">
        <nav :class="[$style.nav, !isMobileWhiteHeader & isMobile && $style.navHide]">
          <ul :class="$style.list">
            <li v-for="({ to, value }, index) in links" :key="index">
              <router-link
                exact-path
                :to="`${to}#${routeToSection}`"
                custom
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  v-html="isMobile ? $t(`${value}`) : $t(`for${value}`)"
                  :class="[
                    $style.link,
                    isMobileWhiteHeader && $style.linkWhite,
                    isActive && $style.linkActive,
                    isExactActive && $style.linkActive,
                    isMobileWhiteHeader && isActive && $style.linkWhiteActive,
                    isMobileWhiteHeader && isExactActive && $style.linkWhiteActive,
                  ]"
                  :href="href"
                  @click="navigate"
                >
                </a>
              </router-link>
            </li>
            <li>
              <a
                target="_blank"
                :class="[$style.gptLink, theme === 'light' && $style.light]"
                href="https://gpt.supster.com"
                >SupsterGPT</a
              >
            </li>
            <li>
              <a
                target="_blank"
                :class="[
                  $style.link,
                  isMobileWhiteHeader && $style.linkWhite,
                  isMobileWhiteHeader && isActive && $style.linkWhiteActive,
                  isMobileWhiteHeader && isExactActive && $style.linkWhiteActive,
                ]"
                :href="about"
                >{{ $t('about') }}</a
              >
            </li>
          </ul>
        </nav>
        <div :class="$style.grow" />
        <div :class="[$style.wrapper, $route.hash !== '#hero' && $style.wrapperIcon]">
          <div
            :class="[
              $style.dropdownContainer,
              $route.hash === '#hero' && $style.dropdownContainerText,
              $route.fullPath === '/' && $style.dropdownContainerText,
              isMobileWhiteHeader & isMobile && $style.dropdownContainerHide,
            ]"
          >
            <multiselect
              :searchable="false"
              v-model="value"
              :options="options"
              :show-labels="false"
              id="multiselect"
              :hide-selected="true"
            ></multiselect>
          </div>
          <a v-if="!isMobile && !isAiClone" :class="$style.signUp" :href="`${registerLink}`">
            <icon :class="$style.signUpIcon" name="cross" />
            <span
              :class="[
                $style.signUpText,
                $route.hash === '#hero' && $style.signUpTextShow,
                $route.fullPath === '/' && $style.signUpTextShow,
              ]"
              >{{ $t('registration') }}</span
            >
          </a>
          <button
            v-if="!isMobile && isAiClone"
            :class="$style.signUp"
            @click="setIsShowAiClonePopUp(true)"
          >
            <icon :class="$style.signUpIcon" name="cross" />
            <span
              :class="[
                $style.signUpText,
                $route.hash === '#hero' && $style.signUpTextShow,
                $route.fullPath === '/' && $style.signUpTextShow,
              ]"
              >{{ $t('registration') }}</span
            >
          </button>
          <a
            :class="[$style.signIn, !isMobileWhiteHeader & isMobile && $style.signInMargin]"
            :href="`${loginLink}`"
          >
            <icon
              :class="[
                $style.signInIcon,
                isMobileWhiteHeader & isMobile && $style.signInIconPrimary,
              ]"
              name="login"
            />
            <span
              :class="[
                $style.signInText,
                $route.fullPath === '/' && $style.signInTextShow,
                $route.hash === '#hero' && $style.signInTextShow,
              ]"
              >{{ $t('personalArea') }}</span
            >
          </a>
        </div>
      </div>
      <the-burger
        :is-open="isOpenAside"
        :is-white="isMobileWhiteHeader"
        @update:is-open="$emit('update:is-open-aside', $event)"
      />
    </div>
  </header>
</template>
<script>
import pages from '@/data/pages'
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import TheBurger from '@/components/TheBurger.vue'
import i18n from '../plugins/i18n'

export default {
  components: { TheBurger, Multiselect },
  name: 'TheHeader',
  props: {
    isOpenAside: Boolean,
  },
  data() {
    return {
      // eslint-disable-next-line no-nested-ternary
      value: i18n.locale === 'en' ? 'En' : i18n.locale === 'ru' ? 'Ru' : 'Pt',
      options: ['Ru', 'En', 'Pt'],
      code: 'RU',
      isMobileWhiteHeader: window.scrollY > 100,
      links: [
        {
          to: 'business',
          value: 'Business',
        },
        {
          to: `${process.env.VUE_APP_BLOG}`,
          value: 'Blog',
        },
        {
          to: `${process.env.VUE_APP_AI_CLONE}`,
          value: 'ai',
        },
      ],
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value === 'En') {
          localStorage.setItem('lang', JSON.stringify('en'))
          i18n.locale = 'en'
        } else if (this.value === 'Ru') {
          localStorage.setItem('lang', JSON.stringify('ru'))
          i18n.locale = 'ru'
        } else {
          localStorage.setItem('lang', JSON.stringify('pt'))
          i18n.locale = 'pt'
        }
      },
    },
    locale() {
      if (this.value !== i18n.locale) {
        if (i18n.locale === 'ru') {
          this.value = 'Ru'
        } else if (i18n.locale === 'pt') {
          this.value = 'Pt'
        } else {
          this.value = 'En'
        }
      }
    },
    isMobile(isMobile) {
      if (isMobile) {
        window.addEventListener('scroll', this.onWhiteHeaderVisibleOnMobile)
        return
      }
      this.isMobileWhiteHeader = false
      window.removeEventListener('scroll', this.onWhiteHeaderVisibleOnMobile)
    },
  },
  mounted() {
    if (this.isMobile) {
      window.addEventListener('scroll', this.onWhiteHeaderVisibleOnMobile)
    }
  },
  computed: {
    ...mapState(['isMobile', 'activeSlideIndex', 'isTextHiden', 'dropDown', 'theme']),
    isAiClone() {
      return this.$route.name === 'ai'
    },
    routeToSection() {
      return !this.isMobile ? pages[this.$route.name].anchors[this.activeSlideIndex] : ''
    },
    registerLink() {
      // return `${process.env.VUE_APP_REGISTER}?lang=${i18n.locale}${
      //   this.searchParam ? `&ref=${this.searchParam}` : ''
      // }`
      return process.env.VUE_APP_TOP_BAR_LINK
    },
    loginLink() {
      return `${process.env.VUE_APP_LOGIN}?lang=${i18n.locale}${
        this.searchParam ? `&ref=${this.searchParam}` : ''
      }`
      // return process.env.VUE_APP_TOP_BAR_LINK
    },
    about() {
      return `${process.env.VUE_APP_ABOUT}?locale=${this.code}`
    },
    barLink() {
      // return `${process.env.VUE_APP_LOGIN}?lang=${i18n.locale}${
      //   this.searchParam ? `&ref=${this.searchParam}` : ''
      // }`

      return process.env.VUE_APP_TOP_BAR_LINK
    },
    locale() {
      return i18n.locale
    },
    ref() {
      return document.location.hash.split('/').at(-1)
    },
    searchParam() {
      const localRef = JSON.parse(localStorage.getItem('ref'))
      const { hash } = document.location

      if (hash.includes('/')) {
        localStorage.setItem('ref', JSON.stringify(this.ref))
        return this.ref
      }
      return localRef
    },
  },
  methods: {
    ...mapMutations(['setIsShowAiClonePopUp']),
    onWhiteHeaderVisibleOnMobile() {
      this.isMobileWhiteHeader = window.scrollY > 100
    },
    async getLocale() {
      try {
        const url = this.countriesUrl
        const response = await axios.get(url)
        this.code = response?.data?.data?.code
      } catch (error) {
        console.log(error?.message)
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" module>
.section {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  top: 0;
  width: 100%;
  z-index: 2;

  @include media('mobile') {
    grid-template-areas: 'logo content burger';
    grid-template-columns: 1fr auto;

    row-gap: 16px;
  }

  &White {
    grid-template-areas: 'logo content burger';
    grid-template-columns: minmax(28px, auto) 1fr minmax(30px, auto);
    column-gap: 16px;
    background-color: white;
    box-shadow: 0px 1px 10px rgba(39, 20, 96, 0.2);
    transition: background $transition ease-in;

    padding-bottom: 16px;
  }
}

.headerContentWrap {
  display: flex;
  padding: 4px 48px 0 48px;
  @media (max-width: 1600px) {
    padding: 4px 48px 0 48px;
  }

  @media (max-width: 756px) {
    padding: 4px 24px 0 24px;
  }
}

.dropdown {
  &Container {
    height: 36px;
    margin: 2px -11px 0 0;
    display: absolute;

    &Hide {
      display: none;
    }

    &Text {
      margin: 2px 24px 0 0;
    }

    @media (max-width: 580px) {
      margin: 2px 0 0 16px;
      height: 24px;
    }
  }
}

.logo {
  align-self: center;
  margin: 0 48px 0 0;
  //
  &Icon {
    width: 92px;
    height: 28px;
    color: var(--icon);

    @include media('mobile') {
      grid-area: logo;
      width: 92px;
      height: 28px;
    }

    &White {
      width: 28px;
      height: 28px;
      color: $primary;
    }

    &Secondary {
      color: $secondary;
    }
  }

  @include media('mobile') {
    margin: 0 12px 0 0;
  }

  @media (max-width: 670px) {
    margin: 0 16px 0 0;
  }

  @media (max-width: 580px) {
    margin: 0;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  @include media('mobile') {
    justify-content: space-between;
  }

  .grow {
    flex-grow: 1;
    height: 100%;

    @include media('mobile') {
      display: none;
    }
  }
}

.nav {
  align-self: center;

  &Hide {
    display: none;
  }
  li {
    @include media('mobile') {
      display: flex;
      align-items: center;
    }
  }
}

.list {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  column-gap: 36px;
  list-style-type: none;

  @media (max-width: 670px) {
    column-gap: 12px;
  }

  @include media('mobile') {
    column-gap: 8px;
  }
}

.link {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  text-shadow: 0.7px 0 transparent;
  padding: 0 6px;

  @media (max-width: 1600px) {
    font-size: 13px;
  }

  @include media('mobile') {
    color: white;
    font-size: 16px;
  }

  &:hover {
    opacity: 0.7;
  }

  &Value {
    cursor: pointer;
  }

  &::after {
    position: absolute;
    bottom: -12px;
    left: 0;
    display: block;
    width: 0;
    border-bottom: 4px solid $secondary;
    transition: width $transition ease-in-out;
    content: '';
  }

  &Active {
    position: relative;
    margin: 0;
    text-shadow: 0.7px 0 var(--text);

    &::after {
      width: 100%;
    }
  }

  &White {
    color: $primary;
    font-size: 14px;
    line-height: 14px;

    &::after {
      bottom: -22px;
      border-bottom: 3px solid $secondary;
    }

    &Active {
      text-shadow: 0.7px 0 $primary;
    }
  }
}
.gptLink {
  color: $secondary;
  text-decoration: none;
  position: relative;

  &::after {
    content: 'NEW!';
    border: 1px solid $secondary;
    font-size: 10px;
    border-radius: 4px;
    padding: 0 4px;
    position: relative;
    top: -10px;
    margin-left: 6px;
    font-weight: 800;
  }
  @include media('mobile') {
    position: relative;
    color: $primary;
    font-size: 14px;
    &::after {
      position: absolute;
      background: $secondary;
      top: calc(-100% - 2px);
      border-radius: 0 0 4px 4px;
      left: 15px;
    }
  }
  &.light {
    color: $primary;
    &::after {
      background: $secondary;
    }
  }
}

.wrapper {
  position: relative;
  display: grid;
  column-gap: 12px;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  padding: 0 31px 0 0;

  &Icon {
    column-gap: 48px;
  }

  @include media('mobile') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    flex-grow: 1;
    column-gap: 0;
    &Icon {
      column-gap: 12px;
    }
  }
}

.sectionWhite .wrapper {
  flex-grow: 0;
}

.signUp {
  grid-auto-flow: column;
  align-items: center;
  text-decoration: none;
  column-gap: 12px;
  background-color: transparent;
  border: 0;

  /* stylelint-disable */
  @include button;
  /* stylelint-enable */

  &Icon {
    width: 16px;
    height: 16px;
    color: var(--icon);
  }

  &Text {
    font-weight: 400;
    font-size: 12px;
    margin: 0 24px 0 0;
    display: none;

    &Show {
      display: block;

      @include media('mobile') {
        display: none;
      }
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.signIn {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 11px;
  align-items: center;
  text-decoration: none;
  border: 0;

  &Margin {
    margin: 0 16px 0 0;
  }

  &Text {
    margin: 1px 0 0 0;
    font-weight: 400;
    font-size: 12px;
    display: none;

    &Show {
      display: block;

      @include media('mobile') {
        display: none;
      }
    }
  }

  &Icon {
    width: 16px;
    height: 16px;
    color: var(--icon);

    &Primary {
      color: $primary;
    }

    @include media('mobile') {
      width: 16px;
      height: 16px;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
.bar {
  background-image: linear-gradient(58deg, #0071ff, #3ccf91 72%, #5e6ad2);
  width: 100%;
  padding: 7px 20px;
  text-align: center;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}
</style>
