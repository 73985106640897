<template>
  <main :class="$style.section">
    <div :class="$style.wrapper">
      <icon :class="$style.logo" name="logo" />
      <router-link to="/" :class="[$style.link, $style.linkLogo]">
        <icon :class="$style.linkLogoIcon" name="left" />
        <span :class="$style.linkLogoText">{{ $t('rules.toMain') }}</span>
      </router-link>
    </div>
    <div :class="$style.content">
      <h2 :class="$style.title">{{ $t('rules.title') }}</h2>
      <h3 :class="$style.subtitle">1. {{ $t('rules.subtitles.1') }}</h3>
      <p :class="$style.paragraph">1.1. {{ $t('rules.paragraphs.text1_1') }}</p>
      <p :class="$style.paragraph">1.2. {{ $t('rules.paragraphs.text1_2') }}</p>
      <p :class="$style.paragraph">1.3. {{ $t('rules.paragraphs.text1_3') }}</p>
      <p :class="[$style.paragraph, $style.shadow]">
        1.3.1. {{ $t('rules.paragraphs.text1_3_1') }}
      </p>
      <p :class="[$style.paragraph, $style.shadow]">
        1.3.2. {{ $t('rules.paragraphs.text1_3_2') }}
      </p>
      <p :class="$style.paragraph">1.4. {{ $t('rules.paragraphs.text1_4') }}</p>
      <p :class="$style.paragraph">1.6. {{ $t('rules.paragraphs.text1_6') }}</p>
      <p :class="$style.text" v-html="$t('rules.textSub')" />
      <p :class="$style.paragraph">1.8. {{ $t('rules.paragraphs.text1_8') }}</p>
      <ul :class="[$style.list, $style.shadow]">
        <li :class="$style.item">{{ $t('rules.list.1') }}</li>
        <li :class="$style.item">{{ $t('rules.list.2') }}</li>
        <li :class="$style.item">{{ $t('rules.list.3') }}</li>
        <li :class="$style.item">{{ $t('rules.list.4') }}</li>
      </ul>
      <h3 :class="$style.subtitle">3. {{ $t('rules.subtitles.3') }}</h3>
      <p :class="$style.paragraph">3.1. {{ $t('rules.paragraphs.text3_1') }}</p>
      <p :class="$style.paragraph">3.3. {{ $t('rules.paragraphs.text3_3') }}</p>
    </div>
    <div :class="$style.wrapper">
      <p :class="[$style.link, $style.date]">{{ `© ${new Date().getFullYear()}. Supster` }}</p>
      <a :class="$style.link" target="_blank">{{ $t('rules.title') }}</a>
    </div>
  </main>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'RulesForUse',
  mounted() {
    const params = new URL(document.location).searchParams
    const locale = params.get('locale')
    i18n.locale = locale
  },
}
</script>
<style lang="scss" module>
main {
  height: auto !important;
}
.section {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: $padding * 1.5;
  background-color: $band-light;
  box-sizing: border-box;

  @include media('mobile') {
    padding: $padding;
  }
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  justify-content: space-between;
}

.logo {
  width: 92px;
  height: 28px;
  color: $primary;
  --logo: #3dffc0;
}

.link {
  position: relative;
  z-index: 2;
  align-self: center;
  color: $primary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    opacity: 0.7;

    svg {
      transform: translateX(-5px);
    }
  }

  &Logo {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 8px;

    &Icon {
      align-self: center;
      width: 24px;
      height: 4px;
      margin-top: -2px;
      color: $primary;
      transition: transform $transition;
    }

    &Text {
      align-self: center;
      color: $primary;
    }
  }
}

.date {
  color: $primary;

  &:hover {
    opacity: 1;
  }
}

.content {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  background-color: white;
  box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.1);
  border-radius: 8px;
  padding: 120px 96px;

  @include media('mobile') {
    padding: 24px;
  }
}

.title {
  margin: 0 0 48px 0;
  font-size: 48px;
  line-height: 1.21;
  color: $primary;
  letter-spacing: -0.05em;

  @include media('mobile') {
    font-size: 32px;
    margin: 0 0 24px 0;
  }
}

.subtitle {
  margin: 0 0 48px 0;
  font-size: 24px;
  line-height: 1.21;
  color: $primary;
  letter-spacing: -0.05em;

  @include media('mobile') {
    font-size: 16px;
    margin: 0 0 24px 0;
  }
}

.paragraph {
  margin: 0 0 16px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
  color: $primary;

  @include media('mobile') {
    font-size: 13px;
  }
}

.shadow {
  opacity: 0.4;
  padding: 0 0 0 16px;
}

.text {
  margin: 0 0 24px 0;
  padding: 20px 24px;
  border-radius: 8px;
  background-color: $band-light;
  color: $primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;

  @include media('mobile') {
    font-size: 13px;
    margin: 0 0 16px 0;
  }

  span {
    font-weight: 900;
    color: $primary;
  }
}

.list {
  margin: 0 0 48px 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;

  .item {
    position: relative;
    font-size: 14px;
    line-height: 1.7;
    margin: 0 0 8px 0;
    color: $primary;
    font-weight: 400;
    padding: 0 0 0 24px;

    @include media('mobile') {
      font-size: 13px;
    }

    &::before {
      position: absolute;
      top: 12px;
      left: 0px;
      content: '';
      width: 8px;
      height: 2px;
      background-color: $secondary;
    }
  }
}
</style>
