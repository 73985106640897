const colors = ['dark', 'light', 'light', 'gray', 'light', 'light']

export default {
  business: {
    anchors: ['hero', 'how-it-work', 'income', 'media', 'pay-or-crm', 'sales'],
    colors,
    slider: ['how-it-work', 'income'],
  },
  blog: {
    anchors: ['hero', 'how-it-work', 'income', 'media', 'pay-or-crm', 'sales'],
    colors,
  },
  ai: {
    anchors: ['hero', 'how-it-work', 'income', 'media', 'pay-or-crm', 'sales'],
    colors,
  },
}
