/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'webmoney': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.943.001c1.872 0 3.654.436 5.252 1.215.251.114.503.252.753.39l-1.095.986-1.644-1.72-2.831 2.522-1.668-1.81-5.206 4.699 3.334 3.69-1.302 1.145 3.289 3.69-1.302 1.147 4.706 5.25 2.784-2.569 2.422 2.752c-.48.367-1.005.734-1.576 1.054A11.888 11.888 0 0111.966 24C5.366 24 0 18.615 0 11.99-.022 5.411 5.344 0 11.943 0v.001zM9.75 11.256l2.856-2.568 2.557 2.866-2.856 2.567-2.557-2.866zm2.032 4.952l2.834-2.59 2.58 2.865-2.856 2.568-2.557-2.843zM7.72 6.373l2.854-2.567 2.559 2.865-2.856 2.569-2.557-2.867zM12.95 5l2.122-1.926 1.919 2.131-2.125 1.949-1.918-2.154h.001zm3.79 8.825l2.122-1.926 1.92 2.154-2.125 1.927-1.918-2.155zm1.872 4.332l2.124-1.925 1.917 2.131-2.123 1.948-1.918-2.154zm.867-9.904l1.417-1.282 1.278 1.42-1.415 1.284-1.28-1.422zm-1.826-4.24l1.416-1.306 1.28 1.445-1.417 1.284-1.279-1.422v-.001zm3.632 8.483l1.415-1.284L24 12.63l-1.439 1.284-1.278-1.42zm-6.372-3.188l2.124-1.925 1.918 2.132-2.123 1.926-1.919-2.132z"/>'
  }
})
