/* eslint-disable */
var arrow = require('vue-svgicon')
arrow.register({
  arrow: {
    width: 12,
    height: 7,
    viewBox: '0 0 14 7',
    data: '<path d="M12.8699 0.840873L7.3699 6.84087C7.2699 6.94087 7.1399 7.00087 6.9999 7.00087C6.8599 7.00087 6.7299 6.94087 6.6299 6.84087L1.1299 0.840873C0.939897 0.640873 0.959897 0.320873 1.1599 0.130873C1.3599 -0.0591266 1.6799 -0.0391266 1.8699 0.160873L6.9999 5.76087L12.1299 0.160873C12.3199 -0.0391266 12.6299 -0.0591266 12.8399 0.130873C13.0399 0.320873 13.0599 0.630873 12.8699 0.840873Z"/>',
  },
})
