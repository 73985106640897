<template>
  <component
    @click="$emit('click')"
    :is="tagName"
    :to="to"
    :href="href"
    :class="[
      $style.button,
      isNoTag && $style.buttonNoTag,
      icon && $style.buttonIcon,
      isSocial && $style.buttonSocial,
      disabled && $style.disabled,
    ]"
    :disabled="disabled"
  >
    <icon :class="$style.icon" v-if="icon" :name="icon" />
    <template :class="$style.content">
      <slot />
    </template>
  </component>
</template>
<script>
export default {
  name: 'VButton',
  props: {
    icon: String,
    href: String,
    to: String,
    isSocial: Boolean,
    isNoTag: Boolean,
    disabled: Boolean,
  },
  computed: {
    tagName() {
      if (this.isNoTag) {
        return 'div'
      }
      if (this.to) {
        return 'router-link'
      }
      if (this.href) {
        return 'a'
      }
      return 'button'
    },
  },
}
</script>
<style lang="scss" module>
.button {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $padding / 2 $padding;
  color: $primary;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: $secondary;
  border-radius: 16px;
  cursor: pointer;
  transition: background $transition ease-in, box-shadow $transition ease-in;

  @include media('mobile') {
    padding: $padding / 2 $padding;
    font-size: 16px;
    border-radius: 24px;
  }

  &:hover {
    background-color: rgba($secondary, 0.7);
  }

  &Icon {
    grid-template-columns: minmax(auto, 16px) max-content;
    column-gap: 8px;

    @include media('desktop') {
      column-gap: 0.615vw;
    }

    @include media('mobile') {
      column-gap: 8px;
    }
  }

  &Social {
    padding: $padding / 2;
    color: $primary;
    background-color: white;
    border-radius: 16px;
    height: 48px;
    box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.1);

    @media (max-width: 1600px) and (min-width: 941px) {
      padding: 8px;
      height: 40px;
    }

    @include media('mobile') {
      min-width: 48px;
    }

    @media (max-width: 580px) {
      min-width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 12px;
      height: 40px;
      border-radius: 14px;
      line-height: 1;

      svg {
        max-height: 24px;
      }
    }

    &:hover {
      background-color: rgba(white, 0.7);
      box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.3);
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &NoTag {
    cursor: auto;
  }
}

.content {
  justify-self: start;
}

.icon {
  justify-self: end;
  width: 16px;
  height: 16px;
  color: currentColor;

  @include media('desktop') {
    width: 1.231vw;
    height: 1.231vw;
  }

  @include media('mobile') {
    width: 16px;
    height: 16px;
  }
}
</style>
