/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 10,
    height: 16,
    viewBox: '0 0 10 16',
    data: '<path pid="0" d="M7.24 0H2.76A2.76 2.76 0 000 2.76v10.48A2.76 2.76 0 002.76 16h4.48A2.76 2.76 0 0010 13.24V2.76A2.76 2.76 0 007.24 0zM9 13.24C9 14.21 8.21 15 7.24 15H2.76C1.79 15 1 14.21 1 13.24V2.76C1 1.79 1.79 1 2.76 1h4.48C8.21 1 9 1.79 9 2.76v10.48zM3 13h4v1H3v-1z"/>'
  }
})
