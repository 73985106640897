<template>
  <transition name="fade">
    <div :class="$style.section">
      <div :class="$style.bounce">
        <div v-for="circle in 2" :key="circle" :class="$style.circle"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ThePreloader',
  data() {
    return {}
  },
}
</script>
<style lang="scss" module>
.section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}

.bounce {
  position: relative;
  width: 100px;
  height: 100px;
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary;
  border-radius: 50%;
  opacity: 0.6;
  animation: preloader 2s infinite ease-in-out;

  @keyframes preloader {
    // eslint-disable-next-line prettier/prettier

    0%,
    100% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }
  }

  &:nth-child(2) {
    animation-delay: -1s;
  }
}
</style>
