<template>
  <div ref="section" :class="$style[`section-${color}`]">
    <v-page
      v-if="isBLog"
      :index="index"
      :theme="color"
      :band-deg="40"
      :title="$t('income.blog.page.title')"
      :nextStep="$t('income.blog.page.nextStep')"
      :nextText="$t('income.blog.page.nextText')"
      :description="$t('income.blog.page.description')"
    >
      <v-list :list="blog" :theme="color" />
      <div v-if="isMobile && isBLog" :class="$style.content">
        <p v-html="$t('income.blog.subText')" :class="$style.text" />
        <div :class="$style.wrapper">
          <img
            :class="$style.image"
            src="@/assets/images/apps-flyer.png"
            alt="apps-flyer"
            draggable="false"
          />
          <img
            :class="$style.image"
            src="@/assets/images/fbsdk.png"
            alt="fbsdk"
            draggable="false"
          />
        </div>
      </div>
      <template v-if="!isMobile">
        <p v-html="$t('income.blog.subText')" :class="$style.text" />
        <div :class="$style.wrapper">
          <img
            :class="$style.image"
            src="@/assets/images/apps-flyer.png"
            alt="apps-flyer"
            draggable="false"
          />
          <img
            :class="$style.image"
            src="@/assets/images/fbsdk.png"
            alt="fbsdk"
            draggable="false"
          />
        </div>
      </template>
    </v-page>
    <v-page
      v-if="isAiClone"
      :index="index"
      :theme="color"
      :band-deg="40"
      :title="$t('income.aiClone.page.title')"
      :nextStep="$t('income.aiClone.page.nextStep')"
      :nextText="$t('income.aiClone.page.nextText')"
      :description="$t('income.aiClone.page.description')"
    >
      <v-list :list="aiClone" :theme="color" />
      <div v-if="isMobile && isAiClone" :class="$style.content">
        <p v-html="$t('income.aiClone.subText')" :class="$style.text" />
        <div :class="$style.wrapper">
          <img
            :class="$style.image"
            src="@/assets/images/apps-flyer.png"
            alt="apps-flyer"
            draggable="false"
          />
          <img
            :class="$style.image"
            src="@/assets/images/fbsdk.png"
            alt="fbsdk"
            draggable="false"
          />
        </div>
      </div>
      <template v-if="!isMobile">
        <p v-html="$t('income.aiClone.subText')" :class="$style.text" />
        <div :class="$style.wrapper">
          <img
            :class="$style.image"
            src="@/assets/images/apps-flyer.png"
            alt="apps-flyer"
            draggable="false"
          />
          <img
            :class="$style.image"
            src="@/assets/images/fbsdk.png"
            alt="fbsdk"
            draggable="false"
          />
        </div>
      </template>
    </v-page>
    <template v-if="isMobile ? !isBLog && !isAiClone : true">
      <div v-if="!isBLog && !isAiClone" :class="$style.dropdownInner" />
      <v-page
        v-for="(step, i) in business"
        :key="`control-system-${i}`"
        :data-anchor="`step-${i + 1}`"
        :index="index"
        :theme="color"
        :band-deg="40"
        :image="step.image"
        :symbol="{ value: step.letter }"
        :title="$t('income.business.page.title')"
        :description="$t('income.business.page.description')"
        :is-header="isMobile ? i === 0 : true"
        :is-box-shadow="isMobile"
        :nextStep="$t(`income.business.${step.slide}.nextStep`)"
        :nextText="$t(`income.business.${step.slide}.nextText`)"
        class="slide"
      >
        <v-list
          :list="step.data"
          :theme="color"
          :title="$t(`income.business.${step.slide}.title`)"
        />
      </v-page>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import SlideWithSlider from '@/mixins/SlideWithSlider'

import VPage from '@/components/VPage.vue'
import VList from '@/components/list/VList.vue'

export default {
  name: 'Income',
  components: { VPage, VList },
  mixins: [SlideWithSlider],
  props: {
    color: {
      type: String,
    },
  },

  data() {
    return {
      business: [
        {
          title: this.$t('income.business.a.title'),
          slide: 'a',
          nextStep: this.$t('income.business.a.nextStep'),
          nextText: this.$t('income.business.a.nextText'),
          letter: 'A',
          image: 'phone',
          link: '1',
          data: [
            'income.business.a.text1',
            'income.business.a.text2',
            'income.business.a.text3',
            'income.business.a.text4',
          ],
        },
        {
          title: this.$t('income.business.b.title'),
          slide: 'b',
          nextStep: this.$t('income.business.b.nextStep'),
          nextText: this.$t('income.business.b.nextText'),
          letter: 'B',
          image: 'laptop',
          link: '2',
          data: ['income.business.b.text1', 'income.business.b.text2', 'income.business.b.text3'],
        },
        {
          title: this.$t('income.business.c.title'),
          slide: 'c',
          nextStep: this.$t('income.business.c.nextStep'),
          nextText: this.$t('income.business.c.nextText'),
          letter: 'C',
          image: 'laptop',
          data: ['income.business.c.text1', 'income.business.c.text2'],
        },
        {
          title: this.$t('income.business.d.title'),
          slide: 'd',
          nextStep: this.$t('income.business.d.nextStep'),
          nextText: this.$t('income.business.d.nextText'),
          letter: 'D',
          data: ['income.business.d.text1'],
        },
        {
          title: this.$t('income.business.e.title'),
          slide: 'e',
          nextStep: this.$t('income.business.e.nextStep'),
          nextText: this.$t('income.business.e.nextText'),
          letter: 'E',
          image: 'laptop',
          data: [
            'income.business.e.text1',
            'income.business.e.text2',
            'income.business.e.text3',
            'income.business.e.text4',
            'income.business.e.text5',
          ],
        },
      ],
      blog: ['income.blog.text1', 'income.blog.text2', 'income.blog.text3', 'income.blog.text4'],
      aiClone: ['income.aiClone.text1', 'income.aiClone.text2', 'income.aiClone.text3'],
    }
  },
  computed: {
    isAiClone() {
      return this.$route.name === 'ai'
    },
    ...mapState(['isMobile']),
  },
}
</script>
<style lang="scss" module>
.section {
  @include mobile-bg();
}

.dropdownInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: none;

  @include media('mobile') {
    display: none;
  }
}

.text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
  max-width: 520px;
  padding: 0 0 0 40px;

  b {
    font-weight: 700;
  }

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 18px;
    line-height: 1.5;
    max-width: 640px;
  }

  @media (max-width: 1600px) and (min-width: 941px) {
    font-size: 13px;
    line-height: 1.5;
    max-width: 420px;
  }

  @media (max-width: 1060px) and (min-width: 941px) {
    font-size: 1.1vw;
    padding: 0 0 0 30px;
    max-width: 320px;
  }

  @include media('mobile') {
    padding: 0 0 0 30px;
  }

  @media (max-width: 580px) {
    padding: 0 0 0 28px;
    margin-bottom: 24px;
    font-size: 13px;
    line-height: 20px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  column-gap: 16px;

  @include media('mobile') {
    filter: drop-shadow(15px 15px 60px rgba(39, 20, 96, 0.1));
  }

  @media (max-width: 580px) {
    width: calc(100% + 8px);
    grid-template-columns: repeat(auto-fill, 148px);
    justify-content: center;
    column-gap: 8px;
    row-gap: 16px;
    margin: 0 auto 8px auto;
  }

  @media (max-width: 1300px) and (min-width: 941px) {
    grid-template-columns: repeat(auto-fill, 110px);
  }
}

.image {
  width: 160px;
  height: 48px;
  border-radius: 16px;

  @media (max-width: 580px) {
    width: 140px;
    height: 40px;
  }

  @media (max-width: 1300px) and (min-width: 941px) {
    width: 110px;
    height: 36px;
    border-radius: 16px;
  }
}
</style>
