export default {
  registration: 'Registro',
  personalArea: 'Entrar no escritório',
  toUser: 'To the user',
  forBusiness: 'Para os negócios',
  Business: 'Negócios',
  forBlog: 'Para o blogue',
  Blog: 'Blogue',
  forai: 'Ai-Clone',
  ai: 'Ai-Clone',
  aboutCompany: 'About company',
  fitsInWorld: 'O primeiro do mundo',
  worldPlatform: 'plataforma',
  forBusinessAndInfluencers:
    '{text} {br} {text2} {br} para os negócios {br} e formadores de opinião opiniões',
  forAiCloneTitle: '{text} {br} {text2} {br} {text3}',
  createPageInFiveMinutes: 'Crie uma página em 5 minutos e início {br} aceitar pagamentos {link}',
  createPageInFiveMinutesForAiClone:
    'Crie um gêmeo digital em 5 minutos{br} {text} comece a ganhar {text2}',
  bySubscription: 'por assinatura!',
  go: 'Comece!',
  step: 'Etapa {0}',
  calculateIncome: 'Calcule sua renda:',
  amountSubscribers: 'Número de assinantes',
  estimatedIncome: 'Estimativa de renda',
  toCard: 'Para o cartão',
  businessAccount: 'P/S do IE',
  yandexMoney: 'YMoney',
  requisites: 'Requisites',
  about: 'About',
  offerForThePurchase: 'Purchase and sale offer',
  rulesForUse: 'Regras de uso',
  privacyPolicy: 'Política de Privacidade',
  policyOfLegalization: 'Politica de legalizacao',
  aboutProject: 'About the project',
  jobs: 'Jobs',
  contacts: 'Contacts',
  cookies: 'Cookie policy',
  aiClone: {
    title: {
      1: 'Clone de IA',
      2: 'novo nível',
      3: 'interação com o público',
    },
    footer: {
      1: 'e',
      2: 'quando você dorme!',
    },
  },
  howItWorks: {
    page: {
      title: 'Como funciona',
      description: 'Process and calculations',
      nextStep: 'Seguir:',
      nextText: 'Sua renda',
    },
    business: {
      a: {
        title: 'Criação de um site',
        text1:
          '<b>Pense no endereço</b> de sua página <b>ou conecte seu domínio</b>. É aqui que os usuários encontrarão sua página.',
        text2: '<b>Publicar o conteúdo</b> que seus clientes gostam e manter contato com eles.',
        text3:
          '<b>Um sistema</b> de gerenciamento de website de fácil utilização não requer nenhum conhecimento profissional de construção de website. </br> Até mesmo um novato pode descobrir isso.',
        nextStep: 'Próximo passo',
        nextText: 'Serviços de instalação',
      },
      b: {
        title: 'Serviços de instalação',
        text1: 'Alimentação de mercadorias',
        text2: 'Serviços de assinatura',
        text3: 'Clubes privados próprios',
        text4: 'Mercadorias por assinatura',
        subText:
          'Seu cliente organiza a assinatura, e <b>nosso serviço controla</b> o processo de débito automático da conta do cliente quando o próximo pagamento é devido.',
        nextStep: 'Próximo passo',
        nextText: 'Vendas',
      },
      c: {
        title: 'Vendas',
        text1:
          '<b>Receber pedidos e comunicar-se</b> com os visitantes através de uma interface amigável e construir um relacionamento com cada cliente em potencial.',
        text2:
          '<b>Criar bate-papos gerais</b> para interesses  e construir  comunidade em torno de sua marca.',
        text3:
          '<b>Postar depoimentos</b> de clientes, assim aumentar credibilidade junto aos clientes potenciais que visitam seu site.',
        nextStep: 'Próximo passo',
        nextText: 'Expansão dos negócios',
      },
      d: {
        title: 'Expansão dos negócios',
        text1:
          '<b>Criar suas próprias páginas de vendas</b> com nosso construtor e  lugar em qualquer bloco.',
        text2:
          '<b>Criar  páginas promocionais</b> com descontos e enviar  a seus visitantes ou clientes existentes.',
        text3:
          '<b>Criar  páginas de texto</b> com um editor de fácil utilização e escrita longreads úteis.',
        nextStep: 'Seguir',
        nextText: 'Sistema de gestão',
      },
    },
    aiClone: {
      title: 'Como manter a singularidade ao se comunicar com um grande público?',
      text1: '<b>Crie uma versão digital</b> de si mesmo.',
      text2: '<b>Escolha o formato:</b> site, bot ou aplicativo móvel.',
      text3:
        '<b>Carregue</b> sua experiência, pensamentos e personalidade no sistema e<br/> <b>ganhe dinheiro</b> através de assinaturas de fãs gratos.',
    },
    blog: {
      text1: '<b>Pense em o endereço</b> de sua página.',
      text2: '<b>Escolha o formato</b> do blogue: página ou canal.',
      text3:
        '<b>Compartilhe fotos</b> vídeos e outros conteúdos exclusivos e ganhe assinaturas de fãs agradecidos.',
    },
  },
  income: {
    business: {
      page: {
        title: 'Sistema de gestão',
        description: 'Manage your business page with a user-friendly CRM.',
      },
      a: {
        title: 'Capacidade de resposta e mobilidade',
        text1: 'Seus gerentes podem responder rapidamente <b>mesmo pelo telefone!</b>',
        text2: '<b>Publicar conteúdo</b>, gravar "círculos" - mensagens.',
        text3: 'Receber <b>notificações push</b> para novos pedidos.',
        text4: '<b>Versão móvel</b> CRM para o trabalho.',
        nextStep: 'O que mais o CRM fornece',
        nextText: 'Gestão de usuários',
      },
      b: {
        title: 'Gestão de usuários',
        text1:
          '<b>Crie categorias de usuários</b> e atribua-as a seus visitantes. Você pode atribuir auto-regras, e o sistema irá atribuir uma categoria ao usuário com base em múltiplos critérios.',
        text2:
          'Todo visitante se torna na caixa de diálogo <b>do CRM.</b> Você pode escrever para ele ou configurar uma auto-referência de mensagens.',
        text3:
          'Envie mensagens a seus visitantes e eles serão instantaneamente  entregue com uma <b>notificação push.</b>',
        nextStep: 'O que mais o CRM fornece',
        nextText: 'Analítica, relatórios, pagamentos',
      },
      c: {
        title: 'Analítica, relatórios, pagamentos',
        text1:
          '<b>Analise seu funil de vendas,</b>, encontre fraquezas e aumente seus próprios lucros.',
        text2: 'Criar <b>relatórios </b>financeiros, gerar <b>pagamentos.</b>',
        nextStep: 'O que mais o CRM fornece',
        nextText: 'Seus próprios autofunis',
      },
      d: {
        title: 'Seus próprios autofunis',
        text1:
          '<b>Um funil</b> de venda automática é a promoção de um usuário através do funil de venda por meio de auto-mensagens e coleta de informações do cliente. O funil de vendas é o passo-a-passo que um cliente toma do conhecimento de seu produto até a compra.',
        nextStep: 'O que mais o CRM fornece',
        nextText: 'Máximo de dados do cliente',
      },
      e: {
        title: 'Máximo de dados do cliente',
        text1: 'De quais <b>propagandas</b> o usuário clicou.',
        text2: 'O que o encorajou a tomar a ação dirigida.',
        text3: '<b>O modelo do dispositivo</b> e outros dados.',
        text4:
          'Transferir facilmente <b>dados de conversão</b> para redes de anúncios no Facebook/Google através da tecnologia Pixel.',
        text5: 'Analisar que tipo de clientes estão gerando a <b>maior receita</b>.',
        nextStep: 'Seguir',
        nextText: 'Máxima conveniência',
      },
    },
    blog: {
      page: {
        title: 'Sua renda',
        description: 'What exactly do you make money on',
        nextStep: 'Seguir',
        nextText: 'Sua exposição na mídia',
      },
      text1:
        'Vender<b> formatos </b>exclusivos <b> de publicidade </b> para empresas: marca do canal, bate-papos, fundo de mensagens.',
      text2: 'Realizar <b>transmissões</b> de publicidade.',
      text3: 'Vender <b> posts </b>promocionais.',
      text4: 'Vender comércio ou serviços através de uma <b> loja integrada</b>.',
      subText:
        '<b>Importante</b>: As capacidades técnicas do Supster permitem ao anunciante analisar os resultados das campanhas publicitárias.',
    },
    aiClone: {
      page: {
        title: 'Supster - plataforma para criar seu clone de IA',
        description: 'No que exatamente você ganha dinheiro',
        nextStep: 'Próximo',
        nextText: 'Sua fama',
      },
      text1:
        'Queremos levar às massas a comunicação <b>um a um</b> com celebridades e pessoas influentes.',
      text2:
        'Vamos criar um clone <b>por meio de várias fontes</b> de dados, como chats, e-mails, postagens em redes sociais, vídeos no YouTube, arquivos de áudio e muito mais.',
      text3:
        'plataforma de clonagem digital que captura seu pensamento, tornando seu conhecimento, experiência, personalidade e opiniões <b>disponíveis para os outros de forma personalizada</b>.',

      subText:
        '<b>A ideia principal do Supster é permitir que personalidades famosas interajam com um grande número de pessoas</b>',
    },
  },
  media: {
    business: {
      page: {
        title: 'Máxima conveniência',
        description: '1-click application',
        nextStep: 'Seguir',
        nextText: 'Máxima flexibilidade',
      },
      list: {
        text1:
          'Crie um aplicativo móvel para sua empresa em 1 clique e comece a atrair novos clientes de fontes anteriormente indisponíveis, como Google UAC, Unity Ads, Applovin e outras redes de publicidade de aplicativos móveis.',
        text2:
          'Conectar Apple Pay e Google Pay e aumentar a conversão para compra através da experiência do usuário.',
        subText:
          'Se você aceita pagamentos de assinatura,, você pode esqueça o fato de que os clientes não farão seus pagamentos a tempo. O dinheiro é deduzido <b>automaticamente</b> sem exigir uma segunda confirmação do usuário.',
      },
    },
    blog: {
      page: {
        title: 'Sua exposição na mídia',
        description: 'Grow with Supster',
        nextStep: 'Seguir',
        nextText: 'Seu benefício',
      },
      list: {
        text1:
          'Crie seu próprio aplicativo móvel pessoal em 5 minutos e comece a receber novos assinantes do Google Play e AppStore!',
        text2:
          'Criar bots úteis em Telegramas / Viber / WhatsApp e postar conteúdo automaticamente.',
      },
    },
    aiClone: {
      page: {
        title: 'Scalability, Accessibility, Passive Income',
        description: 'App in 1 click',
        nextStep: 'Next',
        nextText: 'Your benefit',
      },
      list: {
        text1:
          'An AI clone can interact with multiple subscribers simultaneously, providing each with a personalized experience, regardless of audience size.',
        text2:
          'Influencers get the opportunity to monetize their knowledge and experience through a subscription system, even when they are not personally working.',
      },
    },
  },
  payOrCrm: {
    business: {
      page: {
        title: 'Máxima flexibilidade',
        description: 'Own bots and integration with any CRM',
        nextStep: 'O que mais o CRM fornece',
        nextText: 'Aumente suas vendas!',
      },
      list: {
        text1:
          'Agregar todas as mensagens em um só lugar. Crie seu próprio Telegrama / Viber / WhatsApp bot em 5 minutos.',
        text2: '<b>Integrar-se</b> com qualquer CRM.',
      },
    },
    blog: {
      page: {
        title: 'Seu benefício',
        description: 'Permanent income and easy withdrawal of money',
        nextStep: 'Seguir',
        nextText: 'Aumente suas vendas!',
      },
      list: {
        text1: '<b>Retire seus ganhos</b> em 1 clique por qualquer meio conveniente.',
        subText:
          '<b>Lembre-se:</b> uma pessoa que tenha adquirido uma assinatura gerará regularmente renda passiva, desde que não peça para cancelar a assinatura.',
      },
    },
    aiClone: {
      page: {
        title: 'Seu benefício',
        description: 'Renda constante e retirada fácil de fundos',
        nextStep: 'Próximo',
        nextText: 'Aumente suas vendas!',
      },
      list: {
        text1: '<b>Retire seus ganhos</b> com apenas 1 clique de qualquer forma conveniente.',
        subText:
          '<b>Lembre-se:</b> a pessoa que comprou a assinatura continuará gerando renda passiva regularmente até solicitar o cancelamento.',
      },
    },
  },
  sales: {
    page: {
      title: 'Aumente suas vendas <br /> e otimizar seus processos comerciais',
      titleAiClone:
        'Você está pronto para fazer parte da revolução digital e criar uma nova tendência?',
      button: 'Comece',
      blog: {
        title: 'Leia o blog!',
        description: 'Publicamos regularmente artigos úteis <br /> informações em nosso blog.',
        link: 'Estudos de casos de commodities para outubro.',
      },
      aiClone: {
        title: 'Leia nosso blog!',
        description: 'Publicamos regularmente informações úteis em nosso blog.',
        link: 'Estudos de caso do SupsterGPT',
      },
      youTube: {
        title: 'Estude os casos!',
        description: 'Assista aos nossos vídeos de estudo de caso <br /> e tutoriais no YouTube.',
        link: 'Nosso canal no YouTube',
      },
      telegram: {
        title: 'Pergunte sobre Supster!',
        description:
          'Deseja discutir o sistema e conversar <br /> com nossos clientes? Bem-vindo ao bate-papo!',
        link: 'Ir para Telegrama',
      },
    },
  },
  rules: {
    toMain: 'Início',
    title: 'Regras de uso',
    subtitles: {
      1: 'Dados a serem processados',
      3: 'Exigências de proteção de dados',
    },
    textSub:
      '<span>Exemplo!</span> O software de terceiros especificado inclui os sistemas de coleta de estatísticas de visitantes Google Analytics e Yandex.Metrika.',
    paragraphs: {
      text1_1: 'Nós não coletamos seus dados pessoais usando o Site.',
      text1_2:
        'Todos os dados coletados no Site são fornecidos e aceitos de forma impessoal ("Dados Impessoais").',
      text1_3:
        'Os dados impessoais incluem as seguintes informações que não permitem a sua identificação:',
      text1_3_1:
        'Informações que você fornece sobre você usando os formulários on-line e módulos de software no Site, incluindo seu nome ou número de telefone e/ou endereço de e-mail.',
      text1_3_2:
        'Dados que são transmitidos de forma anônima automaticamente, dependendo das configurações do software que você está usando.',
      text1_4:
        'A Administração tem o direito de estabelecer requisitos para a composição dos Dados Impessoais do Usuário que são coletados usando o Site.',
      text1_6:
        'A administração não verifica a exatidão dos dados fornecidos e se o Usuário tem o consentimento necessário para seu processamento de acordo com esta Política, assumindo que o Usuário age de boa fé, com circunspecção e faz todos os esforços necessários para manter tais informações atualizadas e para obter todos os consentimentos necessários para seu uso.',
      text1_8:
        'A composição e as condições para a coleta de dados anonimizados utilizando software de terceiros serão determinadas diretamente por seus titulares de direitos e podem incluir:',
      text3_1:
        'A administração armazenará os dados e os protegerá do acesso e divulgação não autorizados, de acordo com suas regras e regulamentos internos.',
      text3_3:
        'Para melhorar a qualidade do trabalho, a Administração tem o direito de armazenar arquivos de log sobre as ações realizadas pelo Usuário como parte do uso do Site por 1 (um) ano.',
    },
    list: {
      1: 'dados do navegador (tipo, versão, cookies);',
      2: 'os dados do dispositivo e sua localização;',
      3: 'dados do sistema operacional (tipo, versão, resolução de tela);',
      4: 'solicitar dados (hora, fonte de transferência, endereço IP).',
    },
  },
  notFound: {
    goBack: 'Ir para',
    title: 'Não há nada aqui!',
    text: 'O mais provável é que você tenha chegado <br /> aqui por engano. Volte para a página inicial <br /> e selecione a seção desejada.',
  },
  cookiesHero: {
    text: 'This website uses Cookies to store data. By continuing to use the site, you agree to use these files in accordance with the cookie policy and Supster privacy policy.',
    more: 'Learn more',
    agree: 'Agree',
  },
  barText: "Don't miss out! <b>Join our waiting list</b> to experience platform first-hand.",
  soon: 'Soon',
  startForm: {
    success: 'Seus dados foram enviados com sucesso!',
    name: 'Seu nome',
    insta: 'Link do Instagram',
    telegram: 'Contato do Telegram',
    sending: 'Enviando...',
    send: 'Enviar',
    error: 'Erro',
  },
}
