/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'restart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11 5l1.94-1.94A7.007 7.007 0 008 1C4.14 1 1 4.14 1 8s3.14 7 7 7a7.004 7.004 0 006.765-5.195l.129-.483.965.257-.128.483A8.006 8.006 0 018 16c-4.411 0-8-3.589-8-8 0-4.41 3.589-8 8-8 2.134 0 4.158.868 5.647 2.354L16 0v5h-5z"/>'
  }
})
