let inlinePaddingRight = 0
let inlineOverflowY = 0
let isScrollbarHidden = false
export function hidePageScrollbar() {
  if (isScrollbarHidden) {
    return
  }
  isScrollbarHidden = true

  inlinePaddingRight = document.body.style.paddingRight
  inlineOverflowY = document.body.style.overflowY

  const paddingRight = parseFloat(getComputedStyle(document.body).paddingRight)
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

  document.body.style.paddingRight = `${paddingRight + scrollbarWidth}px`

  document.body.style.overflow = 'hidden'
}

export function showPageScrollbar() {
  if (!isScrollbarHidden) {
    return
  }

  isScrollbarHidden = false
  document.body.style.paddingRight = inlinePaddingRight === '' ? null : inlinePaddingRight
  document.body.style.overflow = inlineOverflowY === '' ? null : inlineOverflowY
}
