import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/plugins/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    lang: i18n.locale,
    isMobile: false,
    activeSlideIndex: 0,
    theme: 'dark',
    isShowAiClonePopUp: false
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme
    },
    setIsShowAiClonePopUp(state, value){
      state.isShowAiClonePopUp = value
    },
    setActiveSlideIndex(state, index) {
      state.activeSlideIndex = index
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile
    },
  },
  modules: {},
})
