/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo-mobile': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M13.703 14.944c.324.568.486 1.288.486 2.16 0 .928-.194 1.738-.582 2.43a5.07 5.07 0 01-1.567 1.716 7.2 7.2 0 01-2.226 1.014 9.771 9.771 0 01-2.563.336c-1.281 0-2.435-.226-3.464-.678a6.45 6.45 0 01-2.533-1.944C.594 19.134.176 18.128 0 16.96l3.41-.504c.24.992.736 1.756 1.488 2.292.752.536 1.608.804 2.569.804.536 0 1.056-.084 1.56-.252.504-.168.918-.416 1.243-.744.324-.328.486-.732.486-1.212a1.67 1.67 0 00-.078-.51 1.358 1.358 0 00-.258-.462c-.12-.144-.29-.28-.51-.408a4.075 4.075 0 00-.834-.348l-4.49-1.32a12.74 12.74 0 01-1.17-.408 5.149 5.149 0 01-1.291-.756c-.416-.328-.763-.762-1.039-1.302-.276-.54-.414-1.222-.414-2.046 0-1.16.292-2.124.876-2.892.585-.768 1.365-1.34 2.342-1.716.976-.376 2.056-.56 3.24-.552 1.193.016 2.258.22 3.193.612.937.392 1.721.962 2.353 1.71.633.748 1.089 1.662 1.37 2.742l-3.53.6c-.129-.56-.364-1.032-.708-1.416a3.41 3.41 0 00-1.23-.876 4.166 4.166 0 00-1.495-.324 4.004 4.004 0 00-1.447.21c-.452.156-.82.382-1.104.678a1.473 1.473 0 00-.427 1.056c0 .376.116.682.349.918.232.236.524.426.876.57.352.144.712.264 1.08.36l3.002.816c.447.12.944.278 1.488.474a6.72 6.72 0 011.566.816c.5.348.913.806 1.237 1.374zM27.238 12.238c-.508-1.028-1.222-1.838-2.142-2.43-.92-.592-2.001-.888-3.242-.888-1.15 0-2.12.272-2.929.794V9.28h-3.289V28h3.29v-6.224c.842.544 1.87.824 3.096.824 1.193 0 2.237-.3 3.133-.9.897-.6 1.594-1.416 2.095-2.448.5-1.032.75-2.196.75-3.492 0-1.32-.254-2.494-.762-3.522zm-3.019 5.508c-.217.596-.549 1.07-.996 1.423-.449.351-1.025.527-1.73.527-.727 0-1.31-.164-1.746-.492-.436-.328-.75-.788-.942-1.38-.192-.592-.288-1.28-.288-2.064s.096-1.471.288-2.064c.192-.591.498-1.051.919-1.38.42-.328.97-.492 1.65-.492.728 0 1.326.178 1.794.534.469.356.815.832 1.038 1.428.225.596.337 1.254.337 1.974 0 .728-.108 1.39-.324 1.986z"/><path pid="1" d="M18.925 0h-3.289v6h3.29V0z" _fill="#27C793"/>'
  }
})
