<template>
  <button
    :class="$style.burger"
    @click="isAside ? $emit('click', $event) : $emit('update:is-open', !isOpen)"
  >
    <div
      v-for="item in 2"
      :key="`burger-${item}`"
      :class="[
        $style.item,
        isWhite && $style.itemWhite,
        isSecondary && $style.itemSecondary,
        isOpen && $style.itemClose,
      ]"
    ></div>
  </button>
</template>
<script>
export default {
  name: 'TheBurger',
  props: {
    isWhite: Boolean,
    isAside: Boolean,
    isOpen: Boolean,
    isSecondary: Boolean,
  },
}
</script>
<style lang="scss" module>
.burger {
  display: grid;
  grid-auto-flow: row;
  align-self: center;
  width: 24px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  row-gap: 6px;

  @include media('mobile') {
    grid-area: burger;
    width: 30px;
    row-gap: 5px;
  }

  &:hover {
    //
    .item {
      width: 100%;
    }
  }
}

.item {
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--icon);
  transition: all $transition ease-in;

  &Secondary {
    background-color: $secondary;
  }

  &White {
    background-color: $primary;
  }

  &Close {
    justify-self: end;
  }

  &:last-child {
    width: 75%;
  }
}
</style>
