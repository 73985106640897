/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'card': {
    width: 28,
    height: 20,
    viewBox: '0 0 28 20',
    data: '<path pid="0" d="M25.68 0H2.32A2.32 2.32 0 000 2.32v15.36A2.32 2.32 0 002.32 20h23.36A2.32 2.32 0 0028 17.68V2.32A2.32 2.32 0 0025.68 0zM26 17.68a.32.32 0 01-.32.32H2.32a.32.32 0 01-.32-.32V2.32A.32.32 0 012.32 2h23.36a.32.32 0 01.32.32v15.36zM4 12h20v4H4v-4z"/>'
  }
})
