var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:[_vm.$style.section, _vm.isMobileWhiteHeader && _vm.$style.sectionWhite],attrs:{"id":"header"}},[_c('a',{class:_vm.$style.bar,attrs:{"href":_vm.barLink},domProps:{"innerHTML":_vm._s(_vm.$t('barText'))}}),_c('div',{class:_vm.$style.headerContentWrap},[_c('div',{class:_vm.$style.logo},[(!_vm.isMobileWhiteHeader)?_c('icon',{class:[_vm.$style.logoIcon, _vm.$route.hash === '#media' && _vm.$style.logoIconSecondary],attrs:{"name":"logo"}}):_c('icon',{class:_vm.$style.logoIconWhite,attrs:{"name":"logo-mobile"}})],1),_c('div',{class:_vm.$style.content},[_c('nav',{class:[_vm.$style.nav, !_vm.isMobileWhiteHeader & _vm.isMobile && _vm.$style.navHide]},[_c('ul',{class:_vm.$style.list},[_vm._l((_vm.links),function(ref,index){
var to = ref.to;
var value = ref.value;
return _c('li',{key:index},[_c('router-link',{attrs:{"exact-path":"","to":(to + "#" + _vm.routeToSection),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  _vm.$style.link,
                  _vm.isMobileWhiteHeader && _vm.$style.linkWhite,
                  isActive && _vm.$style.linkActive,
                  isExactActive && _vm.$style.linkActive,
                  _vm.isMobileWhiteHeader && isActive && _vm.$style.linkWhiteActive,
                  _vm.isMobileWhiteHeader && isExactActive && _vm.$style.linkWhiteActive ],attrs:{"href":href},domProps:{"innerHTML":_vm._s(_vm.isMobile ? _vm.$t(("" + value)) : _vm.$t(("for" + value)))},on:{"click":navigate}})]}}],null,true)})],1)}),_c('li',[_c('a',{class:[_vm.$style.gptLink, _vm.theme === 'light' && _vm.$style.light],attrs:{"target":"_blank","href":"https://gpt.supster.com"}},[_vm._v("SupsterGPT")])]),_c('li',[_c('a',{class:[
                _vm.$style.link,
                _vm.isMobileWhiteHeader && _vm.$style.linkWhite,
                _vm.isMobileWhiteHeader && _vm.isActive && _vm.$style.linkWhiteActive,
                _vm.isMobileWhiteHeader && _vm.isExactActive && _vm.$style.linkWhiteActive ],attrs:{"target":"_blank","href":_vm.about}},[_vm._v(_vm._s(_vm.$t('about')))])])],2)]),_c('div',{class:_vm.$style.grow}),_c('div',{class:[_vm.$style.wrapper, _vm.$route.hash !== '#hero' && _vm.$style.wrapperIcon]},[_c('div',{class:[
            _vm.$style.dropdownContainer,
            _vm.$route.hash === '#hero' && _vm.$style.dropdownContainerText,
            _vm.$route.fullPath === '/' && _vm.$style.dropdownContainerText,
            _vm.isMobileWhiteHeader & _vm.isMobile && _vm.$style.dropdownContainerHide ]},[_c('multiselect',{attrs:{"searchable":false,"options":_vm.options,"show-labels":false,"id":"multiselect","hide-selected":true},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(!_vm.isMobile && !_vm.isAiClone)?_c('a',{class:_vm.$style.signUp,attrs:{"href":("" + _vm.registerLink)}},[_c('icon',{class:_vm.$style.signUpIcon,attrs:{"name":"cross"}}),_c('span',{class:[
              _vm.$style.signUpText,
              _vm.$route.hash === '#hero' && _vm.$style.signUpTextShow,
              _vm.$route.fullPath === '/' && _vm.$style.signUpTextShow ]},[_vm._v(_vm._s(_vm.$t('registration')))])],1):_vm._e(),(!_vm.isMobile && _vm.isAiClone)?_c('button',{class:_vm.$style.signUp,on:{"click":function($event){return _vm.setIsShowAiClonePopUp(true)}}},[_c('icon',{class:_vm.$style.signUpIcon,attrs:{"name":"cross"}}),_c('span',{class:[
              _vm.$style.signUpText,
              _vm.$route.hash === '#hero' && _vm.$style.signUpTextShow,
              _vm.$route.fullPath === '/' && _vm.$style.signUpTextShow ]},[_vm._v(_vm._s(_vm.$t('registration')))])],1):_vm._e(),_c('a',{class:[_vm.$style.signIn, !_vm.isMobileWhiteHeader & _vm.isMobile && _vm.$style.signInMargin],attrs:{"href":("" + _vm.loginLink)}},[_c('icon',{class:[
              _vm.$style.signInIcon,
              _vm.isMobileWhiteHeader & _vm.isMobile && _vm.$style.signInIconPrimary ],attrs:{"name":"login"}}),_c('span',{class:[
              _vm.$style.signInText,
              _vm.$route.fullPath === '/' && _vm.$style.signInTextShow,
              _vm.$route.hash === '#hero' && _vm.$style.signInTextShow ]},[_vm._v(_vm._s(_vm.$t('personalArea')))])],1)])]),_c('the-burger',{attrs:{"is-open":_vm.isOpenAside,"is-white":_vm.isMobileWhiteHeader},on:{"update:is-open":function($event){return _vm.$emit('update:is-open-aside', $event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }