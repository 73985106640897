<template>
  <div :class="$style.section">
    <div :class="[$style.titleContainer, !title && $style.titleContainerHide]">
      <h3 v-if="title" :class="[$style.title, $style[`title-theme-${theme}`]]">{{ step }}</h3>
      <span :class="$style.span">/</span>
      <h3 v-if="title" v-html="title" :class="[$style.title, $style[`title-theme-${theme}`]]"></h3>
    </div>
    <ul :class="$style.list">
      <v-list-item
        :hideLine="hideLine"
        :theme="theme"
        :circle="circle"
        :number="index + 1"
        v-for="(item, index) of list"
        :key="`list-${index}`"
      >
        <p v-html="$t(item)"></p
      ></v-list-item>
    </ul>
  </div>
</template>
<script>
import VListItem from './VListItem.vue'

export default {
  name: 'VList',
  components: { VListItem },
  props: {
    circle: {
      type: Boolean,
      default: false,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
    },
    title: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
    },
  },
}
</script>
<style lang="scss" module>
.section {
  margin-bottom: 16px;
  max-width: 484px;

  @media (max-width: 1200px) {
    max-width: 380px;
  }

  @include media('mobile') {
    margin-bottom: 16px;
  }
}

.titleContainer {
  max-height: 24px;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: row;

  @media (max-width: 1600px) {
    margin: 8px 0 0 0;
  }

  @include media('mobile') {
    max-height: none;
  }

  &Hide {
    display: none;
  }
}

.span {
  padding-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: $secondary;
  margin: -2px 6px 0 6px;

  @include media('mobile') {
    margin: 13px 6px 0 6px;
    padding: 0;
  }

  @media (max-width: 580px) {
    font-size: 16px;
    padding: 0;
    margin: 10px 4px 0 4px;
  }

  @media (max-width: 1600px) and (min-width: 941px) {
    font-size: 16px;
    line-height: 24px;
    margin: 0px 5px 0 5px;
  }
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.05em;

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 26px;
  }

  @media (max-width: 1600px) and (min-width: 941px) {
    font-size: 16px;
    line-height: 24px;
  }

  @include media('mobile') {
    margin: 16px 0 0 0;
  }

  @media (max-width: 580px) {
    padding-bottom: 0;
    font-size: 16px;
    line-height: 16px;
  }

  &-theme-light {
    color: $primary;
  }

  &-theme-gray {
    color: $primary;
  }

  &-theme-dark {
    color: white;
  }
}

.list {
  margin-top: 36px;
  display: grid;
  grid-auto-flow: row;
  list-style: none;
  row-gap: 16px;

  @media (max-width: 1600px) {
    margin-top: 24px;
  }

  @include media('mobile') {
    row-gap: 16px;
    margin-top: 24px;
  }
}
</style>
