/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'yt': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M19.813 3.75c-.12-1.497-.993-3.386-2.786-3.455-5.016-.344-8.952-.44-14.055 0C1.389.473.308 2.445.186 3.95c-.248 3.146-.248 4.932 0 8.086.122 1.496 1.175 3.475 2.786 3.687 5.164.345 9.073.393 14.055 0 1.559-.281 2.666-2.19 2.786-3.687.25-3.154.25-5.139 0-8.285zM7 12V4l7 4-7 4z"/>'
  }
})
