<template>
  <transition name="fade" appear>
    <aside v-show="isVisible" id="aside" :class="$style.aside">
      <div :class="$style.container">
        <div v-if="!isMobile" :class="$style.blur" @click="onClose"></div>
        <transition name="scroll-horizontal" appear>
          <div v-if="isOpen" :class="$style.content">
            <the-burger
              :isSecondary="true"
              :class="$style.close"
              is-aside
              :is-open="isVisible"
              @click="onClose"
            />
            <div>
              <slot name="content" />
            </div>
            <div>
              <slot name="footer" />
            </div>
          </div>
        </transition>
      </div>
    </aside>
  </transition>
</template>
<script>
import { hidePageScrollbar, showPageScrollbar } from '@/utils/scrollbar'
import { mapState } from 'vuex'
import TheBurger from '@/components/TheBurger.vue'

export default {
  components: { TheBurger },
  name: 'VAside',
  props: {
    isVisible: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  watch: {
    isVisible: {
      handler(isVisible) {
        if (isVisible) {
          hidePageScrollbar()
          window.addEventListener('keydown', this.onKeyDown)
          setTimeout(() => {
            this.isOpen = true
          }, this.timeOutTime)
        } else {
          window.removeEventListener('keydown', this.onKeyDown)
          showPageScrollbar()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(['isMobile']),
    timeOutTime() {
      return this.isMobile ? 0 : 200
    },
  },
  methods: {
    onClose() {
      this.isOpen = false
      setTimeout(() => {
        this.$emit('update:is-visible', false)
      }, this.timeOutTime)
    },
    onKeyDown({ code }) {
      if (code === 'Escape') {
        this.onClose()
      }
    },
  },
}
</script>
<style lang="scss" module>
.aside {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 56px;
  right: 46px;
  width: 30px;
  height: 13px;
  z-index: 1;

  @include media('mobile') {
    top: 40px;
    right: 30px;
  }
}

.blur {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(39, 20, 96, 0.8);
  cursor: pointer;
  backdrop-filter: blur(8px);
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  display: grid;
  grid-template-rows: 1fr min-content;
  row-gap: 20px;
  width: 100%;
  max-width: 680px;
  height: 100%;
  padding: 80px 40px 40px 80px;
  background-color: $primary;

  @media (max-height: 730px) {
    padding: 40px 40px 20px 40px;
  }

  @include media('mobile') {
    max-width: 100%;
    padding: 100px 0 0 32px;
  }
}
</style>
