<template>
  <v-aside :is-visible="isVisible" @update:is-visible="$emit('update:is-visible', $event)">
    <template #content>
      <div :class="[$style.content, $style.contentDesktop]">
        <div :class="[$style.element, $style[`element-1`]]">
          <h3 :class="$style.title">{{ $t('forBusiness') }}:</h3>
          <div :class="$style.wrapper">
            <div
              v-for="({ to, value }, i) in forBusiness"
              :key="`header-aside-item-${i}`"
              :class="[$style.link]"
              @click="onRouteChange(to, i + 1)"
            >
              <component :is="'router-link'" :to="to">
                {{ $t(value) }}
              </component>
            </div>
          </div>
        </div>
        <div :class="[$style.element, $style[`element-2`]]">
          <h3 :class="$style.title">{{ $t('Supster') }}:</h3>
          <div :class="$style.wrapper">
            <div :class="[$style.link]">
              <a :href="about" target="_blank">
                {{ $t('about') }}
              </a>
            </div>
            <div :class="[$style.link]">
              <a :href="requisites" target="_blank">
                {{ $t('requisites') }}
              </a>
            </div>
            <div :class="[$style.link]">
              <a :href="rulesForUse" target="_blank">
                {{ $t('rulesForUse') }}
              </a>
            </div>
            <div :class="[$style.link]">
              <a :href="privacyPolicy" target="_blank">
                {{ $t('privacyPolicy') }}
              </a>
            </div>
            <div :class="[$style.link, code === 'RU' && $style.link_hidden]">
              <a :href="policyOfLegalization" target="_blank">
                {{ $t('policyOfLegalization') }}
              </a>
            </div>
          </div>
        </div>
        <div :class="[$style.element, $style[`element-3`]]">
          <h3 :class="$style.title">{{ $t('toUser') }}:</h3>
          <div :class="$style.wrapper">
            <div :class="[$style.link, $style.linkIcon]">
              <a :href="loginLink" target="_blank">
                <icon :class="$style.icon" name="login" />
                {{ $t('personalArea') }}
              </a>
            </div>
            <div :class="[$style.link, $style.linkIcon]">
              <button
                @click="setIsShowAiClonePopUp(true), $emit('update:is-visible', false)"
                v-if="isAiClone"
              >
                <icon :class="$style.icon" name="cross" />
                {{ $t('registration') }}
              </button>
              <a v-else :href="registerLink" target="_blank">
                <icon :class="$style.icon" name="cross" />
                {{ $t('registration') }}
              </a>
            </div>
            <div :class="[$style.link, $style.linkIcon]">
              <a :href="`mailto:${companyEmail}`" target="_blank">
                {{ companyEmail }}
              </a>
            </div>
          </div>
        </div>
        <div :class="[$style.element, $style[`element-4`]]">
          <h3 :class="$style.title">{{ $t('forBlog') }}:</h3>
          <div :class="$style.wrapper">
            <div
              v-for="({ to, value }, i) in forBlog"
              :key="`header-aside-item-${i}`"
              :class="[$style.link]"
              @click="onRouteChange(to, i + 1)"
            >
              <component :is="'router-link'" :to="to">
                {{ $t(value) }}
              </component>
            </div>
          </div>
        </div>
      </div>
      <div :class="[$style.content, $style.contentMobile]">
        <div :class="[$style.element, $style[`element-1`]]">
          <a target="_blank" :class="$style.gptLink" href="https://gpt.supster.com">SupsterGPT</a>
          <h3 :class="$style.title">{{ $t('forBusiness') }}:</h3>
          <div :class="$style.wrapper">
            <div
              v-for="({ to, value }, i) in forBusiness"
              :key="`header-aside-item-${i}`"
              :class="[$style.link]"
              @click="onRouteChange(to, i + 1)"
            >
              <component
                :is="'router-link'"
                :to="to"
                v-scroll-to="to && `#section-${String(to).slice(String(to).lastIndexOf('#') + 1)}`"
              >
                {{ $t(value) }}
              </component>
            </div>
          </div>
        </div>
        <div :class="[$style.element, $style[`element-2`]]">
          <router-link @click.native="goToAiclone" to="/ai" :class="$style.aiclone"
            >Ai Clone</router-link
          >
          <h3 :class="$style.title">{{ $t('forBlog') }}:</h3>
          <div :class="$style.wrapper">
            <div
              v-for="({ to, value }, i) in forBlog"
              :key="`header-aside-item-${i}`"
              :class="[$style.link]"
              @click="onRouteChange(to, i + 1)"
            >
              <component
                :is="'router-link'"
                :to="to"
                v-scroll-to="to && `#section-${String(to).slice(String(to).lastIndexOf('#') + 1)}`"
              >
                {{ $t(value) }}
              </component>
            </div>
          </div>
        </div>
        <div :class="[$style.element, $style[`element-3`]]">
          <h3 :class="$style.title">{{ $t('Supster') }}:</h3>
          <div :class="$style.wrapper">
            <div :class="[$style.link]">
              <a :href="about" target="_blank">
                {{ $t('about') }}
              </a>
            </div>
            <div :class="[$style.link]">
              <a :href="requisites" target="_blank">
                {{ $t('requisites') }}
              </a>
            </div>
            <div :class="[$style.link]">
              <a :href="rulesForUse" target="_blank">
                {{ $t('rulesForUse') }}
              </a>
            </div>
            <div :class="[$style.link]">
              <a :href="privacyPolicy" target="_blank">
                {{ $t('privacyPolicy') }}
              </a>
            </div>
            <div :class="[$style.link, code === 'RU' && $style.link_hidden]">
              <a :href="policyOfLegalization" target="_blank">
                {{ $t('policyOfLegalization') }}
              </a>
            </div>
          </div>
        </div>
        <div :class="[$style.element, $style[`element-4`]]">
          <h3 :class="$style.title">{{ $t('toUser') }}:</h3>
          <div :class="$style.wrapper">
            <div :class="[$style.link, $style.linkIcon]">
              <a :href="loginLink" target="_blank">
                <icon :class="$style.icon" name="login" />
                {{ $t('personalArea') }}
              </a>
            </div>
            <div :class="[$style.link, $style.linkIcon]">
              <button
                @click="setIsShowAiClonePopUp(true), $emit('update:is-visible', false)"
                v-if="isAiClone"
              >
                <icon :class="$style.icon" name="cross" />
                {{ $t('registration') }}
              </button>
              <a v-else :href="registerLink" target="_blank">
                <icon :class="$style.icon" name="cross" />
                {{ $t('registration') }}
              </a>
            </div>
            <div :class="[$style.link, $style.linkIcon]">
              <a :href="`mailto:${companyEmail}`" target="_blank">
                {{ companyEmail }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div :class="$style.footer">
        <img
          v-if="locale === 'ru'"
          :class="$style.image"
          src="~@/assets/images/Sk_resident2ru.jpg"
          alt="SK"
        />
        <div :class="$style.languages">
          <button
            @click="handleClick('ru')"
            :class="[$style.language, locale === 'ru' && $style.languageActive]"
          >
            Ru
          </button>
          <button
            @click="handleClick('en')"
            :class="[$style.language, locale === 'en' && $style.languageActive]"
          >
            En
          </button>

          <button
            @click="handleClick('pt')"
            :class="[$style.language, locale === 'pt' && $style.languageActive]"
          >
            Pt
          </button>
        </div>
        <div v-if="locale !== 'ru'" :class="$style.info">
          <p :class="$style.info__text">
            <span>{{ code === 'IN' ? companyNameIN : companyName }}</span>
          </p>
          <p :class="$style.info__text">
            {{ code === 'IN' ? 'CIN' : 'Company Number' }}
            <span
              ><br />
              {{ code === 'IN' ? companyNumberIN : companyNumber }}</span
            >
          </p>
          <p :class="$style.info__text">
            Address <br />
            <span>{{ code === 'IN' ? companyAddressIN : companyAddress }}</span>
          </p>
          <p :class="$style.info__text">
            {{ code === 'IN' ? 'Contact Email' : 'Contact Phone' }} <br />
            <span>{{ code === 'IN' ? companyEmailIN : companyPhone }}</span>
          </p>
        </div>
        <div :class="$style.inner">
          <the-social v-if="!isMobile" :shadow="true" :class="$style.social" :column="true" />
          <the-social
            v-if="isMobile"
            :shadow="true"
            :class="[$style.social, $style.socialAbsolute]"
          />
        </div>
      </div>
    </template>
  </v-aside>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

import VAside from '@/components/asides/VAside.vue'
import TheSocial from '@/components/TheSocial.vue'

import i18n from '@/plugins/i18n'
import axios from 'axios'

export default {
  components: { VAside, TheSocial },
  name: 'HeaderAside',
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      code: 'RU',
      forBusiness: [
        {
          value: 'howItWorks.page.title',
          to: '/business/#how-it-work',
        },
        {
          value: 'income.business.page.title',
          to: '/business/#income',
        },
        {
          value: 'media.business.page.title',
          to: '/business/#media',
        },
        {
          value: 'payOrCrm.business.page.title',
          to: '/business/#pay-or-crm',
        },
        {
          value: 'sales.page.button',
          to: '/business/#sales',
        },
      ],
      forBlog: [
        {
          value: 'howItWorks.page.title',
          to: `${process.env.VUE_APP_BLOG}#how-it-work`,
        },
        {
          value: 'income.blog.page.title',
          to: `${process.env.VUE_APP_BLOG}#income`,
        },
        {
          value: 'media.blog.page.title',
          to: `${process.env.VUE_APP_BLOG}#media`,
        },
        {
          value: 'payOrCrm.blog.page.title',
          to: `${process.env.VUE_APP_BLOG}#pay-or-crm`,
        },
        {
          value: 'sales.page.button',
          to: `${process.env.VUE_APP_BLOG}#sales`,
        },
      ],
    }
  },
  computed: {
    ...mapState(['isMobile']),
    isAiClone() {
      return this.$route.name === 'ai'
    },
    locale() {
      return i18n.locale
    },

    getLangFromCode() {
      switch (this.code) {
        case 'RU':
          return 'ru'
        case 'IN':
          return 'in'
        default:
          return 'en'
      }
    },
    ref() {
      return document.location.hash.split('/').at(-1)
    },
    searchParam() {
      const localRef = JSON.parse(localStorage.getItem('ref'))
      const { hash } = document.location

      if (hash.includes('/')) {
        localStorage.setItem('ref', JSON.stringify(this.ref))
        return this.ref
      }
      return localRef
    },
    loginLink() {
      return `${process.env.VUE_APP_LOGIN}?lang=${i18n.locale}${
        this.searchParam ? `&ref=${this.searchParam}` : ''
      }`
      // return process.env.VUE_APP_TOP_BAR_LINK
    },
    registerLink() {
      // return `${process.env.VUE_APP_REGISTER}?lang=${i18n.locale}${
      //   this.searchParam ? `&ref=${this.searchParam}` : ''
      // }`
      return process.env.VUE_APP_TOP_BAR_LINK
    },
    companyName() {
      return process.env.VUE_APP_COMPANY_NAME
    },
    companyNameIN() {
      return process.env.VUE_APP_COMPANY_NAME_IN
    },
    companyNumber() {
      return process.env.VUE_APP_COMPANY_NUMBER
    },
    companyNumberIN() {
      return process.env.VUE_APP_COMPANY_NUMBER_IN
    },
    companyAddress() {
      return process.env.VUE_APP_COMPANY_ADDRESS
    },
    companyAddressIN() {
      return process.env.VUE_APP_COMPANY_ADDRESS_IN
    },
    companyPhone() {
      return process.env.VUE_APP_COMPANY_PHONE
    },
    companyEmail() {
      return process.env.VUE_APP_EMAIL
    },
    companyEmailIN() {
      return process.env.VUE_APP_COMPANY_EMAIL_IN
    },
    imageLink() {
      return '~@/assets/images/Sk_resident2ru.jpg'
    },
    phoneNumber() {
      return i18n.locale === 'ru' ? process.env.VUE_APP_PHONE : process.env.VUE_APP_COMPANY_PHONE
    },
    countriesUrl() {
      return `${process.env.VUE_APP_URL}${process.env.VUE_APP_COUNTRIES}`
    },
    requisites() {
      return `${process.env.VUE_APP_REQUISITES}?locale=${this.code}`
    },
    about() {
      return `${process.env.VUE_APP_ABOUT}?locale=${this.code}`
    },
    rulesForUse() {
      return `${process.env.VUE_APP_STATIC}${process.env.VUE_APP_USER_AGREEMENT}-${this.getLangFromCode}.pdf`
    },
    privacyPolicy() {
      return `${process.env.VUE_APP_STATIC}${process.env.VUE_APP_PRIVACY}-${this.getLangFromCode}.pdf`
    },
    policyOfLegalization() {
      return `${process.env.VUE_APP_STATIC}${process.env.VUE_APP_COMBATING_LEGALIZATION}-${this.getLangFromCode}.pdf`
    },
  },
  methods: {
    ...mapMutations(['setIsShowAiClonePopUp']),
    onRouteChange(route, sectionNumber) {
      this.$emit('update:is-visible', false)
      if (route) {
        this.$bus.$emit('change-page', sectionNumber + 1)
      }
    },
    goToAiclone() {
      this.$emit('update:is-visible', false)
      this.$bus.$emit('change-page', 0)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleClick(lang) {
      localStorage.setItem('lang', JSON.stringify(lang))
      i18n.locale = lang
    },
    async getLocale() {
      try {
        const url = this.countriesUrl
        const response = await axios.get(url)
        this.code = response?.data?.data?.code
      } catch (error) {
        console.log(error?.message)
      }
    },
  },
  mounted() {
    this.getLocale()
  },
}
</script>
<style lang="scss" module>
.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4px;

  &Mobile {
    display: none;
    column-gap: 2px;

    @include media('mobile') {
      display: grid;
    }
  }

  &Desktop {
    @include media('mobile') {
      display: none;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;

  &__text {
    font-size: 14px;
    color: #fff;
    max-width: 280px;
    text-align: start;
    margin: 0 0 8px 0;
    line-height: 1.21;

    @include media('mobile') {
      text-align: end;
      font-size: 12px;
    }

    span {
      color: #fff;
      font-weight: 600;
    }
  }

  @include media('mobile') {
    padding: 0 24px 24px 0;
  }
}

.image {
  width: 84px;
  height: 60px;
  position: absolute;
  bottom: 96px;

  @include media('mobile') {
    bottom: 80px;
  }
}

.element {
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, min-content);
  justify-content: start;
}

.banners {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  column-gap: 10px;
}

.banner {
  position: relative;
  height: 60px;
  object-fit: cover;
}

.wrapper {
  display: grid;
  grid-auto-flow: row;
  justify-content: start;
  margin: 0 0 32px 0;

  &:last-child {
    margin: 0;
  }
}

.title {
  color: $secondary;
  font-weight: 700px;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 24px 0;

  @include media('mobile') {
    font-size: 12px;
    line-height: 18px;
  }
}

.link {
  display: block;
  align-self: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin: 0 0 16px 0;

  @media (max-height: 730px) {
    margin: 0 0 12px 0;
  }

  @include media('mobile') {
    font-size: 12px;
    line-height: 18px;
  }

  a {
    text-decoration: none;
    color: white;
  }
  button {
    background: transparent;
    display: flex;
    gap: 12px;
    cursor: pointer;
    color: white;
  }

  &:hover {
    opacity: 0.7;
  }

  &Icon {
    //
    a {
      display: grid;
      grid-template-columns: 16px auto;
      column-gap: 12px;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &_hidden {
    display: none;
  }
}
.gptLink {
  color: $secondary;
  text-decoration: none;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 700;

  &::after {
    content: 'NEW!';
    border: 1px solid $secondary;
    background: $secondary;
    font-size: 10px;
    border-radius: 4px;
    padding: 0 4px;
    position: relative;
    top: -10px;
    margin-left: 6px;
    font-weight: 800;
    color: $primary;
  }
}
.aiclone {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #3dffc0;
  text-decoration: none;
  &::after {
    content: 'NEW!';
    border: 1px solid $secondary;
    background: $secondary;
    font-size: 10px;
    border-radius: 4px;
    padding: 0 4px;
    position: relative;
    top: -10px;
    margin-left: 6px;
    font-weight: 800;
    color: $primary;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-height: 880px) and (min-width: 941px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    padding: 0 40px 20px 40px;
  }
}

.inner {
  display: flex;
  flex-direction: column;
}

.icon {
  align-self: center;
  width: 16px;
  height: 16px;
  color: white;
}

.languages {
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;

  @include media('mobile') {
    margin: 0 0 32px 0;
  }

  .language {
    font-size: 14px;
    line-height: 1.21;
    color: white;
    cursor: pointer;
    border: none;
    background: none;
    margin: 0 50px 0 0;

    @include media('mobile') {
      margin: 0 33px 0 0;
      font-size: 12px;

      &:last-child {
        margin: 0;
      }
    }

    &Active {
      color: $secondary;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.social {
  &Absolute {
    position: absolute;
    top: 24px;
    left: 24px;
  }

  svg {
    color: $secondary;
  }
}

.phone {
  display: grid;
  grid-template-columns: 10px auto;
  align-content: center;
  text-decoration: none;
  column-gap: 12px;

  @include media('mobile') {
    grid-row: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  &Value {
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    @include media('mobile') {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &Icon {
    align-self: center;
    width: 10px;
    height: 16px;
    color: white;
  }
}
</style>
