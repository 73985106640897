/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'viber': {
    width: 22,
    height: 24,
    viewBox: '0 0 22 24',
    data: '<path pid="0" d="M19.52 2.351c-3.086-2.937-13.508-3.372-17.2.131C.662 4.196.078 6.71.014 9.824c-.064 3.114-.14 8.948 5.301 10.53h.005l-.005 2.417s-.037.98.59 1.176c.715.232 1.04-.223 3.266-2.88 3.724.322 6.584-.417 6.91-.525.751-.252 5.006-.814 5.694-6.648.716-6.022-.344-9.823-2.256-11.543zm.63 11.1c-.586 4.87-4.034 5.179-4.668 5.39-.27.09-2.786.736-5.944.525 0 0-2.357 2.936-3.09 3.699-.24.248-.503.225-.499-.267 0-.323.018-4.012.018-4.012-4.613-1.321-4.34-6.289-4.29-8.888.05-2.6.525-4.728 1.93-6.162C6.844.702 15.981 1.38 18.31 3.566c2.846 2.52 1.833 9.643 1.84 9.885zm-9.308-9.145zm6.058 6.519h.001c.005.402-.585.407-.585.005-.027-3.816-2.487-5.896-5.474-5.92-.385-.004-.385-.604 0-.604 3.34.023 6.026 2.407 6.058 6.519zm-2.129-.628c.046-1.985-1.155-3.54-3.435-3.713-.384-.027-.344-.632.041-.604 2.63.197 4.028 2.064 3.98 4.331-.006.403-.595.384-.586-.014zm1.618 5.225v.007c-.489.89-1.405 1.873-2.347 1.56l-.009-.015c-.956-.276-3.208-1.476-4.63-2.645-3.029-2.474-3.979-5.748-4.496-6.775-.303-.975.644-1.92 1.51-2.426h.008c.416-.225.816-.15 1.082.183.253.353.75.814 1.49 2.15.277.51.105 1.03-.167 1.246l-.544.45c-.276.228-.239.655-.239.655s.806 3.15 3.82 3.947c0 0 .413.038.634-.248l.435-.562c.208-.281.711-.459 1.205-.173.666.389 1.514.993 2.076 1.54.317.267.39.675.172 1.106zm-4.6-7.312c-.389-.023-.353-.627.032-.604 1.264.07 1.966.819 2.03 2.164.018.402-.567.43-.585.028-.05-1.03-.516-1.532-1.477-1.588z"/>'
  }
})
