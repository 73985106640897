<template>
  <div :class="$style.section">
    <div :class="$style.content">
      <div :class="$style.container">
        <icon :class="[$style.arrow, $style.arrowUp, isLast && $style.arrowUpShow]" name="arrow" />
      </div>
      <icon :class="$style.mouse" name="mouse" />
      <div :class="[$style.container, $style.containerDown]">
        <icon
          :class="[$style.arrow, $style.arrowDown, isLast && $style.arrowDownHide]"
          name="arrow"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheMouse',
  props: {
    isLast: Boolean,
    isSliderSlide: Boolean,
  },
}
</script>
<style lang="scss" module>
.section {
  bottom: 68px;
  right: 4px;
  position: absolute;
  z-index: 1;
  align-self: center;
  justify-self: end;
  width: 54px;
  height: 34px;
  animation: mouse-vertical-scroll 1s infinite alternate;

  @media (max-width: 1600px) {
    bottom: 48px;
    right: 6px;
  }

  @keyframes mouse-vertical-scroll {
    //
    to {
      transform: translate(-50%, 5px);
    }

    from {
      transform: translate(-50%, -5px);
    }
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 68px;
}

.mouse {
  width: 20px;
  height: 32px;
}

.container {
  margin: 0 0 11px 0;
  width: 13px;
  height: 7px;

  &Down {
    margin: 11px 0 0 0;
  }

  .arrow {
    width: 13px;
    height: 7px;

    &Down {
      margin-top: -18px;

      &Hide {
        display: none;
      }
    }

    &Up {
      display: none;
      transform: rotate(180deg);

      &Show {
        display: block;
      }
    }
  }
}
</style>
