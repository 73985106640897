<template>
  <div :class="$style.hero">
    <i18n v-if="!isAiClone" :class="$style.text" path="createPageInFiveMinutes" tag="p">
      <template #br>
        <br />
      </template>
      <template #link>
        <!-- <a :class="$style.link" href="#" target="_blank">{{ $t('bySubscription') }}</a> -->
        <span :class="$style.link">{{ $t('bySubscription') }} </span>
      </template>
    </i18n>
    <i18n v-else :class="$style.text" path="createPageInFiveMinutesForAiClone" tag="p">
      <template></template>
      <template #br>
        <br />
      </template>
      <template #text>
        <span :class="$style.link">{{ $t('aiClone.footer.1') }} </span>
      </template>
      <template #text2>
        <span :class="$style.link">{{ $t('aiClone.footer.2') }} </span>
      </template>
    </i18n>
    <v-button
      v-if="isAiClone"
      @click="setIsShowAiClonePopUp(true)"
      :class="$style.button"
      icon="on"
    >
      {{ $t('go') }}
    </v-button>
    <v-button v-else :class="$style.button" icon="on" :href="link">
      {{ $t('go') }}
    </v-button>
  </div>
</template>
<script>
import VButton from '@/components/VButton.vue'
// import i18n from '@/plugins/i18n'
import SlideWithSlider from '@/mixins/SlideWithSlider'
import { mapMutations } from 'vuex'

export default {
  components: { VButton },
  name: 'HeroFooter',
  mixins: [SlideWithSlider],
  computed: {
    isAiClone() {
      return this.$route.name === 'ai'
    },
    link() {
      // return `${process.env.VUE_APP_REGISTER}?lang=${i18n.locale}${
      //   this.searchParam ? `&ref=${this.searchParam}` : ''
      // }`
      return process.env.VUE_APP_TOP_BAR_LINK
    },
  },
  methods: {
    ...mapMutations(['setIsShowAiClonePopUp']),
  },
}
</script>
<style lang="scss" module>
.hero {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 64px;
  column-gap: 10px;

  @media (max-width: 1600px) {
    padding: 0 0 0 26px;
  }

  @include media('mobile') {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

.text {
  color: $secondary;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.05em;

  @media (max-width: 1270px) {
    font-size: 1.56vw;
  }

  @include media('mobile') {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 24px 0;
  }

  @media (max-width: 580px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.link {
  font-weight: 500;
  text-decoration: none;
  color: white;

  &:hover {
    opacity: 0.7;
  }
}

.button {
  justify-self: center;
  max-width: 136px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  left: calc(50% - 68px);
  border-radius: 16px;

  @media (max-width: 1600px) and (min-width: 941px) {
    bottom: 22px;
  }

  @media (max-width: 1270px) and (min-width: 941px) {
    right: 128px;
    left: auto;
  }

  @include media('mobile') {
    position: relative;
    bottom: auto;
    left: auto;
    margin: 0;
    height: 40px;
    width: 128px;
    padding: 8px 20px;
  }
}
</style>
