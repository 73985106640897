/* eslint-disable */
require('./airplane')
require('./apple-pay')
require('./bottom')
require('./capitalist')
require('./card')
require('./cross')
require('./google-pay')
require('./left')
require('./login')
require('./logo-mobile')
require('./logo')
require('./mouse')
require('./on')
require('./pause')
require('./pay')
require('./phone')
require('./play')
require('./qiwi')
require('./restart')
require('./scroll')
require('./suitcase')
require('./text')
require('./top')
require('./viber')
require('./webmoney')
require('./whatsapp')
require('./yandex')
require('./yt')
require('./zoom-in')
require('./blog')
require('./blogBack')
require('./tgBack')
require('./ytBack')
require('./background')
require('./eye')
require('./arrow')
