<template>
  <section :class="[$style.section, $style[`section-${color}`]]">
    <div :class="$style.inner">
      <div>
        <strong
          :class="[$style.symbol, $style[`symbol-theme-${theme}`]]"
          class="animation-fade-symbol"
          >////</strong
        >
      </div>
      <div :class="$style.content">
        <v-player
          v-show="isAiClone"
          :class="$style.player"
          :disabled-name="disabledName"
          @click="onPlayer"
        />
        <div :class="$style.container">
          <i18n
            v-if="!isAiClone"
            :class="[
              $style.title,
              $style[`title-${color}`],
              { [$style.title_pt]: locale === 'pt' },
            ]"
            class="animation-content"
            path="forBusinessAndInfluencers"
            tag="h1"
          >
            <template #text>
              <span> {{ $t('fitsInWorld') }}</span>
            </template>
            <template #text2>
              <span> {{ $t('worldPlatform') }}</span>
            </template>
            <template #br>
              <br />
            </template>
          </i18n>
          <i18n
            v-else
            :class="[
              $style.title,
              $style.aiCloneTitle,
              $style[`title-${color}`],
              { [$style.title_pt]: locale === 'pt' },
            ]"
            class="animation-content"
            path="forAiCloneTitle"
            tag="h1"
          >
            <template #text>
              <span>{{ $t('aiClone.title.1') }}</span>
              <br />
            </template>
            <template #text2>
              <span>{{ $t('aiClone.title.2') }}</span>
              <br />
            </template>
            <template #text3>
              {{ $t('aiClone.title.3') }}
            </template>
          </i18n>
          <hero-footer v-if="isMobile" class="animation-content animation-content-after" />
        </div>
      </div>
      <div></div>
      <div :class="$style.contentMobile">
        <v-player
          v-show="isAiClone"
          :class="$style.playerMobile"
          :disabled-name="disabledName"
          @click="onPlayer"
        />
        <h2 :class="$style.titleMobile">
          <i18n
            v-if="!isAiClone"
            :class="[
              $style.title,
              $style[`title-${color}`],
              { [$style.title_pt]: locale === 'pt' },
            ]"
            class="animation-content"
            path="forBusinessAndInfluencers"
            tag="h1"
          >
            <template #text>
              <span> {{ $t('fitsInWorld') }}</span>
            </template>
            <template #text2>
              <span> {{ $t('worldPlatform') }}</span>
            </template>
            <template #br>
              <br />
            </template>
          </i18n>
          <i18n
            v-else
            :class="[
              $style.title,
              $style.aiCloneTitle,
              $style[`title-${color}`],
              { [$style.title_pt]: locale === 'pt' },
            ]"
            class="animation-content"
            path="forAiCloneTitle"
            tag="h1"
          >
            <template #text>
              <span>{{ $t('aiClone.title.1') }}</span>
              <br />
            </template>
            <template #text2>
              <span>{{ $t('aiClone.title.2') }}</span>
              <br />
            </template>
            <template #text3>
              {{ $t('aiClone.title.3') }}
            </template>
          </i18n>
        </h2>
        <i18n
          :class="[$style.subtitleMobile, $style[`title-${color}`]]"
          class="animation-content"
          path="forBusinessAndInfluencers"
          tag="h1"
        ></i18n>
        <hero-footer v-if="isMobile" class="animation-content animation-content-after" />
      </div>
    </div>
    <!-- Временно -->
    <video
      v-show="isAiClone"
      ref="video"
      :class="$style.video"
      class="animation-fade"
      loop
      muted
      data-autoplay
      playsinline
      id="#myVideo"
      @playing="onVideo(true)"
      @pause="onVideo(false)"
    >
      <source src="../../assets/images/ai-background.mp4" type="video/mp4" />
    </video>
    <img
      v-if="disabledName === 'pause'"
      :class="$style.video"
      src="../../assets/images/ai-background.jpg"
      alt=""
    />
    <div v-if="isAiClone" :class="$style.filter"></div>
    <div v-else :class="[$style.cover]" class="animation-fade"></div>
    <div v-if="isMobile" :class="$style.arrow">
      <icon name="arrow" />
      <icon name="arrow" />
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import VPlayer from '@/components/VPlayer.vue'
import HeroFooter from '@/components/footers/HeroFooter.vue'
import i18n from '@/plugins/i18n'
import SlideWithSlider from '@/mixins/SlideWithSlider'

export default {
  name: 'Hero',
  mixins: [SlideWithSlider],
  components: { VPlayer, HeroFooter },
  props: {
    color: {
      type: String,
    },
    theme: {
      type: String,
    },
  },
  data() {
    return {
      disabledName: 'play',
    }
  },
  computed: {
    ...mapState(['isMobile']),

    locale() {
      return i18n.locale
    },
  },
  methods: {
    onVideo(data) {
      const name = data ? 'play' : 'pause'
      this.$refs.video[name]()
      this.disabledName = name
    },
    onPlayer(name) {
      if (['play', 'pause'].includes(name)) {
        this.disabledName = name
        this.$refs.video[name]()
        return
      }
      this.disabledName = 'play'
      this.$refs.video.currentTime = 0
      this.$refs.video.play()
    },
  },
  mounted() {
    this.disabledName = 'pause'
    this.$refs.video.play()
  },
}
</script>
<style lang="scss" module>
.section {
  position: relative;
  width: 100%;
  height: 100%;

  @include media('mobile') {
    height: 100vh;
    overflow: hidden;
  }
}

.arrow {
  position: absolute;
  bottom: 44px;
  right: 42px;
  width: 12px;
  height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 10000;
}
.playerMobile {
  z-index: 100000;
}

.symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  color: white;
  font-weight: 800;
  font-size: 160vh;
  line-height: 1000px;
  white-space: nowrap;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  opacity: 0.04 !important;
  transition: color 0s, opacity 0s;
  letter-spacing: -0.06em;
  z-index: 2;

  @include media('mobile') {
    left: 33%;
    font-size: 1500px;
    letter-spacing: -0.08em;
  }
}

.inner {
  height: 100%;
  margin: 0 auto 0;
}

.container {
  margin: 0 auto;
  width: calc(100% - 400px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1600px) {
    width: calc(100% - 320px);
  }

  @include media('mobile') {
    position: absolute;
    bottom: 0;
  }
}

.content {
  position: relative;
  z-index: 2;
  grid-template-rows: 1fr auto;
  height: 100vh;
  row-gap: 20px;
  width: 100%;

  &Mobile {
    display: none;

    @include media('mobile') {
      position: absolute;
      padding: 32px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      z-index: 2;
    }
  }

  @include media('mobile') {
    display: none;
  }
}

.title {
  font-weight: 200;
  font-size: 80px;
  line-height: 1;
  letter-spacing: -0.05em;
  text-align: left;
  max-width: 534px;

  &_pt {
    max-width: 867px;
  }

  @media (max-width: 1600px) {
    margin: 28px 0 0 0;
  }

  @media (max-width: 1270px) {
    font-size: 6.25vw;
  }

  @include media('mobile') {
    align-self: end;
    font-size: 40px;
    line-height: 40px;
  }

  &-light {
    color: $primary;
  }

  &-gray {
    color: $primary;
  }

  &-dark {
    color: white;
  }

  span {
    color: $secondary;
    font-weight: 800;
  }

  &Mobile {
    font-size: 40px;
    line-height: 1;
    font-weight: 800;
    letter-spacing: -0.05em;
    color: $secondary;
  }
  &.aiCloneTitle {
    max-width: 700px;
  }
}

.subtitle {
  &Mobile {
    font-size: 40px;
    line-height: 1;
    font-weight: 200;
    color: white;
    max-width: 240px;
    margin: 0 0 24px 0;
  }
}

.player {
  z-index: 2;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.filter {
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background: #271460;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.cover {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: $primary;
  transform: translateX(-50%) translateY(-50%);

  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    content: '';
  }
  &.isAiClone {
    video {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      max-width: 100%;
      height: 100%;
      z-index: 10000;
      position: fixed;
    }
  }
}
</style>
