/* eslint-disable */
var ytBack = require('vue-svgicon')
ytBack.register({
  ytBack: {
    width: 300,
    height: 240,
    viewBox: '0 0 300 240',
    data: '<path d="M297.196 16.2622C295.392 -6.2122 282.293 -34.5423 255.401 -35.5722C180.164 -40.7422 121.132 -42.1668 44.5807 -35.5722C20.8337 -32.9071 4.61609 -3.33652 2.78503 19.2431C-0.928344 66.4358 -0.928344 93.2361 2.78503 140.534C4.61609 162.983 20.4217 192.66 44.5807 195.851C122.034 201.021 180.68 201.733 255.401 195.851C278.787 191.631 295.392 162.983 297.196 140.534C300.935 93.2361 300.935 63.4549 297.196 16.2622ZM105 140V20.0003L210 79.9994L105 140Z" />',
  },
})
