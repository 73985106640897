<template>
  <div :class="$style.section">
    <button
      :class="$style.button"
      v-for="(name, index) in icons"
      :key="`player-${index}`"
      :disabled="name === disabledName"
      @click="$emit('click', name)"
    >
      <icon :style="{ color: color }" :class="$style.icon" :name="name" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'VPlayer',
  props: {
    disabledName: {
      type: String,
      default: 'pause',
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      icons: ['play', 'pause', 'restart'],
    }
  },
}
</script>
<style lang="scss" module>
.section {
  position: absolute;
  top: 50%;
  right: 42px;
  display: grid;
  grid-auto-flow: row;
  transform: translateY(-50%);
  row-gap: 24px;
  z-index: 200;

  @include media('mobile') {
    width: 112px;
    position: absolute;
    top: -8px;
    left: 32px;
    grid-auto-flow: column;
    align-self: end;
    justify-self: start;
    row-gap: 0;
    column-gap: 20px;
    transform: translateY(0);
  }
}

.button {
  padding: 8px;
  background-color: transparent;

  @include media('desktop') {
    padding: 0.615vw;
  }
  /* stylelint-disable */
  @include button;
  /* stylelint-enable */

  @include media('mobile') {
    padding: 0;
  }
}

.icon {
  width: 16px;
  height: 16px;
  color: white;

  @include media('desktop') {
    width: 1.231vw;
    height: 1.231vw;
  }

  @include media('mobile') {
    width: 16px;
    height: 16px;
  }
}
</style>
