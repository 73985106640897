<template>
  <v-full-page :anchors="anchors" @leave="onSetNewIndex">
    <the-header :is-open-aside.sync="isOpenAside" />
    <header-aside :is-visible.sync="isOpenAside" />
    <component
      v-for="(name, index) in anchors"
      :key="`template-${name}`"
      :is="name"
      :id="`section-${name}`"
      :color="pagesForTemplate[$route.name].colors[index]"
      :index="index"
      class="section"
      :name="$route.name"
    />
    <transition name="fade-slide"> </transition>
    <the-footer :is-slider-slide="isSliderSlide" />
  </v-full-page>
</template>
<script>
import pages from '@/data/pages'
import { mapMutations, mapState } from 'vuex'

import VFullPage from '@/components/VFullPage.vue'
import HeaderAside from '@/components/asides/list/HeaderAside.vue'
import TheHeader from '@/components/TheHeader.vue'
import Hero from '@/components/sections/Hero.vue'
import HowItWork from '@/components/sections/HowItWork.vue'
import Income from '@/components/sections/Income.vue'
import Media from '@/components/sections/Media.vue'
import PayOrCRM from '@/components/sections/PayOrCRM.vue'
import Sales from '@/components/sections/Sales.vue'
import TheFooter from '@/components/TheFooter.vue'

const templates = {
  hero: Hero,
  'how-it-work': HowItWork,
  income: Income,
  media: Media,
  'pay-or-crm': PayOrCRM,
  sales: Sales,
}

export default {
  components: {
    VFullPage,
    TheHeader,
    HeaderAside,
    TheFooter,
    ...templates,
  },
  name: 'Index',
  data() {
    return {
      isOpenAside: false,
      anchors: pages[this.$route.name].anchors,
    }
  },
  watch: {
    isOpenAside() {
      this.$bus.$emit('change-scrolling', !this.isOpenAside, true)
    },
    isMobile(isMobile) {
      if (isMobile) {
        this.setTheme('dark')
      }
    },
  },
  beforeRouteLeave({ name }, _, next) {
    if (pages[name].anchors) {
      this.anchors = pages[name].anchors
    }
    next()
  },
  computed: {
    ...mapState(['isMobile', 'activeSlideIndex']),

    pagesForTemplate() {
      return pages
    },
    isSliderSlide() {
      if (pages[this.$route.name]?.slider) {
        return pages[this.$route.name].slider.includes(this.anchors[this.activeSlideIndex])
      }
      return false
    },
  },
  methods: {
    ...mapMutations(['setActiveSlideIndex', 'setTheme']),
    onSetNewIndex(index) {
      this.setTheme(pages[this.$route.name].colors[index])
      this.setActiveSlideIndex(index)
    },
  },
}
</script>
<style lang="scss" module>
.aside {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 64px;
  height: 340px;
  transform: translateY(-50%);

  @include media('desktop') {
    width: 4.923vw;
    height: 26.154vw;
  }

  @include media('mobile-height') {
    width: 10.6vh;
    height: 56.6vh;
  }
}
</style>
