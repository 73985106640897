<template>
  <main>
    <full-page :options="fullpage" ref="fullpage" id="fullpage" class="fullpage">
      <slot />
    </full-page>
    <v-navigation v-if="!isMobile" :is-visible="isVisible" :data="anchors" />
  </main>
</template>
<script>
import { mapState } from 'vuex'
import VNavigation from '@/components/VNavigation.vue'

export default {
  name: 'VFullPage',
  components: { VNavigation },
  props: {
    anchors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fullpage: {
        licenseKey: process.env.VUE_APP_FULLPAGEJS,
        anchors: this.anchors,
        menu: '#navigation, #aside, #header, #multiselect',
        // responsiveWidth: 600,
        scrollHorizontally: true,
        scrollHorizontallyKey: process.env.VUE_APP_HORIZONTALLY_KEY,
        scrollOverflow: true,
        scrollBar: false,
        navigation: false,
        controlArrows: false,
        slidesNavigation: true,
        resize: true,
        css3: true,
        easing: 'easeInOutCubic',
        easingcss3: 'ease',
        verticalCentered: true,
        responsiveSlides: true,
        resetSliders: true,
        onLeave: this.onLeave,
        scrollingSpeed: 1100,
      },
    }
  },
  watch: {
    isMobile(isMobile) {
      if (isMobile) {
        this.$refs.fullpage.api.destroy('all')
        return
      }
      this.$nextTick(() => {
        this.$refs.fullpage.init()

        this.$refs.fullpage.api.reBuild()
      })
    },
  },
  mounted() {
    this.$bus.$on('change-scrolling', (isDisabled, isVertical = false) => {
      this.onSetScrolling(isDisabled, isVertical)
    })

    this.$bus.$on('change-page', (sectionNumber) => {
      this.$nextTick(() => {
        this.$refs.fullpage.api.moveTo(sectionNumber)
      })
    })
    if (this.isMobile) {
      this.$refs.fullpage.api.destroy('all')
    }
  },
  computed: {
    ...mapState(['isMobile', 'activeSlideIndex']),
    isVisible() {
      return this.activeSlideIndex !== 0
    },
  },
  methods: {
    onSetScrolling(isDisabled, isVertical) {
      this.$refs.fullpage.api.setAllowScrolling(isDisabled, isVertical ? 'up, down' : 'left, right')
      this.$refs.fullpage.api.setKeyboardScrolling(
        isDisabled,
        isVertical ? 'up, down' : 'left, right'
      )
    },
    onLeave(_, { index }) {
      this.onSetScrolling(true)
      this.$emit('leave', index)
    },
  },
}
</script>
