<template>
  <section :class="[$style.section, $style[`section-${color}`]]">
    <div :class="$style.inner">
      <div>
        <strong
          :class="[$style.symbol, $style[`symbol-theme-${theme}`]]"
          class="animation-fade-symbol"
          >////</strong
        >
      </div>
      <div :class="$style.content">
        <div :class="$style.page">
          <p :class="$style.pageCount">.05</p>
        </div>
        <div :class="$style.wrapper" class="animation-content">
          <h2
            :class="$style.title"
            v-html="isAiClone ? $t('sales.page.titleAiClone') : $t('sales.page.title')"
          ></h2>
          <v-button
            v-if="isAiClone"
            @click="setIsShowAiClonePopUp(true)"
            :class="$style.button"
            icon="on"
          >
            {{ $t('sales.page.button') }}!
          </v-button>
          <v-button v-else :class="$style.button" icon="on" :href="link">
            {{ $t('sales.page.button') }}!
          </v-button>
        </div>
        <div :class="$style.row" class="animation-content animation-content-after">
          <v-article
            v-for="(
              { title, description, link, value, content, page, disabled, disabledText }, index
            ) in articles"
            :key="`article-${index}`"
            :title="title"
            :description="description"
            :link="link"
            :disabled="disabled"
            :disabledText="disabledText"
            :value="value"
            :page="page"
            :content="content"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import i18n from '@/plugins/i18n'
import VButton from '@/components/VButton.vue'
import VArticle from '@/components/VArticle.vue'

export default {
  components: { VButton, VArticle },
  name: 'Sales',
  props: {
    color: {
      type: String,
    },
    theme: {
      type: String,
    },
  },
  data() {
    return {
      articles: [
        // {
        //   title: 'Читай блог!',
        //   description: 'Мы регулярно публикуем полезную информацию в нашем блоге.',
        //   link: '#',
        //   value: 'Перейти в блог',
        //   content: [
        //     {
        //       second: 'text',
        //       main: null,
        //       link: '#',
        //       image: '',
        //       text: 'Кейсы по товарке за октябрь.',
        //     },
        //     {
        //       second: 'text',
        //       main: null,
        //       link: '#',
        //       image: '',
        //       text: 'Кейсы по товарке за ноябрь.',
        //     },
        //     {
        //       second: 'text',
        //       main: null,
        //       link: '#',
        //       image: '',
        //       text: 'Кейсы по товарке за декабрь.',
        //     },
        //   ],
        // },
        {
          title: this.$t('sales.page.blog.title'),
          description: this.$t('sales.page.blog.description'),
          // link: process.env.VUE_APP_TELEGRAM,
          value: this.$t('sales.page.blog.link'),
          page: 'blog',
          content: [
            {
              main: 'blog',
              link: process.env.VUE_APP_BLOG,
              back: 'blogBack',
            },
          ],
        },
        {
          title: this.$t('sales.page.youTube.title'),
          description: this.$t('sales.page.youTube.description'),
          link: process.env.VUE_APP_YOUTUBE,
          value: this.$t('sales.page.youTube.link'),
          page: 'youTube',
          disabled: true,

          content: [
            {
              main: 'yt',
              link: process.env.VUE_APP_YOUTUBE,
              image: '',
              back: 'ytBack',
            },
          ],
        },
        {
          title: this.$t('sales.page.telegram.title'),
          description: this.$t('sales.page.telegram.description'),
          // link: process.env.VUE_APP_TELEGRAM,
          value: this.$t('sales.page.telegram.link'),
          page: 'telegram',
          content: [
            {
              main: 'airplane',
              // link: process.env.VUE_APP_TELEGRAM,
              back: 'tgBack',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState(['isMobile']),
    isAiClone() {
      return this.$route.name === 'ai'
    },

    link() {
      return `${process.env.VUE_APP_REGISTER}?lang=${i18n.locale}${
        this.searchParam ? `&ref=${this.searchParam}` : ''
      }`
    },
  },
  watch: {
    isAiClone() {
      this.setDataForAiClone()
    },
  },
  methods: {
    setDataForAiClone() {
      this.articles[0].content[0].link = this.isAiClone
        ? 'https://teletype.in/@supstercreators/ubt-traff'
        : process.env.VUE_APP_BLOG
      this.articles[0].page = this.isAiClone ? 'aiClone' : 'blog'
      this.articles = this.articles.map((item) => {
        return item
      })
    },
    ...mapMutations(['setIsShowAiClonePopUp']),
  },
  mounted() {
    if (this.isAiClone) {
      this.setDataForAiClone()
    }
  },
}
</script>
<style lang="scss" module>
.symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  color: var(--band);
  font-weight: 800;
  font-size: 1600px;
  line-height: 1000px;
  white-space: nowrap;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  opacity: 0.04;
  transition: color 0s, opacity 0s;
  letter-spacing: -0.06em;

  @media (min-height: 1070px) {
    font-size: 180vh;
  }

  @include media('mobile') {
    top: 25%;
    left: 25%;
    margin: -64px 0 0 0;
    font-size: 240vw;
    line-height: 1;
    color: $primary;
    opacity: 0.04;
  }

  &-small {
    font-size: 800px;
    line-height: 800px;

    @include media('desktop') {
      font-size: 61.538vw;
      line-height: 61.538vw;
    }

    @include media('mobile') {
      font-size: 400px;
      line-height: 400px;
    }
  }

  &-theme-light {
    color: $band-light;
  }

  &-theme-gray {
    color: white;
  }

  &-theme-dark {
    color: rgba(255, 255, 255, 0.1);
  }
}

.page {
  display: none;

  @include media('mobile') {
    position: absolute;
    transform: rotate(270deg);
    right: -12px;
    display: flex;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -0.05em;
    color: $secondary;
    font-weight: 800;
    margin: 4px -5px 0 0;
  }

  @media (max-width: 580px) {
    font-size: 32px;
    margin: 12px 0 0 0;
  }
}

.section {
  position: relative;
  width: 100%;
  overflow: hidden;

  @include mobile-bg();
}

.inner {
  margin: 0 auto;
  width: calc(100% - 400px);

  @media (max-width: 1600px) {
    width: calc(100% - 320px);
  }

  @include media('mobile') {
    width: 100%;
  }
}

.content {
  margin: 184px 0 0 0;

  @media (max-width: 1600px) {
    margin: 145px 0 0 0;
  }

  @include media('mobile') {
    margin: 0;
    padding: 32px;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @include media('mobile') {
    flex-direction: column;
    margin: 0 auto 24px auto;
    max-width: 600px;
  }
}

.title {
  font-weight: 800;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -0.05em;
  text-align: left;
  min-width: 1112px;
  padding: 0 0 0 16px;

  @media (max-width: 1600px) {
    font-size: 32px;
    line-height: 36px;
    min-width: auto;
  }

  @media (max-width: 1200px) and (min-width: 941px) {
    font-size: 2.67vw;
  }

  @include media('mobile') {
    width: 100%;
    font-size: 32px;
    line-height: 36px;
    color: $primary;
    padding: 0;
    margin: 0 0 24px 0;
  }
}

.button {
  max-width: 136px;
  border-radius: 16px;
  margin: 0 16px 0 0;
  z-index: 1;

  @include media('mobile') {
    padding: 0;
    height: 40px;
    max-width: 128px;
    font-weight: 800;
    margin: 0;
    border-radius: 14px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  margin: 64px 0 0 0;

  @media (min-width: 1920px) and (min-height: 1080px) {
    margin: 128px 0 0 0;
  }

  @media (max-width: 1600px) {
    margin: 32px 0 0 0;
  }

  @include media('mobile') {
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 670px) {
    margin: 0;
  }
}

.band {
  position: absolute;
  top: 0;
  right: -20%;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 200px,
    var(--text) 0,
    var(--text) 201px
  );
  transform: rotate(20deg);
  opacity: 0.08;
  text-decoration: none;
}
</style>
