<template>
  <transition name="fade-slide">
    <nav id="navigation" :class="$style.navigation" v-show="isVisible">
      <ul :class="$style.list">
        <li
          v-for="item in dataWithoutFirstItem"
          :key="`nav-${item}`"
          :data-menuanchor="item"
          :class="$style.item"
        >
          <a :class="$style.link" :href="`#${item}`"> <div :class="$style.line"></div></a>
        </li>
      </ul>
    </nav>
  </transition>
</template>
<script>
export default {
  name: 'VNavigation',
  props: {
    isVisible: {
      require: true,
      type: Boolean,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dataWithoutFirstItem() {
      return this.data.filter((_, index) => index !== 0)
    },
  },
}
</script>
<style lang="scss" module>
.navigation {
  position: fixed;
  top: 50%;
  right: 50px;
  z-index: 2;
  transform: translateY(-50%);

  @media (max-width: 1020px) {
    right: 16px;
  }
}

.list {
  display: grid;
  grid-auto-flow: row;
  list-style-type: none;
  row-gap: 8px;
}

.item {
  display: block;
  opacity: 0.3;

  &:hover {
    opacity: 0.7;
  }
}

.link {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-decoration: none;
  column-gap: 8px;
}

.line {
  width: 1px;
  height: 40px;
  background-color: var(--text);
}
</style>
