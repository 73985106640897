/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'left': {
    width: 24,
    height: 4,
    viewBox: '0 0 24 4',
    data: '<path pid="0" d="M12 0v3h12v1H0l12-4z"/>'
  }
})
