/* eslint-disable */
var tgBack = require('vue-svgicon')
tgBack.register({
  tgBack: {
    width: 300,
    height: 240,
    viewBox: '0 0 300 240',
    data: '<path d="M279.745 -38.4126L14.0053 59.3521C-4.13235 66.2918 -4.02452 75.9415 10.6992 80.2435L76.9753 99.975L102.334 174.134C105.417 182.251 103.897 185.47 112.832 185.47C119.729 185.47 122.789 182.471 126.629 178.893C129.072 176.612 143.574 163.164 159.766 148.148L228.706 196.732C241.391 203.408 250.551 199.95 253.711 185.495L298.964 -17.9121C303.598 -35.6313 291.882 -43.6678 279.745 -38.4126ZM245.446 8.30313L117.531 118.386L112.551 169.055L87.3767 95.4422L236.764 5.54373C244.223 1.22975 251.06 3.5497 245.446 8.30313Z" />',
  },
})
