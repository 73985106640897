/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'google-pay': {
    width: 66,
    height: 26,
    viewBox: '0 0 66 26',
    data: '<path pid="0" d="M31.213 12.702v7.621h-2.437V1.503h6.464a5.87 5.87 0 014.186 1.629 5.22 5.22 0 011.748 3.972 5.195 5.195 0 01-1.748 3.994c-1.13 1.07-2.526 1.604-4.186 1.603h-4.027v.001zm0-8.883v6.57h4.088a3.234 3.234 0 002.411-.973 3.19 3.19 0 000-4.6 3.192 3.192 0 00-2.411-.998h-4.088v.001zM46.79 7.026c1.802 0 3.223.477 4.266 1.433 1.042.955 1.563 2.264 1.562 3.928v7.937h-2.331v-1.787h-.106c-1.01 1.471-2.351 2.207-4.027 2.207-1.43 0-2.626-.42-3.589-1.261a4.014 4.014 0 01-1.444-3.154c0-1.333.508-2.392 1.523-3.18 1.015-.786 2.37-1.18 4.066-1.182 1.447 0 2.639.262 3.575.788v-.553a2.743 2.743 0 00-1.006-2.14 3.467 3.467 0 00-2.353-.881c-1.361 0-2.438.57-3.232 1.709l-2.146-1.341c1.18-1.682 2.928-2.523 5.242-2.523zm-3.153 9.356a1.926 1.926 0 00.808 1.576c.54.421 1.208.644 1.894.631a3.901 3.901 0 002.741-1.13c.808-.753 1.212-1.637 1.212-2.653-.76-.6-1.82-.9-3.18-.9-.99 0-1.815.236-2.476.71-.668.48-1 1.064-1 1.766zM66 7.446L57.862 26h-2.516l3.02-6.492-5.351-12.062h2.649l3.868 9.25h.053l3.761-9.25H66z"/><path pid="1" d="M21.365 11.06c0-.738-.062-1.473-.188-2.2H10.9v4.165h5.887a5.003 5.003 0 01-2.177 3.286v2.703h3.513c2.058-1.882 3.243-4.664 3.243-7.955z" _fill="#4285F4"/><path pid="2" d="M10.899 21.625c2.941 0 5.418-.958 7.223-2.61l-3.513-2.703c-.978.658-2.237 1.033-3.71 1.033-2.843 0-5.256-1.9-6.119-4.463H1.16v2.786c1.85 3.652 5.619 5.957 9.739 5.957z" _fill="#34A853"/><path pid="3" d="M4.78 12.882a6.43 6.43 0 010-4.138V5.958H1.16a10.735 10.735 0 000 9.71l3.62-2.786z" _fill="#FBBC04"/><path pid="4" d="M10.899 4.28a5.943 5.943 0 014.18 1.622l3.112-3.086A10.519 10.519 0 0010.899.001C6.779 0 3.01 2.306 1.16 5.958l3.62 2.786c.862-2.562 3.275-4.463 6.118-4.463z" _fill="#EA4335"/>'
  }
})
