/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pay': {
    width: 20,
    height: 26,
    viewBox: '0 0 20 26',
    data: '<path pid="0" d="M16.826 10.659v9.202L2.49 24.473l11.34-7.638V0S5.714 6.385 3.182 8.254C.124 10.51 0 11.437 0 12.897v11.197C0 25.142.868 26 1.928 26H20V10.659h-3.174zm-8.18-.32c.664-.78 1.632-1.057 2.164-.615.532.44.426 1.432-.238 2.213-.662.782-1.631 1.057-2.163.616-.532-.442-.425-1.432.238-2.214z"/>'
  }
})
