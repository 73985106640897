/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bottom': {
    width: 4,
    height: 24,
    viewBox: '0 0 4 24',
    data: '<path pid="0" d="M4 12H1V0H0v24l4-12z"/>'
  }
})
