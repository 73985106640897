/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'whatsapp': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M16.564 13.502c.07.114.07.659-.159 1.295-.228.636-1.322 1.217-1.848 1.295-.471.07-1.068.1-1.723-.108a15.72 15.72 0 01-1.56-.573c-2.746-1.18-4.54-3.932-4.676-4.113C6.46 11.116 5.48 9.82 5.48 8.48s.707-2 .958-2.273c.25-.272.547-.34.73-.34.182 0 .365.001.524.009.168.008.394-.063.616.468.228.545.776 1.886.844 2.022.068.136.114.296.023.477-.091.182-.388.566-.684.932-.122.15-.28.283-.12.556.16.273.709 1.165 1.522 1.887 1.046.928 1.928 1.216 2.201 1.352.274.136.434.114.593-.068.16-.182.684-.796.867-1.068.182-.273.365-.227.616-.137.25.091 1.596.75 1.87.887.274.136.456.204.524.318zM22 10.909c-.002 6.011-4.917 10.902-10.954 10.902h-.004c-1.834 0-3.635-.458-5.235-1.327L0 22l1.554-5.649a10.843 10.843 0 01-1.462-5.45C.094 4.89 5.008 0 11.046 0a10.91 10.91 0 017.749 3.197A10.808 10.808 0 0122 10.91zm-1.85 0a8.985 8.985 0 00-2.664-6.41A9.064 9.064 0 0011.05 1.84c-5.022 0-9.107 4.065-9.109 9.06 0 1.713.48 3.38 1.392 4.823l.217.342-.92 3.344 3.446-.9.332.197c1.398.825 3 1.262 4.634 1.263h.004c5.018 0 9.102-4.065 9.105-9.061z"/>'
  }
})
