/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'suitcase': {
    width: 26,
    height: 22,
    viewBox: '0 0 26 22',
    data: '<path pid="0" d="M23.68 4H19V.832A.832.832 0 0018.168 0H7.832A.832.832 0 007 .832V4H2.32A2.32 2.32 0 000 6.32v13.36A2.32 2.32 0 002.32 22h21.36A2.32 2.32 0 0026 19.68V6.32A2.32 2.32 0 0023.68 4zM9 2h8v2H9V2zm15 17.68a.32.32 0 01-.32.32H2.32a.32.32 0 01-.32-.32V12h7v1.32c0 .376.304.68.68.68h6.64a.68.68 0 00.68-.68V12h7v7.68zM11 12v-2h4v2h-4zm6-2V8.68a.68.68 0 00-.68-.68H9.68a.68.68 0 00-.68.68V10H2V6.32A.32.32 0 012.32 6h21.36a.32.32 0 01.32.32V10h-7z"/>'
  }
})
