export default {
  registration: 'Регистрация',
  personalArea: 'Войти в кабинет',
  toUser: 'Пользователю',
  forBusiness: 'Для бизнеса',
  Business: 'Бизнес',
  forBlog: 'Для блога',
  Blog: 'Блог',
  forai: 'Ai-Clone',
  ai: 'Ai-Clone',
  aboutCompany: 'О компании',
  fitsInWorld: 'Первая в мире',
  worldPlatform: 'платформа',
  forBusinessAndInfluencers: '{text} {br} {text2} {br} для бизнеса {br} и лидеров мнений',
  forAiCloneTitle: '{text} {br} {text2} {br} {text3}',
  createPageInFiveMinutes: 'Создай страницу за 5 минут и начинай {br} принимать платежи {link}',
  createPageInFiveMinutesForAiClone:
    'Создай цифрового двойника за 5 минут{br} {text} начинай зарабатывать {text2}',
  bySubscription: 'по подписке!',
  go: 'Начать!',
  step: 'Шаг {0}',
  calculateIncome: 'Рассчитайте свой доход:',
  amountSubscribers: 'Количествово подписчиков',
  estimatedIncome: 'Ориентировочный доход',
  toCard: 'На карту',
  businessAccount: 'Р/с ИП',
  yandexMoney: 'ЮМани',
  requisites: 'Реквизиты',
  about: 'О компании',
  offerForThePurchase: 'Оферта купли-продажи',
  rulesForUse: 'Правила использования',
  privacyPolicy: 'Политика конфиденциальности',
  policyOfLegalization: 'Политика законности',
  aboutProject: 'О проекте',
  jobs: 'Вакансии',
  contacts: 'Контакты',
  cookies: 'Политика Cookies',
  aiClone: {
    title: {
      1: 'ИИ-клон ',
      2: 'новый уровень',
      3: 'взаимодействия с аудиторией',
    },
    footer: {
      1: 'и',
      2: 'когда ты спишь!',
    },
  },
  howItWorks: {
    page: {
      title: 'Как это работает',
      description: 'Процесс и рассчеты',
      nextStep: 'Далее',
      nextText: 'Ваш доход',
    },
    business: {
      a: {
        title: 'Создание сайта',
        text1:
          '<b>Придумайте адрес</b> своей страницы <b>или подключите свой домен</b>. По этому адресу пользователи будут находить вашу страницу.',
        text2: '<b>Публикуйте контент</b>, записывайте «кружочки»-обращения.',
        text3:
          '<b>Удобная система управления сайтом</b> не требует профессиональных знаний в сайтостроении. </br> Разберется даже новичок.',
        nextStep: 'Следующий шаг',
        nextText: 'Настройка услуг',
      },
      b: {
        title: 'Настройка услуг',
        text1: 'Каталог товаров',
        text2: 'Услуги по подписке',
        text3: 'Собственные закрытые клубы',
        text4: 'Товары по подписке',
        subText:
          'Ваш клиент оформляет подписку, <b>а наш сервис контролирует</b> процесс автоматического списания средств со счета покупателя, когда подходит срок следующего платежа.',
        nextStep: 'Следующий шаг',
        nextText: 'Продажи',
      },
      c: {
        title: 'Продажи',
        text1:
          '<b>Принимайте заказы и общайтесь</b> с посетителями через удобный интерфейс, выстраивайте отношения с каждым потенциальным клиентом.',
        text2:
          '<b>Создавайте общие чаты</b> по интересам и выстраивайте комьюнити вокруг своего бренда.',
        text3:
          '<b>Выкладывайте отзывы</b> клиентов, тем самым увеличивая доверие со стороны потенциальных клиентов, посещающих вашу площадку.',
        nextStep: 'Следующий шаг',
        nextText: 'Расширение бизнеса',
      },
      d: {
        title: 'Расширение бизнеса',
        text1:
          '<b>Создавайте собственные продающие страницы</b> с помощью нашего конструктора и размещайте их в любой блок.',
        text2:
          '<b>Создавайтете акционные страницы</b> со скидками и присылайте их своим посетителям или существующим клиентам.',
        text3:
          '<b>Создавайте текстовые страницы</b> с удобным редактором и пишите там полезные лонгриды.',
        nextStep: 'Далее',
        nextText: 'Система управления',
      },
    },
    aiClone: {
      title: 'Как сохранить уникальность, общаясь с большой аудиторией?',
      text1: '<b>Создай цифровую версию</b> себя.',
      text2: '<b>Выбери формат:</b> сайт, бот или мобильное приложение.',
      text3:
        '<b>Загрузи</b> свой опыт, мышление и личность в систему и<br/> <b>зарабатывай</b> по подписке от благодарных фанов.',
    },
    blog: {
      text1: '<b>Придумайте адрес</b> своей страницы.',
      text2: '<b>Выберите формат</b> блога: страница или канал.',
      text3: '<b>Делитесь</b> фото, видео, другим эксклюзивным контентом.',
      text4: '<b>Зарабатывайте по подписке</b> от благодарных фанов.',
    },
  },
  income: {
    business: {
      page: {
        title: 'Система управления',
        description: 'Управляйте своей бизнес-страницей с удобной CRM',
      },
      a: {
        title: 'Оперативность и мобильность',
        text1: 'Ваши менеджеры могут оперативно <b>отвечать даже с телефона!</b>',
        text2: '<b>Публикуйте контент</b>, записывайте "кружочки"-обращения.',
        text3: '<b>Получайте push-уведомления</b> о новых заказах.',
        text4: '<b>Мобильная версия</b> CRM для работы.',
        nextStep: 'Что еще дает ЦРМ',
        nextText: 'Управление пользователями',
      },
      b: {
        title: 'Управление пользователями',
        text1:
          '<b>Создавайте категории пользователей</b> и присваивайте их посетителям. Вы можете назначать авто-правила, и система сама будет назначать категорию пользователю, исходя из множества критериев.',
        text2:
          'Каждый посетитель сайта становится диалоговым окном в CRM, и вы можете написать ему или настроить автоворонку сообщений.',
        text3:
          'Отправляйте сообщения посетителям, и они мгновенно будут доставлены им вместе с <b>push-уведомлением</b>.',
        nextStep: 'Что еще дает ЦРМ',
        nextText: 'Аналитика, отчеты, платежи',
      },
      c: {
        title: 'Аналитика, отчеты, платежи',
        text1:
          '<b>Анализируйте воронку продаж</b>, находите слабые стороны и увеличивайте собственную прибыль.',
        text2: 'Создавайте финансовые <b>отчеты</b>, формируйте <b>платежи</b>.',
        nextStep: 'Что еще дает ЦРМ',
        nextText: 'Собственные автоворонки',
      },
      d: {
        title: 'Собственные автоворонки',
        text1:
          '<b>Авто-воронка продаж</b> — это продвижение пользователя по воронке продаж с помощью авто-сообщений и сбора информации о клиентах. Воронка продаж — это пошаговый путь, который клиент проходит от узнавания вашего продукта до покупки.',
        nextStep: 'Что еще дает ЦРМ',
        nextText: 'Максимум данных о клиентах',
      },
      e: {
        title: 'Максимум данных о клиентах',
        text1: 'С каких рекламных <b>объявлений</b> перешел пользователь.',
        text2: '<b>Что подогрело</b> его к совершению целевого действия.',
        text3: '<b>Модель устройства</b> и прочие данные.',
        text4:
          'Простая передача <b>данных о конверсиях</b> в рекламные сети Facebook / Google через Pixel технологию.',
        text5: 'Анализируйте, какой тип клиентов приносит <b>максимальный доход</b>.',
        nextStep: 'Далее',
        nextText: 'Максимум удобства',
      },
    },
    blog: {
      page: {
        title: 'Ваш доход',
        description: 'На чем именно вы зарабатываете',
        nextStep: 'Далее',
        nextText: 'Ваша медийность',
      },
      text1:
        '<b>Продавайте</b> эксклюзивные <b>рекламные форматы</b> компаниям: брендирование канала, чатов, фона сообщений.',
      text2: 'Проводите рекламные <b>трансляции</b>.',
      text3: 'Продавайте рекламные <b>посты</b>.',
      text4: 'Продавайте мерч или услуги через <b>встроенный магазин</b>.',
      subText:
        '<b>Важно</b>: технические возможности Supster позволяют рекламодателю анализировать результаты рекламных кампаний.',
    },
    aiClone: {
      page: {
        title: 'Supster - платформа для создания вашего ИИ-двойника',
        description: 'На чем именно вы зарабатываете',
        nextStep: 'Далее',
        nextText: 'Ваша медийность',
      },
      text1:
        'Мы хотим донести до масс общение <b>один на один</b> со знаменитостями и влиятельными лицами.',
      text2:
        'Мы создадим двойника <b>через различные источники</b> данных такие как чаты, электронные письма, посты в соц. сетях, видео на Youtube, аудиофайлы и многое другое.',
      text3:
        'платформа цифрового клонирования, которая фиксирует ваше мышление, делая ваши знания, опыт, личность и мнения <b>доступными другим в персонализированной форме</b>',

      subText:
        '<b>Основная идея Supster - дать возможность известным личностям взаимодействовать с большим количеством людей</b>',
    },
  },
  media: {
    business: {
      page: {
        title: 'Максимум удобства',
        description: 'Приложение в 1 клик',
        nextStep: 'Далее',
        nextText: 'Максимум гибкости',
      },
      list: {
        text1:
          'Создайте мобильное приложение для вашего бизнеса в 1 клик и  начинайте привлекать новых клиентов из ранее недоступных вам источников, таких как <b>Google UAC</b>, <b>Unity Ads</b>, <b>Applovin</b> и других рекламных сетей для мобильных приложений.',
        text2:
          'Подключите <b>Apple Pay</b> и <b>Google Pay</b> и увеличьте конверсию в покупку за счет удобства пользователя.',
        subText:
          'Если вы принимаете платежи по подписке, то <b>можете</b> забыть о том, что клиент забудет вовремя провести платеж. Деньги списываются <b>автоматически</b> без повторного подтверждения со стороны пользователя.',
      },
    },
    blog: {
      page: {
        title: 'Ваша медийность',
        description: 'Развивайтесь вместе с Supster',
        nextStep: 'Далее',
        nextText: 'Ваша выгода',
      },
      list: {
        text1:
          '<b>Создайте</b> свое персональное мобильное приложение за 5 минут и <b>начинайте получать</b> новых подписчиков из Google Play и AppStore!',
        text2:
          '<b>Создавайте удобных ботов</b> в Telegram / Viber / WhatsApp и публикуйте контент автоматически.',
      },
    },
    aiClone: {
      page: {
        title: 'Масштабируемость, Доступность, Пассивный доход',
        description: 'Приложение в 1 клик',
        nextStep: 'Далее',
        nextText: 'Ваша выгода',
      },
      list: {
        text1:
          'ИИ-клон может <b>одновременно взаимодействовать</b> с множеством подписчиков, <b>обеспечивая каждому персонализированный опыт,</b> независимо от размера аудитории.',
        text2:
          'Инфлуенсеры получают возможность <b>монетизировать свои знания и опыт</b> через систему подписки, <b>даже когда они не работают</b> лично',
      },
    },
  },
  payOrCrm: {
    business: {
      page: {
        title: 'Максимум гибкости',
        description: 'Собственные боты и интеграция с любой CRM',
        nextStep: 'Что еще дает ЦРМ',
        nextText: 'Увеличьте свои продажи!',
      },
      list: {
        text1:
          'Агрегируйте все сообщения в одном месте. Создайте собственного бота в <b>Telegram / Viber / <br /> WhatsApp</b> за 5 минут.',
        text2: '<b>Интеграция</b> с любой CRM.',
      },
    },
    blog: {
      page: {
        title: 'Ваша выгода',
        description: 'Постоянный доход и простой вывод средств',
        nextStep: 'Далее',
        nextText: 'Увеличьте свои продажи!',
      },
      list: {
        text1: '<b>Выводите заработанные средства</b> в 1 клик любым удобным способом.',
        subText:
          '<b>Помните:</b> человек, который купил подписку будет регулярно приносить пассивный доход, пока не попросит отписаться.',
      },
    },
    aiClone: {
      page: {
        title: 'Ваша выгода',
        description: 'Постоянный доход и простой вывод средств',
        nextStep: 'Далее',
        nextText: 'Увеличьте свои продажи!',
      },
      list: {
        text1: '<b>Выводите заработанные средства</b> в 1 клик любым удобным способом.',
        subText:
          '<b>Помните:</b> человек, который купил подписку будет регулярно приносить пассивный доход, пока не попросит отписаться.',
      },
    },
  },
  sales: {
    page: {
      title: 'Увеличивайте свои продажи</br> и оптимизируйте бизнес-процессы',
      titleAiClone: 'Готовы стать частью цифровой революции и</br> создать новый тренд ?',

      button: 'Начать',
      blog: {
        title: 'Читай блог!',
        description: 'Мы регулярно публикуем полезную информацию в нашем блоге.',
        link: 'Кейсы по товарке за октябрь.',
      },
      aiClone: {
        title: 'Читай блог!',
        description: 'Мы регулярно публикуем полезную информацию в нашем блоге.',
        link: 'Кейсы по SupsterGPT',
      },
      youTube: {
        title: 'Изучайте кейсы!',
        description: 'Смотрите видео с кейсами и обучающими материалами на YouTube.',
        link: 'Наш канал на YouTube',
      },
      telegram: {
        title: 'Спросите о Supster!',
        description: 'Хотите обсудить систему и пообщаться с нашими клиентами? Welcome в чат!',
        link: 'Перейти в Telegram',
      },
    },
  },
  rules: {
    toMain: 'На главную',
    title: 'Правила использования',
    subtitles: {
      1: 'Обрабатываемые данные',
      3: 'Требования к защите данных',
    },
    textSub:
      '<span>Пример!</span> К указанному программному обеспечению третьих лиц относятся системы сбора статистики посещений Google Analytics и Яндекс.Метрика.',
    paragraphs: {
      text1_1: 'Мы не осуществляем сбор ваших персональных данных с использованием Сайта.',
      text1_2:
        'Все данные, собираемые на Сайте, предоставляются и принимаются в обезличенной форме (далее – «Обезличенные данные»).',
      text1_3:
        'Обезличенные данные включают следующие сведения, которые не позволяют вас идентифицировать:',
      text1_3_1:
        'Информацию, которую вы предоставляете о себе самостоятельно с использованием онлайн-форм и программных модулей Сайта, включая имя или номер телефона и/или адрес электронной почты.',
      text1_3_2:
        'Данные, которые передаются в обезличенном виде в автоматическом режиме в зависимости от настроек используемого вами программного обеспечения.',
      text1_4:
        'Администрация вправе устанавливать требования к составу Обезличенных данных Пользователя, которые собираются использованием Сайта.',
      text1_6:
        'Администрация не осуществляет проверку достоверности предоставляемых данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий на ее использование.',
      text1_8:
        'Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:',
      text3_1:
        'Администрация осуществляет хранение данных и обеспечивает их охрану от несанкционированного доступа и распространения в соответствии с внутренними правилами и регламентами.',
      text3_3:
        'В целях повышения качества работы Администрация вправе хранить лог-файлы о действиях, совершенных Пользователем в рамках использования Сайта в течение 1 (Одного) года.',
    },
    list: {
      1: 'данные браузера (тип, версия, cookie);',
      2: 'данные устройства и место его положения;',
      3: 'данные операционной системы (тип, версия, разрешение экрана);',
      4: 'данные запроса (время, источник перехода, IP-адрес).',
    },
  },
  notFound: {
    goBack: 'На главную',
    title: 'Здесь ничего нет!',
    text: 'Скорее всего, вы попали сюда по ошибке. <br /> <b> Вернитесь на главную страницу и <br /> выберите нужный вам раздел. </b>',
  },
  cookiesHero: {
    text: 'Этот сайт использует файлы Cookie для хранения данных. Продолжая использовать сайт, вы даете свое согласие на работу с этими файлами в соответствии с политикой Cookie и Политикой конфиденциальности Supster.',
    more: 'Подробнее',
    agree: 'Согласен',
  },
  barText:
    'Не упустите свой шанс! <b>Присоединяйтесь к нашему списку ожидания</b>, чтобы первыми испытать платформу.',
  soon: 'Скоро',
  startForm: {
    success: 'Ваши данные успешно отправлены!',
    name: 'Ваше имя',
    insta: 'Ссылка на Instagram',
    telegram: 'Контакт Telegram',
    sending: 'Отправка...',
    send: 'Отправить',
    error: 'Ошибка',
  },
}
