<template>
  <div :class="[$style.social, isFixed && $style.socialFixed, column && $style.socialColumn]">
    <!-- <a :href="telegramLink" target="_blank" 
    :class="[$style.link, shadow && $style.linkShadow]">
      <icon name="airplane" />
    </a> -->
    <a :href="youtubeLink" target="_blank" :class="[$style.link, shadow && $style.linkShadow]">
      <icon name="yt" />
    </a>
  </div>
</template>
<script>
export default {
  name: 'TheSocial',
  props: {
    isFixed: Boolean,
    column: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    youtubeLink() {
      return process.env.VUE_APP_YOUTUBE
    },
    telegramLink() {
      return process.env.VUE_APP_TELEGRAM
    },
  },
}
</script>
<style lang="scss" module>
.social {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-self: center;
  justify-self: start;
  column-gap: 14px;

  &Column {
    grid-template-columns: 1fr;
    row-gap: 16px;
    grid-template-rows: 1fr 1fr;
  }

  @include media('mobile') {
    column-gap: 16px;
  }

  &Fixed {
    position: fixed;
    bottom: $padding * 1.5;
    left: $padding * 2;

    @include media('desktop') {
      bottom: $paddingVW;
      left: $paddingVW;
    }

    @include media('mobile') {
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text);
  border-radius: 14px;
  min-width: 40px;
  min-height: 40px;

  &Shadow {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  @include media('desktop') {
    padding: 0.615vw;
  }

  @include media('mobile') {
    border: 1px solid $primary;

    &Shadow {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 20px;
    height: 16px;

    @include media('mobile') {
      color: $primary;
    }
  }
}
</style>
