var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[_vm.$style.section, _vm.$style[("section-" + _vm.color)]]},[_c('div',{class:_vm.$style.inner},[_c('div',[_c('strong',{staticClass:"animation-fade-symbol",class:[_vm.$style.symbol, _vm.$style[("symbol-theme-" + _vm.theme)]]},[_vm._v("////")])]),_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.page},[_c('p',{class:_vm.$style.pageCount},[_vm._v(".05")])]),_c('div',{staticClass:"animation-content",class:_vm.$style.wrapper},[_c('h2',{class:_vm.$style.title,domProps:{"innerHTML":_vm._s(_vm.isAiClone ? _vm.$t('sales.page.titleAiClone') : _vm.$t('sales.page.title'))}}),(_vm.isAiClone)?_c('v-button',{class:_vm.$style.button,attrs:{"icon":"on"},on:{"click":function($event){return _vm.setIsShowAiClonePopUp(true)}}},[_vm._v(" "+_vm._s(_vm.$t('sales.page.button'))+"! ")]):_c('v-button',{class:_vm.$style.button,attrs:{"icon":"on","href":_vm.link}},[_vm._v(" "+_vm._s(_vm.$t('sales.page.button'))+"! ")])],1),_c('div',{staticClass:"animation-content animation-content-after",class:_vm.$style.row},_vm._l((_vm.articles),function(ref,index){
var title = ref.title;
var description = ref.description;
var link = ref.link;
var value = ref.value;
var content = ref.content;
var page = ref.page;
var disabled = ref.disabled;
var disabledText = ref.disabledText;
return _c('v-article',{key:("article-" + index),attrs:{"title":title,"description":description,"link":link,"disabled":disabled,"disabledText":disabledText,"value":value,"page":page,"content":content}})}),1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }