<template>
  <transition name="fade" mode="out-in">
    <h2 v-if="isSuccess" :class="$style.title">
      {{ $t('startForm.success') }}
    </h2>
    <form v-else @submit.prevent="submit" :class="$style.wrapper">
      <h2 :class="$style.title">AI-Clone</h2>
      <input
        v-model="data.name"
        required
        :class="$style.input"
        :placeholder="$t('startForm.name')"
        type="text"
      />
      <input
        v-model="data.instagram"
        required
        :class="$style.input"
        :placeholder="$t('startForm.insta')"
        type="text"
      />
      <input
        v-model="data.telegram"
        required
        :class="$style.input"
        :placeholder="$t('startForm.telegram')"
        type="text"
      />
      <button :disabled="isLoading" :class="$style.button">
        {{
          isLoading && !isError
            ? $t('startForm.sending')
            : isError && isLoading
            ? $t('startForm.error')
            : $t('startForm.send')
        }}
      </button>
    </form>
  </transition>
</template>
<script>
import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
  name: 'TheStartForm',
  data() {
    return {
      data: {
        name: '',
        instagram: '',
        telegram: '',
      },
      isLoading: false,
      isSuccess: false,
      isError: false,
    }
  },
  methods: {
    ...mapMutations(['setIsShowAiClonePopUp']),
    async submit() {
      try {
        this.isLoading = true
        this.isError = false
        await axios.post(
          'https://supster.fenix-pr.ru/landing/form/1.php',
          {
            name: this.data.name,
            tg_link: this.data.telegram,
            inst_link: this.data.instagram,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        this.isSuccess = true

        setTimeout(() => {
          this.setIsShowAiClonePopUp(false)
        }, 2000)
      } catch (error) {
        this.isError = true
        setTimeout(() => {
          this.isError = false
          this.isLoading = false
        }, 1000)
      }
    },
  },
  destroyed() {},
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100vw;
  max-width: 360px;
  padding: 0 16px;
}
.title {
  font-size: 42px;
  text-align: center;
  color: #fff;
  font-weight: 700;
}
.input {
  border: 1px solid #b4b3b3;
  outline: none;
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  color: black;
  background: #fff;
  &::placeholder {
    color: #8d8d8d;
  }
}
.button {
  padding: 12px;
  border-radius: 6px;
  border: none;
  background: #3dffc0;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  color: rgb(39, 20, 96);
  &:hover,
  &:disabled {
    opacity: 0.7;
  }
}
</style>
