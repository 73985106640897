<template>
  <section :class="$style.section">
    <h3 :class="$style.title">{{ title }}</h3>
    <div :class="$style.content">
      <p :class="$style.text">{{ text }}</p>
      <div :class="$style.range">
        <div
          v-for="(range, index) in ranges"
          :key="`range-${index}`"
          :class="[$style.rangeItem, range.position <= value && $style.rangeItemActive]"
          :style="{
            left: `${range.position}%`,
          }"
        >
          {{ range.value }}
        </div>
      </div>
      <range-slider
        v-model="value"
        ref="slider"
        class="slider"
        :class="$style.slider"
        :min="min"
        :max="max"
        :step="step"
      />
    </div>
    <div :class="$style.column">
      <p :class="$style.text">{{ $t('estimatedIncome') }}:</p>
      <div :class="$style.wrapper">
        <p :class="$style.income">{{ incomeMin.toLocaleString() }}</p>
        <p :class="$style.income">—</p>
        <p :class="$style.income">{{ incomeMax.toLocaleString() }}</p>
        <p :class="$style.income">₽</p>
      </div>
    </div>
  </section>
</template>
<script>
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
  name: 'VIncome',
  components: {
    RangeSlider,
  },
  props: {
    title: String,
    text: String,
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      ranges: [
        {
          position: 0,
          value: '100 K',
        },
        {
          position: 25,
          value: '250 K',
        },
        {
          position: 50,
          value: '500 K',
        },
        {
          position: 75,
          value: '1 M',
        },
        {
          position: 100,
          value: '1 M +',
        },
      ],
      value: 25,
      incomeMin: 250000,
      incomeMax: 500000,
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.incomeMax = this.value * 2 * 10000
        this.incomeMin = (this.value === 75 ? 100 : this.value) * 10000
        this.$refs.slider.$refs.knob.style.transform = `translate(-${this.value}%, -50%)`
        return
      }
      this.$refs.slider.$refs.knob.style.transform = 'translate(0%, -50%)'
      this.incomeMax = 250000
      this.incomeMin = 100000
    },
  },
  methods: {
    getTransformValue(index) {
      if (index === 0) {
        return 0
      }
      if (index === this.ranges.length - 1) {
        return 100
      }
      return 50
    },
  },
}
</script>
<style lang="scss" module>
.section {
  display: flex;
  flex-direction: column;
  margin: 48px 0 0 0;
  position: relative;
  z-index: 0;
  padding: 32px;
  overflow: hidden;
  background-color: white;
  box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.1);
  border-radius: 24px;
  box-sizing: border-box;

  @include media('mobile') {
    padding: 24px;
    border-radius: 16px;
    margin: 0 0 0 -16px;
    width: calc(100% + 32px);
  }

  @media (max-width: 1320px) and (min-width: 941px) {
    padding: 16px;
  }

  @media (max-width: 580px) {
    margin: 28px 0 0 -24px;
    width: calc(100% + 48px);
    padding: 24px 24px 20px 24px;
  }
}

.title {
  color: $primary;
  font-weight: 800;
  font-size: 24px;
  margin: 0 0 24px 0;
  line-height: 30px;
  letter-spacing: -0.05em;

  @media (max-width: 1320px) and (min-width: 941px) {
    font-size: 16px;
    margin: 0;
  }

  @include media('mobile') {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 16px 0;
  }
}

.content {
  width: 100%;
}

.range {
  position: relative;
  height: 16px;
  width: 100%;
  margin: 0 0 12px 0;

  @media (max-width: 1320px) and (min-width: 941px) {
    margin: 0 0 6px 0;
  }

  @include media('mobile') {
    height: 14px;
  }

  @media (max-width: 580px) {
    margin: 16px 0 10px 0;
  }

  &Item {
    position: absolute;
    top: 0;
    align-items: center;
    height: 24px;
    width: 48px;
    padding: 4px 6px 4px 4px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    text-align: center;
    background-color: $light;
    border-radius: 4px;

    &Active {
      background-color: $primary;
    }

    &:nth-child(2) {
      transform: translate(-25%, 0);
    }

    &:nth-child(3) {
      transform: translate(-50%, 0);
    }

    &:nth-child(4) {
      transform: translate(-75%, 0);
    }

    &:nth-child(5) {
      transform: translate(-100%, 0);
    }

    @media (max-width: 1320px) and (min-width: 941px) {
      font-size: 10px;
      width: 40px;
      padding: 1px 3px 2px 3px;
      height: 20px;
    }

    @include media('mobile') {
      width: 48px;
      height: 24px;
    }
  }
}

.text {
  color: $primary;
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 16px 0;
  line-height: 24px;

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 18px;
    line-height: 1.5;
  }

  @media (max-width: 1600px) and (min-width: 941px) {
    font-size: 13px;
    line-height: 1.5;
  }

  @media (max-width: 1060px) and (min-width: 941px) {
    font-size: 1.1vw;
    max-width: 320px;
  }

  @media (max-width: 580px) {
    font-size: 13px;
    line-height: 12px;
    margin: 4px 0 0 0;
  }
}

.slider {
  width: 100%;
  margin: 0 0 16px 0;

  @media (max-width: 1320px) and (min-width: 941px) {
    margin: 0 0 12px 0;
  }

  @media (max-width: 580px) {
    margin: 0 0 8px 0;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include media('mobile') {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 12px;
  }

  @media (max-width: 580px) {
    row-gap: 4px;
  }
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  justify-self: start;
  column-gap: 8px;
  max-width: 80vw;
  margin: -15px 0 0 0;
  letter-spacing: -0.06em;

  @include media('mobile') {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (max-width: 580px) {
    letter-spacing: -0.05em;
    margin: 2px 0 0 0;
  }
}

.income {
  color: $primary;
  font-weight: 800;
  font-size: 24px;

  @media (max-width: 1320px) and (min-width: 941px) {
    font-size: 20px;
  }

  @include media('mobile') {
    font-size: 20px;
    border-radius: 4px 4px 4px 0;
    text-align: center;
  }
}
</style>
