/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text': {
    width: 16,
    height: 10,
    viewBox: '0 0 16 10',
    data: '<path pid="0" d="M0 6h14v1H0V6zM0 9h8v1H0V9zM0 0h16v1H0V0zM0 3h12v1H0V3z"/>'
  }
})
