/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'apple-pay': {
    width: 68,
    height: 28,
    viewBox: '0 0 68 28',
    data: '<path pid="0" d="M12.425 3.61c-.797.946-2.072 1.692-3.347 1.585-.16-1.279.465-2.637 1.195-3.477C11.07.746 12.465.053 13.593 0c.134 1.332-.384 2.637-1.168 3.61zm1.155 1.838C11.735 5.342 10.154 6.5 9.277 6.5c-.89 0-2.232-.999-3.693-.972-1.9.027-3.666 1.106-4.635 2.824-1.993 3.437-.518 8.525 1.408 11.323.943 1.385 2.072 2.903 3.56 2.85 1.407-.053 1.965-.919 3.665-.919 1.714 0 2.205.92 3.693.893 1.54-.027 2.51-1.386 3.454-2.771 1.075-1.572 1.514-3.104 1.54-3.184-.026-.026-2.975-1.159-3.002-4.569-.026-2.85 2.325-4.21 2.431-4.289-1.328-1.971-3.4-2.185-4.117-2.238zm10.667-3.863v20.767h3.214v-7.1h4.45c4.064 0 6.92-2.797 6.92-6.847 0-4.05-2.803-6.82-6.814-6.82h-7.77zm3.214 2.718h3.706c2.79 0 4.383 1.491 4.383 4.116 0 2.624-1.594 4.129-4.396 4.129H27.46V4.303zm17.241 18.209c2.019 0 3.892-1.026 4.742-2.65h.066v2.49h2.976V12.015c0-2.997-2.391-4.928-6.07-4.928-3.414 0-5.938 1.958-6.03 4.649h2.895c.239-1.28 1.421-2.118 3.041-2.118 1.966 0 3.069.919 3.069 2.61v1.146l-4.012.24c-3.732.226-5.751 1.758-5.751 4.422 0 2.69 2.085 4.476 5.074 4.476zm.863-2.464c-1.713 0-2.802-.826-2.802-2.092 0-1.305 1.05-2.064 3.055-2.184l3.573-.227v1.172c0 1.945-1.647 3.33-3.826 3.33zM56.457 28c3.135 0 4.61-1.199 5.898-4.835L68 7.286h-3.268l-3.785 12.269h-.067L57.095 7.286h-3.36l5.445 15.12-.292.919c-.491 1.558-1.288 2.157-2.71 2.157-.252 0-.743-.026-.943-.053v2.491c.186.053.983.08 1.222.08z"/>'
  }
})
