/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 18,
    height: 12,
    viewBox: '0 0 18 12',
    data: '<path d="M14.4 2.3C12.91 0.77 10.95 0 9 0C7.05 0 5.09 0.77 3.6 2.3L0 6L3.6 9.7C5.09 11.23 7.05 12 9 12C10.95 12 12.91 11.23 14.4 9.7L18 6L14.4 2.3ZM13.68 9C12.43 10.29 10.76 11 9 11C7.24 11 5.57 10.29 4.32 9L1.4 6L4.32 3C5.57 1.71 7.23 1 9 1C10.77 1 12.43 1.71 13.68 3L16.6 6L13.68 9ZM9 3C7.34 3 6 4.34 6 6C6 7.66 7.34 9 9 9C10.66 9 12 7.66 12 6C12 4.34 10.66 3 9 3ZM9 8C7.9 8 7 7.1 7 6C7 4.9 7.9 4 9 4C10.1 4 11 4.9 11 6C11 7.1 10.1 8 9 8Z" fill="#271460"/>',
  }
})


