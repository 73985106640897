/* eslint-disable */
var background = require('vue-svgicon')
background.register({
  background: {
    width: 1280,
    height: 640,
    viewBox: '0 0 1280 640',
    data: '<path d="M87.4813 -219L305.081 -219L-2.11873 933H-219.719L87.4813 -219ZM485.231 -219H702.831L395.631 933H178.031L485.231 -219ZM882.981 -219H1100.58L793.381 933H575.781L882.981 -219ZM1280.73 -219H1498.33L1191.13 933H973.531L1280.73 -219Z">',
  },
})
