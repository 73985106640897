/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'login': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 10A5 5 0 108 0a5 5 0 000 10zm0-9c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4zM0 15h16v1H0v-1zm16-3v1H0v-1h16z"/>'
  }
})
