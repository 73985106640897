<template>
  <div>
    <footer :class="$style.section" id="footer">
      <div :class="$style.wrapper">
        <the-social v-if="!isMobile" />
      </div>
      <div :class="$style.content">
        <transition name="fade-slide">
          <hero-footer
            v-if="isFirstSlide && !isMobile"
            class="animation-content animation-content-after"
          />
        </transition>
        <transition name="fade-slide">
          <sales-footer v-if="isLastSlide" class="animation-content animation-content-after" />
        </transition>
      </div>
      <div></div>
    </footer>
    <the-mouse v-if="!isMobile" :is-slider-slide="isSliderSlide" :is-last="isLastSlide" />
  </div>
</template>
<script>
import pages from '@/data/pages'
import { mapState } from 'vuex'
import HeroFooter from '@/components/footers/HeroFooter.vue'
import SalesFooter from '@/components/footers/SalesFooter.vue'
import TheSocial from '@/components/TheSocial.vue'
import TheMouse from '@/components/TheMouse.vue'

export default {
  name: 'TheFooter',
  components: {
    HeroFooter,
    SalesFooter,
    TheSocial,
    TheMouse,
  },
  props: {
    isSliderSlide: Boolean,
  },
  computed: {
    ...mapState(['isMobile', 'activeSlideIndex']),
    slidesArray() {
      return pages[this.$route.name].anchors
    },
    isFirstSlide() {
      return this.activeSlideIndex === 0
    },
    isLastSlide() {
      return this.isMobile ? true : this.slidesArray.length - 1 === this.activeSlideIndex
    },
  },
}
</script>
<style lang="scss" module>
.section {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0 32px 35px 40px;
  z-index: 2;

  @media (max-width: 1600px) {
    padding: 0 32px 20px 40px;
  }

  @include media('mobile') {
    position: relative;
    left: 0;
    padding: 0 $padding $padding;
    transform: translateX(0);
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-self: center;
  justify-self: start;

  @include media('mobile') {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
}

.content {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include media('mobile') {
    max-width: 100%;
    column-gap: 0;
  }
}
</style>
