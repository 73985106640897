<template>
  <article :class="$style.article">
    <h4 v-html="$t(`sales.page.${page}.title`)" :class="$style.title"></h4>
    <p :class="$style.description" v-html="$t(`sales.page.${page}.description`)"></p>
    <div :class="[$style.container, disabled && $style.disabled]">
      <div :class="$style.content" v-touch:swipe="onSwipe">
        <a
          v-for="(item, index) in content"
          :key="`article-slide-${index}`"
          :class="[$style.link, activeIndex === index && $style.linkActive]"
          :href="item.link"
          target="_blank"
          :style="{
            'background-image': `url(${item.image})`,
          }"
        >
          <div :class="$style.cover">
            <icon :class="$style.coverMainIcon" :name="item.main" />
            <icon :class="$style.coverBack" :name="item.back" />
            <p :class="$style.coverText" v-if="item.text">{{ item.text }}</p>
          </div>
        </a>
      </div>
      <footer :class="[$style.footer, disabled && $style.disabled]">
        <div v-if="content.length > 1" :class="$style.navigation">
          <button
            v-for="(_, index) in content"
            :key="`article-nav-${index}`"
            :class="[$style.navigationItem, activeIndex === index && $style.navigationItemActive]"
            @click="activeIndex = index"
          ></button>
        </div>
        <a
          v-html="$t(`sales.page.${page}.link`)"
          :class="$style.footerLink"
          :href="link"
          target="_blank"
        ></a>
        <span v-if="disabled" :class="$style.disabledText">{{ $t('soon') }}</span>
      </footer>
    </div>
  </article>
</template>
<script>
export default {
  name: 'VArticle',
  props: {
    title: String,
    description: String,
    link: String,
    disabled: Boolean,
    value: String,
    page: String,
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    onSwipe(data) {
      if (data === 'left') {
        this.activeIndex += 1
      }
      if (data === 'right') {
        this.activeIndex -= 1
      }
      if (this.activeIndex === this.content.length || this.activeIndex <= 0) {
        this.activeIndex = 0
      }
    },
  },
}
</script>
<style lang="scss" module>
.disabled {
  position: relative;
  pointer-events: none;
  opacity: 0.8;
}
.disabledText {
  z-index: 1;
  color: #3dffc0;
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  right: 16px;
  top: 10px;
}
.article {
  position: relative;
  display: grid;
  row-gap: 16px;
  width: 100%;
  margin: 0 64px 0 0;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 1200px) and (min-width: 941px) {
    margin: 0 16px 0 0;
  }

  @include media('mobile') {
    row-gap: 16px;
    margin: 0 0 24px 0;
    width: calc(100% + 16px);
    max-width: 600px;
  }

  @media (max-width: 670px) {
    margin: 0 0 24px -8px;

    &:last-child {
      margin: 0 0 0 -8px;
    }
  }

  @media (max-width: 580px) {
    row-gap: 0;
    margin: 0 0 24px -16px;
    width: calc(100% + 32px);

    &:last-child {
      margin: 0 0 0 -16px;
    }
  }
}

.title {
  color: var(--text);
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
  margin: 0 0 0 16px;

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 28px;
    line-height: 1.25;
  }

  @media (max-width: 1600px) {
    font-size: 16px;
    line-height: 24px;
  }

  @include media('mobile') {
    color: $primary;
    font-size: 20px;
    line-height: 20px;
  }

  @media (max-width: 580px) {
    margin: 0 0 12px 16px;
  }
}

.description {
  color: var(--text);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: -8px 0 0 16px;
  max-width: 340px;

  @media (min-width: 1920px) and (min-height: 1080px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 360px;
  }

  @media (max-width: 1200px) and (min-width: 941px) {
    font-size: 0.9vw;
    max-width: 190px;
  }

  @include media('mobile') {
    color: $primary;
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: 580px) {
    margin: 0 0 12px 16px;
  }
}

.container {
  box-shadow: 15px 15px 60px rgba(39, 20, 96, 0.1);
  border-radius: 24px;
  background: white;

  @include media('mobile') {
    border-radius: 16px;
  }

  @media (max-width: 580px) {
    box-shadow: 10px 10px 24px rgba(39, 20, 96, 0.1);
  }
}

.content {
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;

  @include media('mobile') {
    height: 160px;
    border-radius: 8px;
  }

  @media (min-width: 1920px) and (min-height: 1080px) {
    height: 19vh;
  }

  @media (max-width: 1600px) {
    height: 120px;
  }

  @media (max-width: 492px) {
    height: 138px;
  }

  @media (max-width: 428px) {
    height: 120px;
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: $primary;
  background-size: cover;
  opacity: 0;
  cursor: pointer;
  border-radius: 24px;

  &Active {
    opacity: 1;
  }

  @include media('mobile') {
    border-radius: 16px;
  }
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  height: 100%;
  padding: $padding / 2;
  box-sizing: border-box;
  background-color: rgba($primary, 0.6);
  border-radius: 24px;
  transition: opacity $transition;

  @include media('desktop') {
    padding: $paddingVW / 1.5;
  }

  @include media('mobile') {
    padding: $padding / 2;
    border-radius: 16px;
  }

  &:hover {
    opacity: 0.7;
  }

  &SecondIcon {
    align-self: start;
    width: 16px;
    height: 16px;
    color: $secondary;

    @include media('desktop') {
      width: 1.231vw;
      height: 1.231vw;
    }

    @include media('mobile') {
      width: 16px;
      height: 16px;
    }
  }

  &MainIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    color: $secondary;
    transform: translate(-50%, -50%);

    @media (min-width: 1920px) and (min-height: 1080px) {
      height: 4.762vh;
      width: 4.762vh;
    }

    @include media('mobile') {
      width: 40px;
      height: 40px;
    }
  }

  &Back {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    height: 148%;
    color: $white7;

    @media (max-width: 1030px) and (min-width: 941px) {
      height: 130%;
      top: 0px;
    }
  }

  &Text {
    align-self: end;
    color: white;
    font-size: 12px;
    line-height: 12px;

    @include media('mobile') {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 16px;
  padding: 18px 24px;
  &.disabled {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1600px) {
    padding: 16px 18px;
  }

  @include media('mobile') {
    column-gap: 16px;
    padding: 12px 16px;
  }

  &Link {
    align-self: center;
    justify-self: start;
    color: var(--text);
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: 1920px) and (min-height: 1080px) {
      font-size: 14px;
      line-height: 14px;
    }

    @media (max-width: 1075px) and (min-width: 941px) {
      font-size: 1vw;
    }

    @include media('mobile') {
      color: $primary;
      font-size: 12px;
      line-height: 12px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.navigation {
  display: grid;
  grid-auto-flow: column;
  align-self: center;
  justify-self: start;
  column-gap: 8px;

  @include media('desktop') {
    column-gap: 0.615vw;
  }

  @include media('mobile') {
    column-gap: 8px;
  }

  &Item {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 1px solid $primary-light;
    border-radius: 100%;
    outline: none;
    cursor: pointer;
    transition: all $transition ease-in;

    @include media('desktop') {
      width: 0.769vw;
      height: 0.769vw;
    }

    @include media('mobile') {
      width: 8px;
      height: 8px;
    }

    &Active {
      background-color: $secondary;
      border: 1px solid $secondary;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
