/* eslint-disable */
var blog = require('vue-svgicon')
blog.register({
  blog: {
    width: 40,
    height: 32,
    viewBox: '0 0 40 32',
    data: '<path d="M32 0H8C3.58 0 0 3.58 0 8V24C0 28.42 3.58 32 8 32H32C36.42 32 40 28.42 40 24V8C40 3.58 36.42 0 32 0ZM8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10ZM16 18C14.9 18 14 17.1 14 16C14 14.9 14.9 14 16 14C17.1 14 18 14.9 18 16C18 17.1 17.1 18 16 18ZM16 10C14.9 10 14 9.1 14 8C14 6.9 14.9 6 16 6C17.1 6 18 6.9 18 8C18 9.1 17.1 10 16 10ZM24 26C22.9 26 22 25.1 22 24C22 22.9 22.9 22 24 22C25.1 22 26 22.9 26 24C26 25.1 25.1 26 24 26ZM24 18C22.9 18 22 17.1 22 16C22 14.9 22.9 14 24 14C25.1 14 26 14.9 26 16C26 17.1 25.1 18 24 18ZM24 10C22.9 10 22 9.1 22 8C22 6.9 22.9 6 24 6C25.1 6 26 6.9 26 8C26 9.1 25.1 10 24 10ZM32 18C30.9 18 30 17.1 30 16C30 14.9 30.9 14 32 14C33.1 14 34 14.9 34 16C34 17.1 33.1 18 32 18Z"/>',
  },
})
