/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M0 0v16l14-8L0 0zm1 1.723L11.984 8 1 14.277V1.723z"/>'
  }
})
