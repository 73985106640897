var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[_vm.$style.section, _vm.$style[("section-" + _vm.color)]]},[_c('div',{class:_vm.$style.inner},[_c('div',[_c('strong',{staticClass:"animation-fade-symbol",class:[_vm.$style.symbol, _vm.$style[("symbol-theme-" + _vm.theme)]]},[_vm._v("////")])]),_c('div',{class:_vm.$style.content},[_c('v-player',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAiClone),expression:"isAiClone"}],class:_vm.$style.player,attrs:{"disabled-name":_vm.disabledName},on:{"click":_vm.onPlayer}}),_c('div',{class:_vm.$style.container},[(!_vm.isAiClone)?_c('i18n',{staticClass:"animation-content",class:[
            _vm.$style.title,
            _vm.$style[("title-" + _vm.color)],
            ( _obj = {}, _obj[_vm.$style.title_pt] = _vm.locale === 'pt', _obj ) ],attrs:{"path":"forBusinessAndInfluencers","tag":"h1"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('fitsInWorld')))])]},proxy:true},{key:"text2",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('worldPlatform')))])]},proxy:true},{key:"br",fn:function(){return [_c('br')]},proxy:true}],null,false,261102410)}):_c('i18n',{staticClass:"animation-content",class:[
            _vm.$style.title,
            _vm.$style.aiCloneTitle,
            _vm.$style[("title-" + _vm.color)],
            ( _obj$1 = {}, _obj$1[_vm.$style.title_pt] = _vm.locale === 'pt', _obj$1 ) ],attrs:{"path":"forAiCloneTitle","tag":"h1"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('aiClone.title.1')))]),_c('br')]},proxy:true},{key:"text2",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('aiClone.title.2')))]),_c('br')]},proxy:true},{key:"text3",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('aiClone.title.3'))+" ")]},proxy:true}])}),(_vm.isMobile)?_c('hero-footer',{staticClass:"animation-content animation-content-after"}):_vm._e()],1)],1),_c('div'),_c('div',{class:_vm.$style.contentMobile},[_c('v-player',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAiClone),expression:"isAiClone"}],class:_vm.$style.playerMobile,attrs:{"disabled-name":_vm.disabledName},on:{"click":_vm.onPlayer}}),_c('h2',{class:_vm.$style.titleMobile},[(!_vm.isAiClone)?_c('i18n',{staticClass:"animation-content",class:[
            _vm.$style.title,
            _vm.$style[("title-" + _vm.color)],
            ( _obj$2 = {}, _obj$2[_vm.$style.title_pt] = _vm.locale === 'pt', _obj$2 ) ],attrs:{"path":"forBusinessAndInfluencers","tag":"h1"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('fitsInWorld')))])]},proxy:true},{key:"text2",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('worldPlatform')))])]},proxy:true},{key:"br",fn:function(){return [_c('br')]},proxy:true}],null,false,261102410)}):_c('i18n',{staticClass:"animation-content",class:[
            _vm.$style.title,
            _vm.$style.aiCloneTitle,
            _vm.$style[("title-" + _vm.color)],
            ( _obj$3 = {}, _obj$3[_vm.$style.title_pt] = _vm.locale === 'pt', _obj$3 ) ],attrs:{"path":"forAiCloneTitle","tag":"h1"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('aiClone.title.1')))]),_c('br')]},proxy:true},{key:"text2",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('aiClone.title.2')))]),_c('br')]},proxy:true},{key:"text3",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('aiClone.title.3'))+" ")]},proxy:true}])})],1),_c('i18n',{staticClass:"animation-content",class:[_vm.$style.subtitleMobile, _vm.$style[("title-" + _vm.color)]],attrs:{"path":"forBusinessAndInfluencers","tag":"h1"}}),(_vm.isMobile)?_c('hero-footer',{staticClass:"animation-content animation-content-after"}):_vm._e()],1)]),_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAiClone),expression:"isAiClone"}],ref:"video",staticClass:"animation-fade",class:_vm.$style.video,attrs:{"loop":"","muted":"","data-autoplay":"","playsinline":"","id":"#myVideo"},domProps:{"muted":true},on:{"playing":function($event){return _vm.onVideo(true)},"pause":function($event){return _vm.onVideo(false)}}},[_c('source',{attrs:{"src":require("../../assets/images/ai-background.mp4"),"type":"video/mp4"}})]),(_vm.disabledName === 'pause')?_c('img',{class:_vm.$style.video,attrs:{"src":require("../../assets/images/ai-background.jpg"),"alt":""}}):_vm._e(),(_vm.isAiClone)?_c('div',{class:_vm.$style.filter}):_c('div',{staticClass:"animation-fade",class:[_vm.$style.cover]}),(_vm.isMobile)?_c('div',{class:_vm.$style.arrow},[_c('icon',{attrs:{"name":"arrow"}}),_c('icon',{attrs:{"name":"arrow"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }