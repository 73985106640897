<template>
  <div ref="section">
    <v-page
      :index="index"
      :class="$style[`section-${color}`]"
      :title="sectionTitle"
      :nextStep="nextStep"
      :nextText="nextText"
      :description="sectionDescriprion"
      :theme="color"
      :band-deg="40"
      :symbol="{ value: '{ }', isSmall: true }"
      is-large-content
    >
      <v-list
        :hideLine="$route.name === 'blog' || $route.name === 'ai' ? true : false"
        :list="data[name]"
        :class="$style.list"
        :theme="color"
      />
      <div :class="$style.content" v-if="isBlog || isAiClone">
        <div :class="$style.wrapper">
          <v-button
            v-for="{ name, value } in icons"
            :key="name"
            :class="$style.button"
            is-no-tag
            is-social
          >
            <icon :name="name" :class="[$style.icon, $style[`icon-${name}`]]" /> {{ $t(value) }}
          </v-button>
        </div>
      </div>
      <div :class="[$style.wrapper, $style.wrapperImages]" v-else>
        <div v-for="image in 12" :key="`crm-image-${image}`" :class="$style.container">
          <img
            :class="$style.image"
            :src="require(`@/assets/images/crms/crm-${image}.png`)"
            alt="Crm"
            draggable="false"
          />
        </div>
      </div>
      <v-list-item
        :hideLine="true"
        v-if="isBlog || isAiClone"
        :theme="color"
        number="+"
        type="dark"
        component="div"
      >
        <p v-html="$t('payOrCrm.blog.list.subText')"></p>
      </v-list-item>
    </v-page>
  </div>
</template>
<script>
import VList from '@/components/list/VList.vue'
import VPage from '@/components/VPage.vue'
import VButton from '@/components/VButton.vue'
import VListItem from '@/components/list/VListItem.vue'

export default {
  name: 'PayOrCRM',
  components: {
    VPage,
    VList,
    VButton,
    VListItem,
  },
  props: {
    hideLine: {
      type: Boolean,
      default: false,
    },
    name: {
      require: true,
      type: String,
    },
    index: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
  data() {
    return {
      data: {
        business: ['payOrCrm.business.list.text1', 'payOrCrm.business.list.text2'],
        blog: ['payOrCrm.blog.list.text1'],
        ai: ['payOrCrm.blog.list.text1'],
      },
      icons: [
        { value: 'toCard', name: 'card' },
        { value: 'Webmoney', name: 'webmoney' },
        { value: 'yandexMoney', name: 'yandex' },
        { value: 'Qiwi', name: 'qiwi' },
        { value: 'Capitalist', name: 'capitalist' },
        { value: 'businessAccount', name: 'suitcase' },
      ],
    }
  },
  computed: {
    isBlog() {
      return this.name === 'blog'
    },
    isAiClone() {
      return this.name === 'ai'
    },
    sectionTitle() {
      if (this.isAiClone) {
        return this.$t(`payOrCrm.aiClone.page.title`)
      }
      return this.$t(`payOrCrm.${this.isBlog || this.isAiClone ? 'blog' : 'business'}.page.title`)
    },
    sectionDescriprion() {
      return this.$t(
        `payOrCrm.${this.isBlog || this.isAiClone ? 'blog' : 'business'}.page.description`
      )
    },
    nextStep() {
      return this.$t(
        `payOrCrm.${this.isBlog || this.isAiClone ? 'blog' : 'business'}.page.nextStep`
      )
    },
    nextText() {
      return this.$t(
        `payOrCrm.${this.isBlog || this.isAiClone ? 'blog' : 'business'}.page.nextText`
      )
    },
  },
}
</script>
<style lang="scss" module>
.section {
  @include mobile-bg();
}

.content {
  @include li;

  @include media('mobile') {
    grid-template-columns: 1fr;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  margin: 32px 0 32px 0;
  column-gap: 16px;
  row-gap: 16px;

  @media (max-width: 1600px) and (min-width: 941px) {
    grid-template-columns: repeat(3, 128px);
    column-gap: 8px;
    row-gap: 8px;
    width: 400px;
    margin: 16px 0;
  }

  @include media('mobile') {
    width: 458px;
    margin: 32px auto;
    filter: drop-shadow(15px 15px 60px rgba(39, 20, 96, 0.1));
    padding: 0;
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    margin: 8px 0 24px 0;
    column-gap: 16px;
    row-gap: 16px;
  }

  @media (max-width: 510px) {
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 48px;
  border-radius: 16px;
  background-color: white;
  overflow: hidden;

  @media (max-width: 1600px) {
    width: 128px;
  }
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  font-size: 12px;
  line-height: 12px;
  column-gap: 10px;

  @include media('desktop') {
    font-size: 0.923vw;
    line-height: 0.923vw;
    column-gap: 0.769vw;
  }

  @include media('mobile') {
    font-size: 12px;
    line-height: 12px;
    column-gap: 10px;
  }
}

.icon {
  display: block;
  justify-self: start;

  &-card {
    width: 28px;
    height: 20px;

    @include media('desktop') {
      width: 2.154vw;
      height: 1.538vw;
    }

    @include media('mobile') {
      width: 28px;
      height: 20px;
    }
  }

  &-webmoney {
    width: 24px;
    height: 24px;

    @include media('desktop') {
      width: 1.846vw;
      height: 1.846vw;
    }

    @include media('mobile') {
      width: 24px;
      height: 24px;
    }
  }

  &-yandex {
    width: 20px;
    height: 26px;

    @include media('desktop') {
      width: 1.538vw;
      height: 2vw;
    }

    @include media('mobile') {
      width: 20px;
      height: 26px;
    }
  }

  &-qiwi {
    width: 24px;
    height: 26px;

    @include media('desktop') {
      width: 1.846vw;
      height: 2vw;
    }

    @include media('mobile') {
      width: 24px;
      height: 26px;
    }
  }

  &-capitalist {
    width: 20px;
    height: 30px;

    @include media('desktop') {
      width: 1.538vw;
      height: 2.308vw;
    }

    @include media('mobile') {
      width: 20px;
      height: 30px;
    }
  }

  &-suitcase {
    width: 26px;
    height: 22px;

    @include media('desktop') {
      width: 2vw;
      height: 1.692vw;
    }

    @include media('mobile') {
      width: 26px;
      height: 22px;
    }
  }
}
</style>
