import Vue from 'vue'
import VueExpandableImage from 'vue-expandable-image'

import router from './router'
import store from './store'

import i18n from './plugins/i18n'
import eventBus from './plugins/eventBus'
import './plugins/vue-fullpage'
import './plugins/vue-svgicon'
import './plugins/vue-touch-events'
import './plugins/vue-scrollto'
import App from './App.vue'

import './assets/style/style.scss'

Vue.prototype.$bus = eventBus
Vue.config.productionTip = false

Vue.use(VueExpandableImage)

new Vue({
  name: '',
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
