/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'on': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M16 8A8 8 0 110 8 7.998 7.998 0 015 .587v2.225A5.999 5.999 0 002 8c0 3.308 2.692 6 6 6s6-2.692 6-6a5.999 5.999 0 00-3-5.188V.587A7.998 7.998 0 0116 8zM9 0H7v6h2V0z"/>'
  }
})
