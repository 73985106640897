export default {
  registration: 'Registration',
  personalArea: 'Personal Area',
  toUser: 'To the user',
  forBusiness: 'For business',
  Business: 'Business',
  forBlog: 'For blog',
  Blog: 'Blog',
  forai: 'Ai-Clone',
  ai: 'Ai-Clone',
  aboutCompany: 'About company',
  fitsInWorld: 'The first in the',
  worldPlatform: 'world platform',
  forAiCloneTitle: '{text} {br} {text2} {br} {text3}',
  forBusinessAndInfluencers: '{text} {br} {text2} {br} for business {br} and influencers',
  createPageInFiveMinutes:
    'Create a page in 5 minutes and get started {br} accepting payments {link}',
  createPageInFiveMinutesForAiClone:
    'Create a digital twin in 5 minutes{br} {text} start earning {text2}',
  bySubscription: 'by subscription!',
  go: 'Go!',
  step: 'Step {0}',
  calculateIncome: 'Calculate your income',
  amountSubscribers: 'Number of subscribers',
  estimatedIncome: 'Estimated income',
  toCard: 'Card',
  businessAccount: 'R/s IE',
  yandexMoney: 'Yandex.Money',
  requisites: 'Requisites',
  about: 'About',
  offerForThePurchase: 'Purchase and sale offer',
  rulesForUse: 'Rules for use',
  privacyPolicy: 'Privacy Policy',
  policyOfLegalization: 'Policy of legalization',
  aboutProject: 'About the project',
  jobs: 'Jobs',
  contacts: 'Contacts',
  cookies: 'Cookie policy',
  aiClone: {
    title: {
      1: 'AI clone',
      2: 'new level',
      3: 'audience interaction',
    },
    footer: {
      1: 'and',
      2: 'when you sleep!',
    },
  },
  howItWorks: {
    page: {
      title: 'How it works',
      description: 'Process and calculations',
      nextStep: 'Next',
      nextText: 'Your Income',
    },
    business: {
      a: {
        title: 'Creation of a site',
        text1:
          '<b>Create the address</b> or your page <b>or connect your domain</b>. Users will find your page at this address.',
        text2: '<b>Post the content</b> that you costumers like and keep in touch with them.',
        text3:
          '<b>Convenient</b> content managment <b> system </b> doesn’t require any professional skills in web development. </br> Even a beginner will figure it out.',
        nextStep: 'Next step',
        nextText: 'Service settings',
      },
      b: {
        title: 'Service settings',
        text1: 'Commercial feed',
        text2: 'Subscription services',
        text3: 'Private clubs',
        text4: 'Goods by subscription',
        subText:
          "Your client subscribes, and <b>our service controls</b> the process of automatic deduction of funds from the buyer's account when the next payment is coming up.",
        nextStep: 'Next step',
        nextText: 'Sales',
      },
      c: {
        title: 'Sales',
        text1:
          '<b>Take orders and communicate</b> with visitors through a user-friendly interface, build relationships with each potential client.',
        text2:
          '<b>Create public chats</b> based on interests and build a community around your brand.',
        text3:
          '<b>Post customer reviews</b> and increase the trust of potential customers visiting your site.',
        nextStep: 'Next step',
        nextText: 'Business expansion',
      },
      d: {
        title: 'Business expansion',
        text1: '<b>Create your landing pages</b> using our tracker and place them in any block.',
        text2:
          '<b>Create promotional pages</b> with discounts and send them to your visitors or current customers.',
        text3: '<b>Create text pages</b> with a convenient editor and write useful longreads there',
        nextStep: 'Next',
        nextText: 'Management system',
      },
    },
    aiClone: {
      title: 'How to maintain uniqueness when communicating with a large audience?',
      text1: '<b>Create a digital version</b> of yourself.',
      text2: '<b>Choose format:</b> website, bot or mobile application.',
      text3:
        '<b>Upload</b> your experience, thinking and personality into the system and<br/> <b>earn</b> by subscription from grateful fans.',
    },
    blog: {
      text1: '<b>Create the address</b> of your page.',
      text2: '<b>Choose a blog format:</b> page or channel.',
      text3: '<b>Share</b> photos, videos or other exclusive content.',
      text4: '<b>Earn</b> by subscription from grateful fans.',
    },
  },
  income: {
    business: {
      page: {
        title: 'Management system',
        description: 'Manage your business page with a user-friendly CRM.',
      },
      a: {
        title: 'Efficiency and mobility',
        text1: 'Your managers can <b>respond promptly</b> even from their <b>phone!</b>',
        text2: '<b>Post the content</b>, record video messages.',
        text3: 'Get <b>push-notifications</b> about new orders.',
        text4: '<b>Mobile version</b> CRM for work.',
        nextStep: 'Other benefits of CRM',
        nextText: 'User management',
      },
      b: {
        title: 'User management',
        text1:
          '<b>Create user categories</b> and assign them to visitors. You can set automatic rules, and the system will assign a category to the user based on a variety of criteria.',
        text2:
          'Each site visitor becomes <b>a dialog box in CRM.</b> You can text him or set up an auto messages.',
        text3:
          'Send messages to visitors, and visitors will get them along with <b>push-notifications.</b>',
        nextStep: 'Other benefits of CRM',
        nextText: 'Analytics, reports, payments.',
      },
      c: {
        title: 'Analytics, reports, payments.',
        text1: '<b>Analyze sales funnel</b>, find weaknesses and increase your profit.',
        text2: 'Make financial <b>reports</b>, and generate <b>payments.</b>',
        nextStep: 'Other benefits of CRM',
        nextText: 'Personal automated sales funnel',
      },
      d: {
        title: 'Personal automated sales funnel',
        text1:
          '<b>Automated sales funnel</b> is a movement on sales funnel using auto-messages and collecting information about costumers. Sales funnel is a costumer path from introduction to your product to purchase.',
        nextStep: 'Other benefits of CRM',
        nextText: 'Maximum customer data',
      },
      e: {
        title: 'Maximum customer data',
        text1: 'Information about the advertisements that the user clicked on.',
        text2: '<b>What encouraged</b> the costumer to make a targeted action.',
        text3: '<b>Device model</b> and other data.',
        text4:
          'Simple transfer of <b>conversion data</b> to Facebook and Google advertising networks via Pixel technology.',
        text5: 'Analyze what type of clients brings the <b>maximum income</b>.',
        nextStep: 'Next',
        nextText: 'Maximum of convenience',
      },
    },
    blog: {
      page: {
        title: 'Your Income',
        description: 'What exactly do you make money on',
        nextStep: 'Next',
        nextText: 'Your media prominence',
      },
      text1:
        'Sell ​​exclusive <b>advertising formats</b> to companies: branding of the channek, chats, message backgrounds.',
      text2: 'Conduct advertising<b> broadcasts</b>.',
      text3: 'Sell advertising<b> posts</b>.',
      text4: 'Sell merch and services through the<b> built-in store</b>.',
      subText:
        '<b>Important</b>: technical capacities of Supster allow the advertiser to analyze the results of advertising campaigns.',
    },
    aiClone: {
      page: {
        title: 'Supster - a platform for creating your AI double',
        description: 'What exactly do you earn from',
        nextStep: 'Next',
        nextText: 'Your media exposure',
      },
      text1:
        'We want to bring <b>one-on-one</b> interactions with celebrities and influencers to the masses.',
      text2:
        'We will create a double <b>through various sources</b> of data such as chats, emails, social posts. networks, Youtube videos, audio files and much more.',
      text3:
        'a digital cloning platform that captures your thinking, making your knowledge, experience, personality and opinions <b>available to others in a personalized form</b>',

      subText:
        '<b>The main idea of ​​Supster is to enable famous personalities to interact with a large number of people</b>',
    },
  },
  media: {
    business: {
      page: {
        title: 'Maximum of convenience',
        description: '1-click application',
        nextStep: 'Next',
        nextText: 'Maximum flexibility',
      },
      list: {
        text1:
          'Create a mobile app in 1 click and start attracting new costumers from previously unavailable sources, such as <b>Google UAC, Unity Ads, Applovin</b> and other advertising networks for mobile apps.',
        text2:
          'Connect <b>Apple Pay</b> and <b>Google Pay</b> and increase the conversion rate to a purchase thanks to the service convenience.',
        subText:
          'If you accept subscription payments, then you shouldn’t worry that the client will forget to make the payment on time. The money is deducted <b>automatically</b> without reconfirmation from the user.',
      },
    },
    blog: {
      page: {
        title: 'Your media prominence',
        description: 'Grow with Supster',
        nextStep: 'Next',
        nextText: 'Your benefit',
      },
      list: {
        text1:
          '<b>Create</b> your personal mobile app in 5 minutes and start getting new subscribers from Google Play and AppStore!',
        text2:
          '<b>Create convinient</b> bots in Telegram / Viber / WhatsApp and post content automatically.',
      },
    },
    aiClone: {
      page: {
        title: 'Scalability, Affordability, Passive Income',
        description: '1-click app',
        nextStep: 'Next',
        nextText: 'Your benefit',
      },
      list: {
        text1:
          'An AI clone can <b>interact</b> with multiple followers at the same time, <b>providing a personalized experience for everyone</b> regardless of audience size.',
        text2:
          'Influencers have the opportunity to <b>monetize their knowledge and experience</b> through a subscription system, <b>even when they are not working</b> personally',
      },
    },
  },
  payOrCrm: {
    business: {
      page: {
        title: 'Maximum flexibility',
        description: 'Own bots and integration with any CRM',
        nextStep: 'Other benefits of CRM',
        nextText: 'Increase your sales!',
      },
      list: {
        text1:
          'Combine all messages in one place. <br /> Create your own bot on <b>Telegram / Viber / WhatsApp</b> in 5 minutes.',
        text2: '<b>Integration with any</b> CRM.',
      },
    },
    blog: {
      page: {
        title: 'Your benefit',
        description: 'Permanent income and easy withdrawal of money',
        nextStep: 'Next',
        nextText: 'Increase your sales!',
      },
      list: {
        text1: '<b>Withdraw the funds earned</b> in 1 click in any convenient way.',
        subText:
          '<b>Remember:</b> the person who bought the subscription will regularly bring you passive income, until he unsubscribes.',
      },
    },
    aiClone: {
      page: {
        title: 'Your benefit',
        description: 'Regular income and easy withdrawal of funds',
        nextStep: 'Next',
        nextText: 'Increase your sales!',
      },
      list: {
        text1: '<b>Withdraw your earnings</b> in 1 click in any convenient way.',
        subText:
          '<b>Remember:</b> the person who purchased the subscription will regularly generate passive income until he asks to unsubscribe.',
      },
    },
  },
  sales: {
    page: {
      title: 'Increase your sales <br /> and optimize business processes',
      titleAiClone: 'Ready to become part of the digital revolution and</br>create a new trend?',
      button: 'Get started',
      blog: {
        title: 'Read blog!',
        description: 'We regularly publish useful information on our blog.',
        link: 'Product cases for October.',
      },
      aiClone: {
        title: 'Read our blog!',
        description: 'We regularly publish useful information in our blog.',
        link: 'SupsterGPT case studies',
      },
      youTube: {
        title: 'Study the cases!',
        description: 'Watch videos with cases and training materials on YouTube.',
        link: 'Our YouTube channel',
      },
      telegram: {
        title: 'Ask about Supster!',
        description:
          'Do you want to discuss the system and communicate with our costumers? Welcome to the chat!',
        link: 'Go to Telegram',
      },
    },
  },
  rules: {
    toMain: 'Home',
    title: 'Terms of use',
    subtitles: {
      1: 'Processed data',
      3: 'Data protection requirements',
    },
    textSub:
      '<span>Example!</span> The specified third-party software includes the systems for collecting visitor statistics such as Google Analytics and Yandex.Metrica.',
    paragraphs: {
      text1_1: 'We do not collect your personal data from the Site.',
      text1_2:
        'All data collected on the Site is provided and accepted in an anonymous form (hereinafter referred to as "Anonymous Data").',
      text1_3:
        'Anonymous data includes the following information that cannot be used to identify you:',
      text1_3_1:
        'Information that you provide about yourself using online forms and software modules of the Site, including your name or phone number and/or email address.',
      text1_3_2:
        'Data that is transmitted in an anonymous form in automatic mode, depending on the settings of the software you use.',
      text1_4:
        'The Administration has the right to set requirements for the Anonymous User Data that is collected using the Site.',
      text1_6:
        'The Administration does not verify the reliability of Personal data submitted assuming that the User acts in good faith and with deliberation and takes all measures to keep this information updated and obtain all necessary consents to its use.',
      text1_8:
        'The structure and conditions for collecting anonymous data using third-party software are determined directly by their copyright holders and may include:',
      text3_1:
        'The Administration stores Personal information and provides its protection against an unauthorized access and distribution in accordance with the internal rules and regulations.',
      text3_3:
        'In order to improve the quality of work, the Administration has the right to store log files about actions performed by the User in the use of the Site for 1 (One) year.',
    },
    list: {
      1: 'browser information (type, version, cookie);',
      2: 'device data and its location;',
      3: 'operating system data (type, version, screen resolution);',
      4: 'query data (time, source, IP address).',
    },
  },
  notFound: {
    goBack: 'Go back',
    title: 'There is nothing here!',
    text: 'Probably you got here by mistake. <br /> <b> Go back to the home page and <br /> select the section you need. </b>',
  },
  cookiesHero: {
    text: 'This website uses Cookies to store data. By continuing to use the site, you agree to use these files in accordance with the cookie policy and Supster privacy policy.',
    more: 'Learn more',
    agree: 'Agree',
  },
  barText: "Don't miss out! <b>Join our waiting list</b> to experience platform first-hand.",
  soon: 'Soon',
  startForm: {
    success: 'Your data has been successfully sent!',
    name: 'Your name',
    insta: 'Instagram link',
    telegram: 'Telegram contact',
    sending: 'Sending...',
    send: 'Send',
    error: 'Error',
  },
}
